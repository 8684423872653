import './SuccessTransaction.css';

import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { encryptEmail } from '@auth/helpers';

const SuccessTransaction: React.FC = () => {
	const history = useHistory();
	// TODO: correctly use TS here
	const historyState = history.location.state as any;
	const { email, amount } = historyState.confirmedData;
	return (
		<section className="uk-section uk-section-xsmall">
			<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
				<div className="uk-background-gold mc-breadcrumb">
					<div className="uk-container">
						<ul className="uk-breadcrumb uk-flex-center">
							<li>
								<Link to="/market">Mitacoin</Link>
							</li>
							<li>
								<span>Transfer Mitacoins</span>
							</li>
						</ul>
					</div>
				</div>
				<div className="uk-card mc-transfer uk-width-xlarge uk-margin-auto uk-margin-medium-top">
					<div className="uk-width-1-1 uk-text-center uk-margin">
						<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-small">
							<span>Successful Transfer</span>
						</legend>
					</div>
					<form className="uk-form-stacked mc-success-form">
						<div className="uk-card-body">
							<p className="uk-text-intro uk-text-bluelight uk-text-center uk-text-uppercase uk-text-bold">Transfer in process</p>
							<img className="mc-success-img" src="/images/mc-tranfer-ok.png" alt="" />
							<p className="uk-text-content uk-text-bluedark uk-text-center uk-text-bolder uk-margin">
								We have started the transfer process to <br />
								<span className="uk-text-bluelight uk-text-intro uk-text-bold">{encryptEmail(email)}</span>
								<br />
								and very soon{' '}
								<span className="uk-text-bluelight uk-text-intro uk-text-bold">
									{amount} {amount === 1 ? 'Mitacoin' : 'Mitacoins'}
								</span>{' '}
								will be in your friend wallet.
							</p>
							<div className="uk-text-center uk-margin-large">
								<Link to="/my-account" className="uk-button uk-button-text uk-modal-close">
									Back to Wallet
								</Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default SuccessTransaction;
