import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';

import { ForgotPasswordComponent } from '@auth/components';
import { ForgotPassword as PasswordForgot } from '@auth/services';
import { authFormForgotInitialValues, authFormForgotSchema, ForgotPasswordFormValues } from '@auth/utils';

const ForgotPassword: React.FC = () => {
	const history = useHistory();

	const [serverError, setServerError] = useState('');
	const [loader, setLoader] = useState(false);

	const handleSubmit = async (values: ForgotPasswordFormValues) => {
		setLoader(true);
		setServerError('');
		const response = await PasswordForgot({ ...values });
		setLoader(false);
		if (response && response.success) {
			return history.push({
				pathname: '/pass-email-sent',
				state: { email: values.email },
			});
		}
		return setServerError(response.message ?? 'Login error');
	};

	return (
		<Formik initialValues={authFormForgotInitialValues} validationSchema={authFormForgotSchema} onSubmit={handleSubmit}>
			{(formikProps: FormikProps<ForgotPasswordFormValues>) => (
				<>
					<ForgotPasswordComponent {...formikProps} serverError={serverError} loader={loader} />
				</>
			)}
		</Formik>
	);
};

export default ForgotPassword;
