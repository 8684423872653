import './Transfer.css';

import React, { KeyboardEvent } from 'react';
import { ErrorMessage, FormikProps } from 'formik';

import { Calculator } from '@transfers/components';
import { InviteModal, ValidateCodeTransaction } from '@transfers/containers';
import { ICouponData, TransferMitacoinsFormValues } from '@transfers/utils';

type TransferProps = FormikProps<TransferMitacoinsFormValues> & {
	loader: boolean;
	serverError: string;
	confirmedData: ICouponData | undefined;
	currentUserEmail: string;
	priceUSD: number;
	priceCOP: number;
	priceUSDtoCOP: number;
	userMitacoinAmount: number;
	total: string;
	selectedCurrency: string;
	inputAmount: string;
	setInputAmount: (val: string) => void;
};

const Transfer: React.FC<TransferProps> = ({
	currentUserEmail,
	confirmedData,
	priceUSD,
	priceCOP,
	priceUSDtoCOP,
	userMitacoinAmount,
	loader,
	serverError,
	handleChange,
	handleSubmit,
	errors,
	touched,
	total,
	selectedCurrency,
	inputAmount,
	setInputAmount,
	setFieldValue,
}) => {
	const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const regexExpresion = /^[0-9\b]+$/;
		if (!regexExpresion.test(e.key)) {
			if (
				e.key === '.' ||
				e.key === 'Backspace' ||
				e.key === 'ArrowLeft' ||
				e.key === 'ArrowRigh' ||
				e.key === 'Tab' ||
				e.key === 'v' ||
				e.key === 'Delete'
			) {
				return true;
			}
			return e.preventDefault();
		}
	};

	const handleChangeTotal = (e: React.ChangeEvent<HTMLInputElement>) => {
		const num = e.target.value.split('.');
		if (num[1]) {
			if (num[1].length >= 3) num[1] = num[1].substring(0, 3);
		}
		const numComplete = num.join('.');
		setInputAmount(numComplete);
		setFieldValue('amount', numComplete);
	};

	React.useEffect(() => {
		setFieldValue('amount', inputAmount);
	}, [inputAmount, setFieldValue]);

	return (
		<>
			<form className="uk-form-stacked" onSubmit={handleSubmit}>
				<div className="uk-width-1-1 uk-text-center uk-margin">
					<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-small">
						<span>Transfer Mitacoins</span>
					</legend>
					<p className="uk-text-subheading uk-text-bluelight uk-margin-small">Transfer your gold to friends</p>
				</div>
				<Calculator priceCOP={priceCOP} priceUSD={priceUSD} priceUSDtoCOP={priceUSDtoCOP} setInputAmount={setInputAmount} />

				<div className="uk-width-1-1 uk-margin-medium-top uk-margin-bottom">
					<hr className="uk-margin-small" />
					<h3 className="uk-text-blue uk-margin-remove uk-text-center">Start Transfer</h3>
					<hr className="uk-margin-small" />
				</div>
				<p className="uk-text-meta uk-text-bluedark uk-text-bold uk-text-center uk-margin-remove-top">
					You can transfer any of your <strong>{userMitacoinAmount} Mitacoins</strong> to another registered user.
				</p>
				<div className="uk-grid-collapse mc-transfer" data-uk-grid>
					<div className="uk-text-center mc-coin">
						<div className="uk-inline">
							<img className="mc-transfer-dots" src="/images/mitacoin-dots.png" alt="" />
							<div className="uk-overlay uk-position-cover">
								<img src="/images/mitacoin-transfers.png" alt="" />
							</div>
						</div>
					</div>
					<div className="uk-text-center mc-transfer-container">
						<div className="mc-transfer-field">
							<label className="uk-form-label">Mitacoins to transfer</label>
							<div className="uk-form-controls uk-inline uk-width-1-1">
								<span className="uk-form-icon">
									<img src="/images/icons/ico-minicoin.svg" alt="" />
								</span>
								<input
									className="uk-input"
									value={inputAmount}
									type="text"
									placeholder="10.25"
									name="amount"
									onKeyDown={handleOnKeyDown}
									onChange={handleChangeTotal}
									maxLength={7}
								/>
								{errors.amount && touched.amount && <ErrorMessage name="amount" component="small" className="errorMessage" />}
							</div>
						</div>
						<div className="mc-transfer-field">
							<label className="uk-form-label">Friend email account</label>
							<div className="uk-form-controls uk-inline uk-width-1-1">
								<span className="uk-form-icon uk-form-icon-flip">
									<img src="../images/icons/ico-friend.svg" alt="" />
								</span>
								<input className="uk-input" type="email" placeholder="" name="email" onChange={handleChange} />
								{errors.email && touched.email && <ErrorMessage name="email" component="small" className="errorMessage" />}
							</div>
						</div>

						<div className="mc-total uk-margin" id="mc-total1">
							<hr className="uk-margin-small" />
							<strong className="uk-text-small uk-text-uppercase uk-text-blue uk-text-middle uk-margin-small-right">Total</strong>
							<strong className="uk-text-number uk-text-large uk-text-bluelight uk-text-middle">{total}</strong>
							<strong className="uk-text-small uk-text-uppercase uk-text-blue uk-text-middle uk-margin-small-left">
								({selectedCurrency})
							</strong>
						</div>
					</div>

					{serverError ? (
						<div className="uk-margin uk-width-1-1">
							<div className="uk-alert-warning" data-uk-alert>
								<button type="button" className="uk-alert-close" data-uk-close></button>
								<p>{serverError}</p>
							</div>
						</div>
					) : null}

					<div className="mc-tranfer-info uk-margin" data-uk-alert>
						<div className="uk-flex uk-flex-left uk-flex-middle">
							<img src="/images/icons/ico-notice.svg" alt="" />
							<p className="uk-text-small uk-text-graydarkx uk-text-bold uk-margin-remove">
								To secure this transaction, you will have to verify your identity with a unique code that we will send to your
								email.
							</p>
						</div>
					</div>

					<div className="uk-text-center uk-text-xsmall uk-text-bold uk-text-uppercase uk-text-bluedark mc-invite-friend">
						If your friend is not part of Mitacoin yet,{' '}
						<a href="/" className="uk-button uk-button-text uk-button-small uk-text-middle" data-uk-toggle="target: #mc-invite">
							Click here to invite
						</a>
					</div>
					<div className="uk-width-1-1 uk-margin-large-bottom uk-margin-top  uk-text-center">
						<hr className="uk-margin-medium" />
						<button
							type="submit"
							disabled={loader}
							className={`uk-button uk-button-primary uk-margin-small-left ${loader ? 'loading' : ''}`}
						>
							Start Tranfer
						</button>
					</div>
				</div>
			</form>
			<ValidateCodeTransaction confirmedData={confirmedData} currentUserEmail={currentUserEmail} />
			<InviteModal />
		</>
	);
};

export default Transfer;
