import 'react-toastify/dist/ReactToastify.css';
import './ValidateCodeTransaction.css';

import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { encryptEmail } from '@auth/helpers';
import { hooks } from '@context/index';

type ValidateCodeTransactionProps = {
	serverError: string;
	isLoading: boolean;
	sendANewCode: () => void;
	loaderCode: boolean;
	handleSubmit: (code: number) => void;
	currentUserEmail: string;
};

const ValidateCodeTransaction: React.FC<ValidateCodeTransactionProps> = ({
	serverError,
	isLoading,
	sendANewCode,
	loaderCode,
	handleSubmit,
	currentUserEmail,
}) => {
	const [codeNumber1, setCodeNumber1] = useState('');
	const [codeNumber2, setCodeNumber2] = useState('');
	const [codeNumber3, setCodeNumber3] = useState('');
	const [codeNumber4, setCodeNumber4] = useState('');
	const [codeNumber5, setCodeNumber5] = useState('');
	const [codeNumber6, setCodeNumber6] = useState('');
	const [codeError, setCodeError] = useState('');

	const handleSubmitCode = () => {
		if (!codeNumber1 || !codeNumber2 || !codeNumber3 || !codeNumber4 || !codeNumber5 || !codeNumber6) {
			return setCodeError('Please complete this field');
		}
		handleSubmit(Number(`${codeNumber1}${codeNumber2}${codeNumber3}${codeNumber4}${codeNumber5}${codeNumber6}`));
	};

	const regexExpresion = /^[0-9\b]+$/;
	const handleOnKeyDownNum1 = (e: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = e.key;
		if (keyCode === 'Backspace') {
			return setCodeNumber1('');
		}
	};
	const handleChangeCodeNum1 = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.substr(e.target.value.length - 1);
		if (!regexExpresion.test(newValue)) {
			return;
		}
		(document.getElementsByName('numCode2')[document.getElementsByName('numCode2').length - 1] as HTMLElement).focus();
		setCodeNumber1(newValue);
	};

	const handleOnKeyDownNum2 = (e: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = e.key;
		if (keyCode === 'Backspace') {
			return setCodeNumber2('');
		}
	};
	const handleChangeCodeNum2 = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.substr(e.target.value.length - 1);
		if (!regexExpresion.test(newValue)) {
			return;
		}
		(document.getElementsByName('numCode3')[document.getElementsByName('numCode3').length - 1] as HTMLElement).focus();
		setCodeNumber2(newValue);
	};

	const handleOnKeyDownNum3 = (e: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = e.key;
		if (keyCode === 'Backspace') {
			return setCodeNumber3('');
		}
	};
	const handleChangeCodeNum3 = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.substr(e.target.value.length - 1);
		if (!regexExpresion.test(newValue)) {
			return;
		}
		(document.getElementsByName('numCode4')[document.getElementsByName('numCode4').length - 1] as HTMLElement).focus();
		setCodeNumber3(newValue);
	};

	const handleOnKeyDownNum4 = (e: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = e.key;
		if (keyCode === 'Backspace') {
			return setCodeNumber4('');
		}
	};
	const handleChangeCodeNum4 = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.substr(e.target.value.length - 1);
		if (!regexExpresion.test(newValue)) {
			return;
		}
		(document.getElementsByName('numCode5')[document.getElementsByName('numCode5').length - 1] as HTMLElement).focus();
		setCodeNumber4(newValue);
	};

	const handleOnKeyDownNum5 = (e: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = e.key;
		if (keyCode === 'Backspace') {
			return setCodeNumber5('');
		}
	};
	const handleChangeCodeNum5 = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.substr(e.target.value.length - 1);
		if (!regexExpresion.test(newValue)) {
			return;
		}
		(document.getElementsByName('numCode6')[document.getElementsByName('numCode6').length - 1] as HTMLElement).focus();
		setCodeNumber5(newValue);
	};

	const handleOnKeyDownNum6 = (e: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = e.key;
		if (keyCode === 'Backspace') {
			return setCodeNumber6('');
		}
	};
	const handleChangeCodeNum6 = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.substr(e.target.value.length - 1);
		if (!regexExpresion.test(newValue)) {
			return;
		}
		setCodeNumber6(newValue);
	};

	const handleSendNewCode = async () => {
		await sendANewCode();
		setCodeNumber1('');
		setCodeNumber2('');
		setCodeNumber3('');
		setCodeNumber4('');
		setCodeNumber5('');
		setCodeNumber6('');
		return (document.getElementsByName('numCode1')[0] as HTMLElement).focus();
	};

	const { modalTransferOpen, setModalTransferOpen } = hooks.useModalTransferContext();

	return (
		<>
			<ToastContainer />
			<div id="mc-transfers-code" className={`uk-flex-top uk-modal ${modalTransferOpen ? 'uk-flex uk-open' : ''}`} data-uk-modal>
				<div className="uk-modal-dialog uk-margin-auto-vertical">
					<button className="uk-modal-close-default uk-icon uk-close" type="button" onClick={() => setModalTransferOpen(false)}>
						<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
							<line fill="none" stroke="#000" strokeWidth="1.1" x1="1" y1="1" x2="13" y2="13"></line>
							<line fill="none" stroke="#000" strokeWidth="1.1" x1="13" y1="1" x2="1" y2="13"></line>
						</svg>
					</button>
					<div className="uk-modal-header uk-text-center">
						<h2 className="uk-modal-title">Transfer Mitacoins</h2>
					</div>
					<form className="uk-form-stacked mc-emailcode-form">
						<div className="uk-modal-body">
							<p className="uk-text-intro uk-text-bluelight uk-text-center uk-text-bolder">Check your inbox</p>
							<p className="uk-text-meta uk-text-graydarkx uk-text-bold uk-text-center uk-margin-remove-top">
								Enter the 6 digit code we sent to {encryptEmail(currentUserEmail)}{' '}
							</p>
							<div className="mc-emailcode-fields">
								<div className="uk-margin">
									<div className="uk-form-controls">
										<div className="uk-text-center uk-flex-middle">
											<input
												className="uk-input uk-form-width-xsmall uk-form-large uk-text-center"
												type="text"
												name="numCode1"
												value={codeNumber1}
												onKeyDown={handleOnKeyDownNum1}
												onChange={handleChangeCodeNum1}
											/>
											<input
												className="uk-input uk-form-width-xsmall uk-form-large uk-text-center"
												type="text"
												name="numCode2"
												value={codeNumber2}
												onKeyDown={handleOnKeyDownNum2}
												onChange={handleChangeCodeNum2}
											/>
											<input
												className="uk-input uk-form-width-xsmall uk-form-large uk-text-center"
												type="text"
												name="numCode3"
												value={codeNumber3}
												onKeyDown={handleOnKeyDownNum3}
												onChange={handleChangeCodeNum3}
											/>
											<input
												className="uk-input uk-form-width-xsmall uk-form-large uk-text-center"
												type="text"
												name="numCode4"
												value={codeNumber4}
												onKeyDown={handleOnKeyDownNum4}
												onChange={handleChangeCodeNum4}
											/>
											<input
												className="uk-input uk-form-width-xsmall uk-form-large uk-text-center"
												type="text"
												name="numCode5"
												value={codeNumber5}
												onKeyDown={handleOnKeyDownNum5}
												onChange={handleChangeCodeNum5}
											/>
											<input
												className="uk-input uk-form-width-xsmall uk-form-large uk-text-center"
												type="text"
												name="numCode6"
												value={codeNumber6}
												onKeyDown={handleOnKeyDownNum6}
												onChange={handleChangeCodeNum6}
											/>
											<span className="uk-button uk-button-blank loading uk-hidden"></span>
										</div>
									</div>
								</div>
							</div>
							<div className="uk-text-center uk-text-small uk-margin-small">
								{loaderCode ? (
									<button type="button" className="uk-button uk-button-text" data-uk-spinner>
										Send a new code
									</button>
								) : (
									<button type="button" className="uk-button uk-button-text" onClick={handleSendNewCode}>
										Send a new code
									</button>
								)}
							</div>
							{codeError ? (
								<div className="uk-margin">
									<div className="uk-alert-warning" data-uk-alert>
										<button type="button" className="uk-alert-close" data-uk-close></button>
										<p>{codeError}</p>
									</div>
								</div>
							) : null}
							{serverError ? (
								<div className="uk-margin">
									<div className="uk-alert-warning" data-uk-alert>
										<button type="button" className="uk-alert-close" data-uk-close></button>
										<p>
											{serverError}
											{serverError === 'Email could not be sent' ? (
												<button type="submit" className="uk-button uk-button-text uk-text-danger">
													TRY AGAIN
												</button>
											) : null}
										</p>
									</div>
								</div>
							) : null}
						</div>
						<div className="uk-modal-footer uk-text-center">
							<button className="uk-button uk-button-default uk-modal-close">Cancel</button>
							{isLoading ? (
								<button type="button" className="uk-button uk-button-primary uk-margin-small-left loading">
									Confirm Transfer
								</button>
							) : (
								<button type="button" className="uk-button uk-button-primary uk-margin-small-left" onClick={handleSubmitCode}>
									Confirm Transfer
								</button>
							)}
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ValidateCodeTransaction;
