import React, { useEffect } from 'react';
import UIkit from 'uikit';

const GeneralError = () => {
	useEffect(() => {
		document.title = 'Something went wrong - Mitacoin';
		const modalsApp = [
			'mc-transfers',
			'mc-transfers-code',
			'mc-invite',
			'mc-transfers-success',
			'mc-invite-success',
			'modal-example',
			'mitacoin-info',
		];
		for (let index = 0; index < modalsApp.length; index++) {
			const modalId = modalsApp[index];
			const modalContiner = document.getElementById(modalId);
			if (modalContiner) {
				UIkit.modal(modalContiner).hide();
			}
		}
	}, []);

	return (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="mc-shop uk-text-center">
					<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
						<div className="uk-width-xlarge uk-margin-auto uk-text-large uk-text-center uk-text-orange">
							<img src="/images/mitacoin-cart-error.png" alt="" width="200" />
							<br />
							<p className="uk-margin-small">Something went wrong</p>
							<p className="uk-text-intro uk-text-graylightxx uk-margin-small">Please try again or contact us.</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default GeneralError;
