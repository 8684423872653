import * as Yup from 'yup';

export interface IConfirmedData {
	amount: number;
	email: string;
}

// Step 1
export type TransferMitacoinsFormValues = {
	amount: number;
	email: string;
};

export const TransferMitacoinsFormValuesInitialValues = {
	amount: 0,
	email: '',
};

export const TransferMitacoinsFormValuesSchema = Yup.object().shape({
	amount: Yup.number().required('Enter a valid amount').min(0.001, 'Only for transfers greater than 0.001'),
	email: Yup.string()
		.email('Email is invalid')
		.required('Email is required')
		.matches(
			/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
			'Is not in correct format, please write without accent and lowercase',
		),
});

// Invit Friend
export type InviteFriendFormValues = {
	friendName: string;
	friendEmail: string;
};

export const InviteFriendFormValuesInitialValues = {
	friendName: '',
	friendEmail: '',
};

export const InviteFriendFormValuesInitialValuesSchema = Yup.object().shape({
	friendName: Yup.string().required('This field is required'),
	friendEmail: Yup.string()
		.email('Email is invalid')
		.required('Email is required')
		.matches(
			/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
			'Is not in correct format, please write without accent and lowercase',
		),
});

// Interface coupon data
export interface ICouponData {
	amount: number;
	email: string;
}
