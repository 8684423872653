/* eslint-disable */
import React, { lazy, Suspense } from 'react';
import IdleTimer from 'react-idle-timer';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';

import { providers } from '@context/index';

import config from '../config';
import { auth, shared } from '../modules';
const { MainLayout } = shared.layout;
const { GlobalProvider } = providers;

// screens
// -- Gold screens
const Shop = lazy(() => import('@gold/screens/Shop/Shop'));
const Checkout = lazy(() => import('@gold/screens/Checkout/Checkout'));
const PurchaseFailed = lazy(() => import('@gold/screens/PurchaseFailed/PurchaseFailed'));
const PurchasePending = lazy(() => import('@gold/screens/PurchasePending/PurchasePending'));
const PurchaseSuccess = lazy(() => import('@gold/screens/PurchaseSuccess/PurchaseSuccess'));

// -- User screens
const Landing = lazy(() => import('@user/screens/Landing/Landing'));
const MyAccount = lazy(() => import('@user/screens/MyAccount/MyAccount'));
const NewPassword = lazy(() => import('@user/screens/NewPassword/NewPassword'));

// -- Transfer screens
const Transfer = lazy(() => import('@transfers/screens/Transfer/Transfer'));
const SuccessTransaction = lazy(() => import('@transfers/screens/SuccessTransaction/SuccessTransaction'));

export const PrivateRoute: React.FC<RouteProps> = ({ component, ...res }) => {
	if (!window.sessionStorage.getItem('auth')) {
		const redirect = () => <Redirect to="/" />;
		return <Route {...res} component={redirect} />;
	} else {
		return <Route {...res} component={component} />;
	}
};

export const LayoutPrivateRoute = ({ component, ...res }: RouteProps) => {
	const history = useHistory();

	const { idleTimerExpiredSession } = config;
	const handleOnIdle = async () => {
		if (sessionStorage.getItem('auth')) {
			await auth.services.Logout();
			return history.push('/session-expired');
		}
	};

	return (
		<>
			<IdleTimer timeout={idleTimerExpiredSession} onIdle={handleOnIdle} />
			<GlobalProvider>
				<MainLayout>
					<Suspense fallback={<div>Loading...</div>}>
						<PrivateRoute {...res} component={component} />
					</Suspense>
				</MainLayout>
			</GlobalProvider>
		</>
	);
};

export const privateRoutes: RouteProps[] = [
	// {
	// 	exact: true,
	// 	path: '/shop',
	// 	component: Shop,
	// },
	// {
	// 	exact: true,
	// 	path: '/checkout',
	// 	component: Checkout,
	// },
	// {
	// 	exact: true,
	// 	path: '/purchase-failed',
	// 	component: PurchaseFailed,
	// },
	// {
	// 	exact: true,
	// 	path: '/purchase-success',
	// 	component: PurchaseSuccess,
	// },
	// {
	// 	exact: true,
	// 	path: '/purchase-pending',
	// 	component: PurchasePending,
	// },
	{
		exact: true,
		path: '/market',
		component: Landing,
	},
	// {
	// 	exact: true,
	// 	path: '/my-account',
	// 	component: MyAccount,
	// },
	// {
	// 	exact: true,
	// 	path: '/new-password',
	// 	component: NewPassword,
	// },
	// {
	// 	exact: true,
	// 	path: '/transfer',
	// 	component: Transfer,
	// },
	// {
	// 	exact: true,
	// 	path: '/success-transaction',
	// 	component: SuccessTransaction,
	// },
];
