import './login.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, FormikProps } from 'formik';

import { LoginFormValues } from '@auth/utils';

type FormLoginProps = FormikProps<LoginFormValues> & {
	serverError: string;
	loader: boolean;
};

const LoginComponent: React.FC<FormLoginProps> = ({ serverError, loader, handleSubmit, handleChange, errors, touched }) => {
	return (
		<>
			<div uk-height-viewport="offset-bottom: true">
				<section className="uk-section uk-section-primary uk-padding-remove-vertical uk-preserve-color">
					<div className="mc-form-grid uk-grid-collapse uk-grid-match" uk-grid="true" uk-height-viewport="offset-bottom: 80px">
						<div className="uk-width-1-2@s">
							<div className="uk-card uk-card-body uk-text-center uk-flex uk-flex-middle">
								<div className="mc-welcome">
									<img src="/images/mitacoin-welcome.png" alt="Mitacoin" />
									<hr className="uk-margin" />
									<p className="uk-text-center uk-text-xlarge uk-text-graylight">
										Welcome
										<br />
									</p>
									<p className="uk-text-large uk-text-gold">Invest in the most secure and solid stock market of all times.</p>
								</div>
							</div>
						</div>
						<div className="uk-width-1-2@s uk-background-default">
							{/* <div className=" uk-text-center mc-toolbar uk-background-light uk-height-auto">
								<strong className="uk-text-uppercase uk-text-black uk-text-small uk-margin-small-left uk-margin-small-right">
									Don't have an account yet?
								</strong>
								<Link to="/register" className="uk-button uk-button-default uk-button-small" href="/">
									Sign Up <img src="/images/mc-crown.png" width="28" alt="" />
								</Link>
							</div> */}
							<div className="uk-card uk-card-body uk-flex uk-flex-middle mc-forms mc-login-form">
								<form className="uk-form-stacked uk-width-medium uk-margin-auto" onSubmit={handleSubmit} autoComplete="off">
									<div className="uk-text-center uk-margin">
										<legend className="uk-heading-small uk-heading-line uk-text-center">
											<span>Enter your account</span>
										</legend>
									</div>
									<div className="uk-margin-medium">
										<label className="uk-form-label">Email</label>
										<div className="uk-form-controls">
											<div className="uk-inline uk-width-expand">
												<span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: mail"></span>
												<Field
													className={`uk-input ${errors.email && touched.email ? 'input-error' : null}`}
													type="email"
													placeholder="myemail@***mail.com"
													name="email"
													onChange={handleChange}
												/>
											</div>
											<div className="mc-form-validation uk-hidden">
												<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
											</div>
										</div>
										{errors.email && touched.email ? (
											<ErrorMessage name="email" component="small" className="errorMessage" />
										) : null}
									</div>
									<div className="uk-margin">
										<label className="uk-form-label">Password</label>
										<div className="uk-form-controls">
											<div className="uk-inline uk-width-expand">
												<span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
												<Field
													className={`uk-input ${errors.password && touched.password ? 'input-error' : null}`}
													type="password"
													name="password"
													onChange={handleChange}
												/>
											</div>
											<div className="mc-form-validation uk-hidden">
												<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
											</div>
										</div>
										{errors.password && touched.password ? (
											<ErrorMessage name="password" component="small" className="errorMessage" />
										) : null}
									</div>
									{serverError ? (
										<div className="uk-margin">
											<div className="uk-alert-warning" uk-alert="true">
												<button className="uk-alert-close" uk-close="true"></button>
												<p>{serverError}</p>
											</div>
										</div>
									) : null}
									<div className="uk-margin-medium uk-text-center">
										{
											<button
												type="submit"
												className={`uk-button uk-button-primary uk-width-small ${loader && 'loading'}`}
												disabled={loader}
											>
												Enter{' '}
											</button>
										}
									</div>
									<div className="uk-margin-small uk-text-center">
										<Link className="uk-button uk-button-text uk-button-small" to="/forgot-password">
											Forgot password?
										</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
			<footer className="mc-footer">
				<div className="uk-section uk-section-xsmall uk-background-secondary">
					<div className="uk-container">
						<div className="uk-grid-collapse uk-flex uk-flex-center uk-flex-middle">
							<div>
								<small className="uk-text-bold uk-text-xsmall uk-text-uppercase">
									© Mitacoin. Medellín, city of innovation, Colombia
								</small>
							</div>
							{/* <div>
								<ul className="uk-subnav uk-subnav-divider uk-flex-center" uk-margin="true">
									<li>
										<a href="https://mitacoins.com/how" target="_blank" rel="noopener noreferrer">
											How
										</a>
									</li>
									<li>
										<a href="https://mitacoins.com/why-gold" target="_blank" rel="noopener noreferrer">
											Why
										</a>
									</li>
									<li>
										<a href="https://mitacoins.com/help" target="_blank" rel="noopener noreferrer">
											Help
										</a>
									</li>
								</ul>
							</div>
							<div>
								<ul className="uk-iconnav mc-social uk-flex-center">
									<li>
										<a
											href="http://mitacoins.com/"
											target="_blank"
											rel="noopener noreferrer"
											className="uk-icon-button"
											uk-icon="icon: world"
										>
											{' '}
										</a>
									</li>
									<li>
										<a
											href="https://twitter.com/mitacoin"
											target="_blank"
											rel="noopener noreferrer"
											className="uk-icon-button"
											uk-icon="icon: twitter"
										>
											{' '}
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/mitacoingold/"
											target="_blank"
											rel="noopener noreferrer"
											className="uk-icon-button"
											uk-icon="icon: instagram"
										>
											{' '}
										</a>
									</li>
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</footer>

			{/* <div id="particles-background" className="vertical-centered-box"></div>
			<div id="particles-foreground" className="vertical-centered-box"></div> */}
		</>
	);
};

export default LoginComponent;
