import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
	const history = useHistory();
	useEffect(() => {
		document.title = 'Not Found - Mitacoin';
	}, []);
	return (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="mc-shop uk-text-center">
					<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
						<div className="uk-width-xlarge uk-margin-auto uk-text-large uk-text-center uk-text-orange">
							<img src="/images/mitacoin-404.png" alt="" width="440" />
							<br />
						</div>
						<div className="uk-text-center uk-margin-medium">
							<button type="button" className="uk-button uk-button-default" onClick={() => history.goBack()}>
								Try Again
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default NotFound;
