import './Loading.css';

import React from 'react';

const Loading: React.FC = () => {
	return (
		<section className="uk-section uk-section-xsmall">
			<div className="uk-text-center">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-text-center mc-loading-animation uk-inline">
						<img className="mc-dots" src="/images/mitacoin-loading.svg" alt="" />
						<div className="uk-overlay uk-position-center">
							<h2 className="uk-text-gold">Loading</h2>
						</div>
						<div className="prelines">
							<div className="lines"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Loading;
