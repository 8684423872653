import React, { useEffect, useState } from 'react';

import { hooks } from '@context/index';
import { goldPrice } from '@gold/services';
import { CURRENCIES, DEFAULT_CURRENCY } from '@shared/constants';
import { IDataGold } from '@shared/utils';
import { WalletComponent } from '@user/components';

const Wallet: React.FC = () => {
	const [selectedCurrency, setSelectedCurrency] = useState<string>(DEFAULT_CURRENCY);
	const [dataGold, setDataGold] = useState<IDataGold>();
	const { wallet, isLoading: isWalletLoading } = hooks.useWalletContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleCurrencyToggle = (currency: string) => setSelectedCurrency(currency);

	useEffect(() => {
		const getGoldPrice = async () => {
			setIsLoading(true);
			const responseDataGold = await goldPrice.getGoldPrice();
			setIsLoading(false);
			if (responseDataGold.payload) {
				setDataGold(responseDataGold.payload);
			}
		};
		getGoldPrice();
	}, []);

	return (
		<>
			{isWalletLoading || isLoading ? (
				<div className="uk-text-center">
					<div uk-spinner="ratio: 4.5" className="uk-margin"></div>
				</div>
			) : null}
			{wallet && dataGold ? (
				<WalletComponent
					currencies={CURRENCIES}
					dataGold={dataGold}
					dataWallet={wallet}
					handleCurrencyToggle={handleCurrencyToggle}
					selectedCurrency={selectedCurrency}
				/>
			) : null}
		</>
	);
};

export default Wallet;
