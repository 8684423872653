import { handleError } from '@error/helpers';
import * as shared from '@shared/index';
import { MitacoinServiceResponse } from '@shared/utils';

import config from '../../../config';

const {
	services: { mitacoinApi },
} = shared;

export const buyMitacoins = async (amount: number, values: any, couponData?: any) => {
	try {
		let response;
		if (couponData) {
			response = await mitacoinApi.post(config.api.paths.buyMitacoins, {
				amount,
				couponData,
				paymentMethod: { ...values },
			});
		} else {
			response = await mitacoinApi.post(config.api.paths.buyMitacoins, {
				amount,
				paymentMethod: { ...values },
			});
		}
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const getWalletData = async (): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.get(config.api.paths.getDataWallet);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};
