import './myAccount.css';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Wallet } from '@user/containers';

interface MyAccountProps {
	hasBoughtCoins: boolean;
}

const MyAccountComponent: React.FC<MyAccountProps> = ({ hasBoughtCoins }) => {
	useEffect(() => {
		document.title = 'Your Account - Mitacoin';
	}, []);

	return (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<Link to="/market">Mitacoin</Link>
								</li>
								<li>
									<span>Your Account</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="uk-card mc-user-info mc-wallet uk-width-xlarge uk-margin-auto uk-margin-medium-top">
						<form className="uk-form-stacked">
							<Wallet />
						</form>
					</div>
					<hr className="uk-margin-remove uk-divider-light" />
					<div className="uk-text-center uk-background-muted uk-padding mc-disclaimer">
						<p className="uk-text-blue uk-text-bold uk-text-meta uk-margin-remove">
							Gold is monitored and guarded by bancash, company responsible for transport and safekeeping of securities and vaults
							design and high technology standards.
							<small className="uk-text-uppercase uk-text-bold uk-text-bluedark uk-text-xsmall">
								Operating license renewed through Resolution No. 20194100092547 of September 27/2019, granted by the
								Superintendency of Surveillance and Private Security of Colombia.
							</small>
						</p>
					</div>
					<hr className="uk-margin-remove uk-divider-light" />
				</div>
			</section>

			{hasBoughtCoins ? (
				<>
					<section className="uk-section uk-section-xsmall">
						<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto">
							<div className="uk-grid-collapse mc-user-tools uk-position-relative" uk-grid="true">
								<div className="uk-width-1-1 uk-text-center uk-margin-top">
									<h3 className="uk-heading-small uk-text-gold uk-text-center uk-margin-remove">
										{/* <span className="uk-label uk-label-danger">SOON</span>{' '} */}
										Investments Tools
									</h3>
									<p className="uk-text-content uk-text-graylightx uk-text-bold uk-margin-small-top uk-margin-bottom">
										Very soon, you will be able to use your Mitacoins like any currency in the world.
									</p>
								</div>

								<div className="mc-tool uk-width-1-2@s uk-width-1-1 uk-text-center">
									<div className="uk-card uk-padding-small">
										<Link to="/transfer" className="uk-button uk-width-1-1 uk-button-secondary" type="button">
											Transfer Mitacoins
										</Link>
										<p className="uk-text-xsmall uk-text-graylightx uk-text-uppercase uk-text-bold uk-margin-small">
											{' '}
											Transfer your gold to other Mitacoin friends in a click.
										</p>
									</div>
								</div>

								<div className="mc-tool uk-width-1-2@s uk-width-1-1 uk-text-center">
									<div className="uk-card uk-padding-small">
										<a
											className="uk-button uk-width-1-1 uk-button-secondary"
											type="button"
											href="https://wa.me/573126490547"
											target="_blank"
											rel="noopener noreferrer"
										>
											Sell your Gold
										</a>
										{/* <a
                      href="/"
                      className="uk-button uk-width-1-1 uk-button-outline uk-disabled"
                    >
                      Sell your Gold
                    </a>
                    <br /> */}
										<p className="uk-text-xsmall uk-text-graylightx uk-text-uppercase uk-text-bold uk-margin-small">
											Resell your Mitacoin gold to the house at market price.
										</p>
									</div>
								</div>
								{/* <div className="mc-tool uk-width-1-1">
                  <div
                    className="uk-alert-success uk-text-center"
                    uk-alert="true"
                  >
                    <span className="uk-text-aqualight">
                      According to the application of international legislation,
                      to prevent money laundering and terrorist financing, at
                      least 90 days must have passed from the purchase.
                    </span>
                  </div>
                </div> */}
							</div>
						</div>
					</section>
				</>
			) : null}
		</>
	);
};

export default MyAccountComponent;
