import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import intlTelInput, { Plugin } from 'intl-tel-input';

import { RegisterComponent } from '@auth/components/index';
import { SignUp } from '@auth/services';
import { createAuthFormRegisterSchema, RegisterFormValues } from '@auth/utils';
import { customHooks } from '@shared/index';

const Register: React.FC = () => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');
	const [loader, setLoader] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [loadScriptPassword, setLoadScriptPassword] = useState(false);

	const params: { referrer: string } = useParams();
	const refCode = params.referrer ? params.referrer : '';

	customHooks.useScript('/js/pswmeter.min.js', {
		onLoad: () => setLoadScriptPassword(true),
		onError: (err) => console.error(err),
	});

	const handleSubmit = async (values: RegisterFormValues) => {
		setServerError('');
		values.firstName = values.firstName.toLowerCase().trim();
		values.lastName = values.lastName.toLowerCase().trim();
		values.secondLastName = values.secondLastName.toLowerCase().trim();
		values.email = values.email.trim();
		setLoader(true);
		const response = await SignUp({ ...values });
		setLoader(false);
		if (response && response.success) {
			return history.push({
				pathname: '/account-email-sent',
				state: { email: values.email },
			});
		}
		return setServerError(response.message ?? 'Register error');
	};

	useEffect(() => {
		if (!loadScriptPassword) return;
		new window.passwordStrengthMeter({
			containerElement: '#pswmeter',
			passwordInput: '#psw-input',
			showMessage: true,
			messageContainer: '#pswmeter-message',
			messagesList: ['Password Strength', 'Very Weak!', 'Weak', 'Medium', 'Strong'],
			height: 6,
			borderRadius: 0,
			pswMinLength: 8,
			colorScore1: '#DA0D34',
			colorScore2: '#FA6400',
			colorScore3: '#0475E2',
			colorScore4: '#00B6B9',
		});
	}, [loadScriptPassword]);

	useEffect(() => {
		const input = document.querySelector('#phone');
		if (input) {
			const config = intlTelInput(input, {
				separateDialCode: true,
				initialCountry: 'co',
				utilsScript: '/js/utils.js',
			});
			itiConfig.current = config;
		}
	}, []);

	const itiConfig = React.useRef<Plugin>();

	const validate = React.useCallback(async (values: RegisterFormValues) => {
		try {
			const countryData = itiConfig.current?.getSelectedCountryData();
			values.phone = {
				number: Number(values.phone.number) || 0,
				iso2: countryData?.iso2 || '',
			};
			await validateYupSchema(values, createAuthFormRegisterSchema(itiConfig.current), false);
		} catch (error) {
			const err = error as Error;
			if (err.name === 'ValidationError') {
				return yupToFormErrors<RegisterFormValues>(err);
			} else {
				console.error(error);
			}
		}
		return {};
	}, []);

	return (
		<Formik
			// initialValues={authFormRegisterInitialValues}
			initialValues={{
				email: '',
				password: '',
				firstName: '',
				lastName: '',
				secondLastName: '',
				phone: { number: 0, iso2: '' },
				termsCheckboxes: {
					terms: false,
					privacy: false,
					rates: false,
				},
				whoReferred: refCode,
			}}
			validate={validate}
			onSubmit={handleSubmit}
		>
			{(formikProps: FormikProps<RegisterFormValues>) => (
				<>
					<RegisterComponent
						{...formikProps}
						serverError={serverError}
						loader={loader}
						showPassword={showPassword}
						setShowPassword={() => setShowPassword(!showPassword)}
						referrer={refCode}
					/>
				</>
			)}
		</Formik>
	);
};

export default Register;
