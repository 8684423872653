import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, FormikProps } from 'formik';

import { ResetPasswordFormValue } from '@auth/utils';

type ResetPasswordProps = FormikProps<ResetPasswordFormValue> & {
	serverError: string;
	loader: boolean;
	showPassword1: boolean;
	showPassword2: boolean;
	togglePassword1: () => void;
	togglePassword2: () => void;
};

const ResetPasswordComponent: React.FC<ResetPasswordProps> = ({
	serverError,
	loader,
	showPassword1,
	showPassword2,
	togglePassword1,
	togglePassword2,
	handleChange,
	handleSubmit,
	errors,
	touched,
}) => {
	return (
		<>
			<section className="uk-section uk-section-xsmall ">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<Link to="/">Mitacoin</Link>
								</li>
								<li>
									<span>New Password</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="uk-card mc-forms uk-width-xlarge uk-margin-auto uk-margin-medium-top">
						<div className="uk-width-1-1 uk-text-center uk-margin">
							<legend className="uk-heading-small uk-heading-line uk-text-center uk-margin-small">
								<span>New Password</span>
							</legend>
							<p className="uk-text-subheading uk-text-graydarkxx uk-margin-small">Set up a new password for your account:</p>
							<small className="uk-text-black uk-text-meta uk-text-bold">
								(Use a secure combination of letters, numbers or special characters.)
							</small>
						</div>
						<div className="uk-width-large uk-margin-auto">
							<form className="uk-form-stacked uk-grid-medium" uk-grid="true" onSubmit={handleSubmit} autoComplete="off">
								<div className="uk-width-1-1">
									<label className="uk-form-label">New Password</label>
									<div className="uk-form-controls">
										<div className="uk-inline uk-width-expand">
											<button
												type="button"
												className="uk-form-icon uk-form-icon-flip"
												uk-icon={`icon: ${showPassword1 ? 'unlock' : 'lock'}`}
												onClick={togglePassword1}
											></button>
											<Field
												id="psw-input"
												className={`uk-input ${errors.password && touched.password ? 'input-error' : null}`}
												type={showPassword1 ? 'text' : 'password'}
												name="password"
												onChange={handleChange}
											/>
											<div id="pswmeter" className="mt-3"></div>
											<div id="pswmeter-message" className="uk-text-uppercase uk-text-bolder"></div>
										</div>
										<div className="mc-form-validation uk-hidden">
											<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
										</div>
									</div>
									{errors.password && touched.password && (
										<ErrorMessage name="password" component="small" className="errorMessage" />
									)}
								</div>
								<div className="uk-width-1-1">
									<label className="uk-form-label">Confirm New Password</label>
									<div className="uk-form-controls">
										<div className="uk-inline uk-width-expand">
											<button
												type="button"
												className="uk-form-icon uk-form-icon-flip"
												uk-icon={`icon: ${showPassword2 ? 'unlock' : 'lock'}`}
												onClick={togglePassword2}
											></button>
											<Field
												className={`uk-input ${errors.confirmPassword && touched.confirmPassword ? 'input-error' : null}`}
												type={showPassword2 ? 'text' : 'password'}
												name="confirmPassword"
												onChange={handleChange}
											/>
										</div>
										<div className="mc-form-validation uk-hidden">
											<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
										</div>
									</div>
									{errors.confirmPassword && touched.confirmPassword && (
										<ErrorMessage name="confirmPassword" component="small" className="errorMessage" />
									)}
								</div>

								{serverError && (
									<div className="uk-width-1-1">
										<div className="uk-alert-warning" uk-alert="true">
											<button className="uk-alert-close" uk-close="true"></button>
											<p>{serverError}</p>
										</div>
									</div>
								)}
								<div className="uk-width-1-1 uk-margin-large-bottom uk-text-center">
									{loader ? (
										<button type="submit" className="uk-button uk-button-primary loading">
											Save Password
										</button>
									) : (
										<button type="submit" className="uk-button uk-button-primary">
											Save Password
										</button>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ResetPasswordComponent;
