import React from 'react';
import { toast } from 'react-toastify';

import { formatDate, formatNumUSD } from '@shared/helpers/format';
import { IHistoryPurchases } from '@shared/utils';
import { getStatusColor } from '@user/helpers';

interface HistoryPurchasesProps {
	historyPurchaseData: IHistoryPurchases[];
}

const HistoryPurchasesComponent: React.FC<HistoryPurchasesProps> = ({ historyPurchaseData }) => {
	const copyIdPurchase = (idPurchase: string) => {
		const textArea = document.createElement('textarea');
		textArea.value = idPurchase;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand('Copy');
		textArea.remove();
		toast.success('Purchase code has been copied');
		return () => {
			document.body.removeChild(textArea);
		};
	};
	return (
		<li>
			<div className="uk-grid-small mc-user-fields" uk-grid="true">
				<div className="uk-width-1-1">
					<legend className="uk-text-content uk-text-bluelight uk-heading-divider uk-text-uppercase uk-margin-bottom">
						<strong>Purchase History</strong>
					</legend>
					<table className="mc-transactions uk-table uk-table-striped uk-table-middle uk-table-responsive">
						<thead>
							<tr>
								<th className="uk-text-left">date</th>
								<th className="uk-text-center">Transaction ID</th>
								<th className="uk-text-center uk-table-shrink">Status</th>
								<th className="uk-text-right">Amount (USD)</th>
							</tr>
						</thead>
						<tbody>
							{historyPurchaseData.map((purchase: IHistoryPurchases, index: number) => {
								const validLengthPrice = (price: number) => {
									const purchase_price: string = price.toString();
									if (purchase_price.length === 2) {
										return Number(purchase.price + '00');
									}
									return price;
								};

								return (
									<tr key={index}>
										<td className="date">{formatDate(purchase.date_created)} </td>
										<td className="invoice uk-text-break uk-text-primary">
											<span
												className="uk-text-break uk-text-primary"
												onClick={() => copyIdPurchase(purchase.externalTransactionId)}
												uk-tooltip="title:Click to copy"
											>
												{purchase.externalTransactionId}
											</span>
										</td>
										<td className="status">
											<span className={`uk-label ${getStatusColor(purchase.status)}`}>{purchase.status}</span>
										</td>
										<td className="amount">
											{formatNumUSD((validLengthPrice(purchase.priceWithDiscount ?? purchase.price) * purchase.amount) / 100)}{' '}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</li>
	);
};

export default HistoryPurchasesComponent;
