import './phone-input.css';
import './intlTelInput.css';

import React from 'react';
import { useHistory } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { ErrorMessage, FormikProps } from 'formik';

import { firstLetterToUpperCase } from '@shared/helpers/format';
import { IUserDataPersonal } from '@user/utils';

type FormPersonalProps = FormikProps<IUserDataPersonal> & {
	editDataProfile: boolean;
	toggleDataUser: () => void;
	userData: IUserDataPersonal;
	serverError: string;
	isLoading: boolean;
};

const FormPersonalComponent: React.FC<FormPersonalProps> = ({
	editDataProfile,
	toggleDataUser,
	userData,
	isLoading,
	serverError,
	handleSubmit,
	initialValues,
	handleChange,
	errors,
	touched,
}) => {
	const history = useHistory();
	// const copyReferral = (copyData: string, name: string) => {
	// 	const textArea = document.createElement('textarea');
	// 	textArea.value = copyData;
	// 	document.body.appendChild(textArea);
	// 	textArea.select();
	// 	document.execCommand('Copy');
	// 	textArea.remove();
	// 	toast.success(`${name} has been copied`);
	// 	return () => {
	// 		document.body.removeChild(textArea);
	// 	};
	// };

	if (!userData) {
		return null;
	}
	return (
		<form>
			<li>
				<legend className="uk-text-content uk-text-bluelight uk-heading-divider uk-text-uppercase uk-margin-bottom">
					<strong>Account Information</strong>
				</legend>
				<div className="uk-grid-small mc-user-fields" uk-grid="true">
					<div className="uk-width-1-2@s">
						<label className="uk-form-label">First Name</label>

						<div className="uk-form-controls uk-inline">
							<input
								className={`uk-input uk-form-blank ${errors.firstName && touched.firstName ? 'input-error' : null}`}
								type="text"
								name="firstName"
								defaultValue={firstLetterToUpperCase(initialValues.firstName)}
								onChange={handleChange}
								placeholder={userData.firstName}
								disabled={editDataProfile}
							/>
						</div>
						{errors.firstName && touched.firstName && (
							<ErrorMessage name="firstName" component="small" className="errorMessage" />
						)}
					</div>
					<div className="uk-width-1-2@s">
						<label className="uk-form-label">Last Name</label>
						<div className="uk-form-controls uk-inline">
							<input
								className={`uk-input uk-form-blank ${errors.lastName && touched.lastName ? 'input-error' : null}`}
								type="text"
								name="lastName"
								defaultValue={firstLetterToUpperCase(initialValues.lastName)}
								onChange={handleChange}
								placeholder={userData.lastName}
								disabled={editDataProfile}
							/>
						</div>
						{errors.lastName && touched.lastName && <ErrorMessage name="lastName" component="small" className="errorMessage" />}
					</div>

					<div className="uk-width-1-2@s">
						<label className="uk-form-label">Second Last Name</label>
						<div className="uk-form-controls uk-inline">
							<input
								className={`uk-input uk-form-blank ${errors.secondLastName && touched.secondLastName ? 'input-error' : null}`}
								type="text"
								name="secondLastName"
								defaultValue={initialValues.secondLastName ? firstLetterToUpperCase(initialValues.secondLastName) : ''}
								onChange={handleChange}
								placeholder={userData?.secondLastName}
								disabled={editDataProfile}
							/>
						</div>
						{errors.secondLastName && touched.secondLastName && (
							<ErrorMessage name="secondLastName" component="small" className="errorMessage" />
						)}
					</div>

					<div className="uk-width-1-2@s mc-phone">
						<label className="uk-form-label">Phone</label>
						<div className="uk-form-controls">
							<input className="uk-input" id="phone" name="phone" type="tel" value={userData.phone.number} disabled />
						</div>
						<div className="mc-form-validation uk-hidden">
							<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
						</div>
					</div>

					<div className="uk-width-1-2@s">
						<label className="uk-form-label">Email</label>
						<div className="uk-form-controls uk-inline">
							<input
								className="uk-input uk-form-blank"
								type="text"
								name="email"
								defaultValue={initialValues.email}
								placeholder={userData.email}
								disabled={true}
							/>
						</div>
					</div>

					<div className="uk-width-1-2@s">
						<div className="uk-flex uk-flex-left uk-flex-middle uk-height-1-1">
							<button
								type="button"
								className="uk-button uk-button-text uk-margin-top"
								onClick={() =>
									history.push({
										pathname: '/new-password',
										state: userData.firstName,
									})
								}
							>
								New Password
							</button>
						</div>
					</div>
					<div className="uk-width-1-1 uk-text-left uk-margin-medium">
						{editDataProfile ? (
							<button type="button" className="uk-button uk-button-default" onClick={() => toggleDataUser()}>
								Edit Information
							</button>
						) : (
							<>
								{isLoading ? (
									<button type="button" className="uk-button uk-button-default loading">
										Update Information{' '}
									</button>
								) : (
									<button type="button" className="uk-button uk-button-default uk-margin-right" onClick={() => handleSubmit()}>
										Update Information
									</button>
								)}
							</>
						)}
					</div>
					{/* <div className="uk-width-1-1">
            <hr className="uk-divider-light" />
            <legend className="uk-text-content uk-text-bluelight uk-text-uppercase uk-margin-bottom">
              <strong>Invite Friends To Mitacoin By URL</strong>
            </legend>
            <p className="uk-margin-left uk-text-graydark uk-text-bold">
              {`${process.env.REACT_APP_URL}/register/${userData.referralCode}`}
              <button
                type="button"
                className="uk-align-right"
                uk-icon="icon: copy"
                onClick={() =>
                  copyReferral(
                    `${process.env.REACT_APP_URL}/register/${userData.referralCode}`,
                    'Referral Url'
                  )
                }
                uk-tooltip="title:Click to copy"
              ></button>
            </p>
            <legend className="uk-text-content uk-text-bluelight uk-text-uppercase uk-margin-bottom">
              <strong>Invite Friends To Mitacoin By Code</strong>
            </legend>
            <p className="uk-margin-left uk-text-graydark uk-text-bold">
              {userData.referralCode}
              <button
                type="button"
                className="uk-align-right"
                uk-icon="icon: copy"
                onClick={() =>
                  copyReferral(userData.referralCode, 'Referral Code')
                }
                uk-tooltip="title:Click to copy"
              ></button>
            </p>
            <hr className="uk-divider-light" />
          </div> */}

					{serverError || null}
				</div>
			</li>
		</form>
	);
};

export default FormPersonalComponent;
