import { handleError } from '@error/helpers';
import * as shared from '@shared/index';
import { MitacoinServiceResponse } from '@shared/utils';

import config from '../../../config';

const {
	services: { mitacoinApi },
} = shared;

export const getGoldPrice = async (): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.get(config.api.paths.getDataGold);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const getSupply = async (): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.get(config.api.paths.getSupply);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};
