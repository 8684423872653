/* eslint-disable */
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { MainLayout } from '@shared/layout';

// Screens
// -- Auth screens
const Register = lazy(() => import('@auth/screens/Register/Register'));
const ForgotPassword = lazy(() => import('@auth/screens/ForgotPassword/ForgotPassword'));
const EmailSent = lazy(() => import('@auth/screens/EmailSent/EmailSent'));
const AccountConfirmation = lazy(() => import('@auth/screens/AccountConfirmation/AccountConfirmation'));
const ResetPassword = lazy(() => import('@auth/screens/ResetPassword/ResetPassword'));

export const PublicRoute: React.FC<RouteProps> = ({ component, ...res }) => {
	if (window.sessionStorage.getItem('auth')) {
		const renderComponent = () => <Redirect to="/market" />;
		return <Route {...res} component={renderComponent} />;
	} else {
		return <Route {...res} component={component} />;
	}
};

export const LayoutPublicRoute = ({ component, ...res }: RouteProps) => {
	return (
		<MainLayout>
			<Suspense fallback={<div>Loading...</div>}>
				<PublicRoute {...res} component={component} />
			</Suspense>
		</MainLayout>
	);
};

export const publicRoutes: RouteProps[] = [
	// {
	// 	exact: true,
	// 	path: '/register',
	// 	component: Register,
	// },
	// {
	// 	exact: true,
	// 	path: '/register/:referrer',
	// 	component: Register,
	// },
	{
		exact: true,
		path: '/forgot-password',
		component: ForgotPassword,
	},
	{
		exact: true,
		path: '/pass-email-sent',
		component: EmailSent,
	},
	// {
	// 	exact: true,
	// 	path: '/account-email-sent',
	// 	component: EmailSent,
	// },
	// {
	// 	exact: true,
	// 	path: '/account-confirmation/:token',
	// 	component: AccountConfirmation,
	// },
	{
		exact: true,
		path: '/reset/:token',
		component: ResetPassword,
	},
];
