import React from 'react';

import { formatDate } from '@shared/helpers/format';
import { IDataGold } from '@shared/utils';

// TODO create & use dataGold type
interface GoldProps {
	dataGold?: IDataGold;
}

const GoldComponent: React.FC<GoldProps> = ({ dataGold }) => {
	return (
		<>
			<div className="uk-width-1-1 uk-text-center uk-margin-medium-top mc-market-stocks">
				<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-remove">
					<span>Gold Spot Price</span>
				</legend>
				<div className="uk-inline mc-gold-market">
					<div className="mc-market-help">
						<a href="/" className="uk-label uk-label-toggle uk-position-center" uk-toggle="target: #modal-example">
							About Gold Market
						</a>
					</div>
					<canvas width="600" height="210"></canvas>
					<img src="/images/mitacoin-goldmarket.svg" alt="" uk-cover="true"></img>

					<div className="uk-position-center mc-market-chart">
						<div className="tradingview-widget-container" id="trading_gold">
							<div className="tradingview-widget-container__widget"></div>
							<div className="tradingview-widget-copyright uk-text-blue">
								<a
									className="uk-text-black"
									href="https://www.tradingview.com/symbols/FX_IDC-XAUUSDG/"
									rel="noopener noreferrer"
									target="_blank"
								>
									<span className="uk-text-xsmall uk-text-black uk-text-uppercase">XAUUSDG Quotes by TradingView</span>
								</a>
							</div>
						</div>
					</div>

					{/* the market is open burned, not shown */}
					{dataGold ? (
						<div
							className={`uk-position-bottom uk-overlay uk-text-uppercase uk-padding-small mc-market-status ${
								dataGold.isMarketOpen ? '' : 'mc-closed'
							}`}
						>
							<strong className={`uk-width-1-1 uk-text-xsmall uk-text-black uk-margin-small`}>
								<span className={dataGold.isMarketOpen ? 'uk-text-browndark' : 'uk-text-danger'}>
									Market is {dataGold.isMarketOpen ? 'open' : 'close'}
								</span>{' '}
								(Will {dataGold.isMarketOpen ? 'close' : 'open'} in {formatDate(dataGold.nextMarketOpenDate)}.)
							</strong>
						</div>
					) : null}
				</div>
			</div>
			<div id="modal-example" uk-modal="true">
				<div className="uk-modal-dialog">
					<button className="uk-modal-close-default" type="button" uk-close="true"></button>
					<div className="uk-modal-header">
						<h4 className="uk-modal-title uk-text-center">What moves the gold market?</h4>
					</div>
					<div className="uk-modal-body">
						<p className="uk-text-bolder uk-text-graydarkxx uk-text-content">
							While gold is one of the top commodity markets, only behind crude oil, its price action doesn’t reflect traditional
							supply and demand fundamentals. The price of most commodities is usually determined by inventory levels and expected
							demand. Prices rise when inventories are low and demand is high; however, gold prices are impacted more by interest
							rates and currency fluctuations. <br />
							<br />
							Many analysts note that because of gold’s intrinsic value, it is seen more as a currency than a commodity, one of
							the reasons why gold is referred to as monetary metals. <br />
							Gold is highly inversely correlated to the U.S. dollar and bond yields. When the U.S. dollar goes down along with
							interest rates, gold rallies. Gold is more driven by sentiment then traditional fundamentals. Although these aren’t
							regulated, financial institutions play an important role, acting as market makers, providing a bid and ask price in
							the spot market.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default GoldComponent;
