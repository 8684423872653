import axios from 'axios';
import moment from 'moment';

import { clearAuthTokens, refreshToken } from '@shared/helpers';

import config from '../../../config';

const mitacoinApi = axios.create({
	baseURL: config.api.baseUrl,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

mitacoinApi.interceptors.request.use(async (request) => {
	const credentials = sessionStorage.getItem('auth');
	if (credentials) {
		const parsedCredentials = JSON.parse(credentials);
		if (moment().isAfter(parsedCredentials.expiresIn)) {
			await refreshToken(parsedCredentials.refreshToken);
		}
		const lastCredentials = sessionStorage.getItem('auth');
		if (lastCredentials) {
			const parsedLastCredentials = JSON.parse(lastCredentials);
			request.headers.Authorization = `Bearer ${parsedLastCredentials.token}`;
		}
	}
	return request;
});

mitacoinApi.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;
		if (!response) {
			clearAuthTokens();
			window.location.href = '/general-error';
			return Promise.reject(error);
		}
		const { status } = response;
		if (status === 401) {
			clearAuthTokens();
			window.location.href = '/general-error';
		} else if (status === 403 || status === 404 || status >= 500) {
			window.location.href = '/general-error';
		}
		return Promise.reject(error);
	},
);

export default mitacoinApi;
