import { handleError } from '@error/helpers';
import { ICheckoutInfo } from '@gold/utils';
import * as shared from '@shared/index';

import config from '../../../config';

const {
	services: { mitacoinApi },
} = shared;

export const getGlobalId = async (amount: number, coupon_code?: string) => {
	try {
		const response = await mitacoinApi.post(config.api.paths.payments, {
			amount,
			coupon_code,
		});
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const dispatchCheckoutStart = async (
	amount: number,
	coupon_code: string,
	firstName: string,
	lastName: string,
	secondLastName: string,
	phone: {
		number: number;
		iso2: string;
	},
	identification: {
		type: string;
		number: string;
	},
	updatePersonalInfo: boolean,
	address: string,
	addressComplement: string,
	postalCode: string,
	city: string,
	state: string,
	country: string,
) => {
	const args: ICheckoutInfo =
		phone.iso2 === '' || phone.number === 0
			? {
					amount,
					coupon_code,
					firstName,
					lastName,
					secondLastName,
					identification,
					updatePersonalInfo,
					address,
					addressComplement,
					postalCode,
					city,
					state,
					country,
			  }
			: {
					amount,
					coupon_code,
					firstName,
					lastName,
					secondLastName,
					phone,
					identification,
					updatePersonalInfo,
					address,
					addressComplement,
					postalCode,
					city,
					state,
					country,
			  };
	try {
		const response = await mitacoinApi.post(config.api.paths.checkoutPayPal, args);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const initTransaction = async (amount: number, coupon_code?: string) => {
	try {
		const response = await mitacoinApi.post(config.api.paths.payments, {
			amount,
			coupon_code,
		});
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};
