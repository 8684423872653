import { Plugin } from 'intl-tel-input';
import * as Yup from 'yup';

import * as sharedConstants from '@shared/constants';

import * as goldHelpers from '../helpers';

export const checkoutFormInitialValues = {
	firstName: '',
	lastName: '',
	secondLastName: '',
	phone: { number: 0, iso2: '' },
	idType: 'CC',
	idNumber: '',
	updatePersonalInfo: false,
	address: '',
	addressComplement: '',
	postalCode: '',
	city: '',
	state: '',
	country: 'CO',
};

export const checkoutFormSchema = (itiFinal?: Plugin) =>
	Yup.object().shape({
		firstName: Yup.string()
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
			.trim()
			.required('First Name is required'),
		lastName: Yup.string()
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
			.trim()
			.required('Last Name is required'),
		secondLastName: Yup.string()
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
			.trim()
			.optional(),
		phone: Yup.object({
			number: Yup.string().matches(/^[0-9\b]+$/, 'Please enter a valid value'),
			iso2: Yup.string(),
		}).optional(),
		idType: Yup.string().required('ID Type is required'),
		idNumber: Yup.string().matches(/^\d+$/, 'Please enter a valid number').required('ID Number is required'),
		updatePersonalInfo: Yup.boolean().required(),
		address: Yup.string().required('Address is required').trim().max(sharedConstants.ADDRESS_MAX_LENGTH, 'Address is too long'),
		addressComplement: Yup.string()
			.optional()
			.trim()
			.max(sharedConstants.ADDRESS_COMPLEMENT_MAX_LENGTH, 'Address complement is too long'),
		postalCode: Yup.string()
			.optional()
			.trim()
			.max(sharedConstants.POSTAL_CODE_MAX_LENGTH, 'Postal code is too long')
			.when('country', {
				is: (country) => country !== 'CO' && goldHelpers.shouldShowPostalCode(country),
				then: Yup.string()
					.required('Postal code is required')
					.trim()
					.max(sharedConstants.POSTAL_CODE_MAX_LENGTH, 'Postal code is too long'),
			}),
		city: Yup.string().required('City is required').trim().max(sharedConstants.CITY_MAX_LENGTH, 'City is too long'),
		state: Yup.string()
			.optional()
			.trim()
			.max(sharedConstants.STATE_MAX_LENGTH, 'State is too long')
			.when('country', {
				is: (country) => country !== 'CO',
				then: Yup.string().required('State is required').trim().max(sharedConstants.STATE_MAX_LENGTH, 'State is too long'),
			}),
		country: Yup.string().required('Country is required'),
	});

export type CheckoutValues = {
	firstName: string;
	lastName: string;
	secondLastName: string;
	phone: { number: number; iso2: string };
	idType: string;
	idNumber: string;
	updatePersonalInfo: boolean;
	address: string;
	addressComplement: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
};

export interface ICheckoutInfo {
	firstName: string;
	lastName: string;
	secondLastName: string;
	phone?: {
		number: number;
		iso2: string;
	};
	identification: {
		type: string;
		number: string;
	};
	updatePersonalInfo: boolean;
	amount: number;
	coupon_code: string;
	address: string;
	addressComplement?: string;
	postalCode?: string;
	city: string;
	state?: string;
	country: string;
}
