import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, FormikProps } from 'formik';

import { ForgotPasswordFormValues } from '@auth/utils';

type FormForgotProps = FormikProps<ForgotPasswordFormValues> & {
	serverError: string;
	loader: boolean;
};

const ForgotPassword: React.FC<FormForgotProps> = ({ serverError, loader, handleSubmit, handleChange, errors, touched }) => {
	useEffect(() => {
		document.title = 'Reset Password - Mitacoin';
	}, []);
	return (
		<>
			<section className="uk-section uk-section-xsmall ">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<Link to="/">Mitacoin</Link>
								</li>
								<li>
									<span>Forgot my Password</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="uk-card mc-forms uk-width-large uk-margin-auto uk-margin-medium-top">
						<form className="uk-form-stacked uk-grid-medium" uk-grid="true" onSubmit={handleSubmit} autoComplete="off">
							<div className="uk-width-1-1 uk-text-center uk-margin">
								<legend className="uk-heading-small uk-heading-line uk-text-center uk-margin-small">
									<span>Reset Account password</span>
								</legend>
								<p className="uk-text-subheading uk-text-graydarkxx uk-margin-small">Enter your account information</p>
							</div>
							<div className="uk-width-1-1">
								<label className="uk-form-label">Email Account</label>
								<div className="uk-form-controls">
									<div className="uk-inline uk-width-expand">
										<span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: mail"></span>
										<Field
											className={`uk-input ${errors.email && touched.email ? 'input-error' : null}`}
											type="email"
											placeholder="myemail@***mail.com"
											name="email"
											onChange={handleChange}
										/>
									</div>
									<div className="mc-form-validation uk-hidden">
										<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
									</div>
								</div>
								{errors.email && touched.email && <ErrorMessage name="email" component="small" className="errorMessage" />}
								{serverError && (
									<div className="uk-margin">
										<div className="uk-alert-warning" uk-alert="true">
											<button className="uk-alert-close" uk-close="true"></button>
											<p>
												{serverError}.
												{serverError === 'Email could not be sent' ? (
													<button type="submit" className="uk-button uk-button-text uk-text-danger">
														TRY AGAIN
													</button>
												) : null}
											</p>
										</div>
									</div>
								)}
							</div>
							<div className="uk-width-1-1 uk-margin-large-bottom uk-margin ">
								{loader ? (
									<button type="submit" className="uk-button uk-button-primary loading">
										Reset Password{' '}
									</button>
								) : (
									<button type="submit" className="uk-button uk-button-primary">
										Reset Password
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default ForgotPassword;
