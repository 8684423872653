import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';

import * as shared from '@shared/index';
import { IUserData } from '@shared/utils';
import { FormBilling as FormBillingComponent } from '@user/components';
import { getUserInfo, updateBillingInfo } from '@user/services';
import { createFormBillingSchema, IUserDataBilling } from '@user/utils';
interface FormBillingProps {
	userInfo: IUserData;
	setUserInfo: (info: IUserData) => null;
}

const FormBilling: React.FC<FormBillingProps> = ({ userInfo, setUserInfo }) => {
	const [editDataProfile, setEditDataProfile] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [serverError, setServerError] = useState('');

	const [country, setCountry] = useState(userInfo?.country ?? '');
	const [state, setState] = useState(userInfo?.state ?? '');

	const handleSubmit = async (values: IUserDataBilling) => {
		values.country = country;
		values.state = state;
		setIsLoading(true);
		setServerError('');
		const response = await updateBillingInfo({ ...values });
		setIsLoading(false);
		if (response && response.success) {
			setEditDataProfile(true);
			shared.helpers.setAuthTokens(response.payload);
			setIsLoading(true);
			const responseDataUpdate = await getUserInfo();
			setIsLoading(false);
			if (responseDataUpdate.success && responseDataUpdate.payload) {
				return setUserInfo(responseDataUpdate.payload);
			}
		}
		return setServerError(response.message ?? 'Update user error');
	};

	const formBillingSchema = React.useMemo(() => createFormBillingSchema(country, state), [country, state]);

	if (!userInfo) {
		return null;
	}

	return (
		<Formik
			initialValues={{
				address: userInfo.address ? userInfo.address : '',
				city: userInfo.city ? userInfo.city : '',
				state: userInfo.state ? userInfo.state : state,
				country: userInfo.country ? userInfo.country : country,
			}}
			validationSchema={formBillingSchema}
			onSubmit={handleSubmit}
		>
			{(formikProps: FormikProps<IUserDataBilling>) => (
				<>
					<FormBillingComponent
						{...formikProps}
						editDataProfile={editDataProfile}
						toggleDataUser={() => setEditDataProfile(!editDataProfile)}
						isLoading={isLoading}
						serverError={serverError}
						setCountry={(val: string) => setCountry(val)}
						setCity={(val: string) => setState(val)}
						country={country}
						state={state}
					/>
				</>
			)}
		</Formik>
	);
};

export default FormBilling;
