import { Plugin } from 'intl-tel-input';
import * as Yup from 'yup';

// Login
export type LoginFormValues = {
	email: string;
	password: string;
};

export const authFormLoginInitialValues = {
	email: '',
	password: '',
};

export const authFormLoginSchema = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required'),
});

// Register
type TermsAndConditionsCheckboxes = {
	// every field for the checkboxes typing should be a document
	// that the user must agree with
	terms: boolean;
	privacy: boolean;
	rates: boolean;
};

export type RegisterFormValues = {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	secondLastName: string;
	phone: { number: number; iso2: string };
	termsCheckboxes: TermsAndConditionsCheckboxes;
	whoReferred: string;
};

export const authFormRegisterInitialValues = {
	email: '',
	password: '',
	firstName: '',
	lastName: '',
	secondLastName: '',
	phone: { number: 0, iso2: '' },
	termsCheckboxes: {
		terms: false,
		privacy: false,
		rates: false,
	},
};

const termsAndConditionsCheckboxesSchema = Yup.object({
	terms: Yup.boolean().oneOf([true], 'Must accept Terms and Conditions'),
	privacy: Yup.boolean().oneOf([true], 'Must accept Privacy and Confidentiality'),
	rates: Yup.boolean().oneOf([true], 'Must accept Rates'),
});

export const createAuthFormRegisterSchema = (itiFinal?: Plugin) =>
	Yup.object().shape({
		firstName: Yup.string()
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
			.trim()
			.required('First Name is required'),
		lastName: Yup.string()
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
			.trim()
			.required('Last Name is required'),
		secondLastName: Yup.string()
			.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
			.trim()
			.optional(),
		phone: Yup.object({
			number: Yup.string()
				.matches(/^[0-9\b]+$/, 'Please enter a valid value')
				.required('Phone is required'),
			iso2: Yup.string(),
		}),
		// .test('validate-phone', 'phone-error', function () {
		//   const { path, createError } = this;
		//   const isValid = itiFinal?.isValidNumber();
		//   if (!isValid) {
		//     const error = itiFinal?.getValidationError();
		//     if (error === window.intlTelInputUtils.validationError.TOO_SHORT) {
		//       return createError({ path, message: 'The phone is too short' });
		//     }
		//     if (
		//       error ===
		//       window.intlTelInputUtils.validationError.INVALID_COUNTRY_CODE
		//     ) {
		//       return createError({
		//         path,
		//         message: 'Country code is required',
		//       });
		//     }
		//     if (error === window.intlTelInputUtils.validationError.TOO_LONG) {
		//       return createError({ path, message: 'The phone is too long' });
		//     }
		//     return createError({ path, message: 'Please check this field' });
		//   }
		//   return true;
		// }),
		email: Yup.string().email('Email is invalid').required('Email is required').trim(),
		password: Yup.string().required('Password is required'),
		termsCheckboxes: termsAndConditionsCheckboxesSchema,
		whoReferred: Yup.string()
			// .matches(/MIT-\w{5}/g, 'Please enter a valid code ej. MIT-123AB')
			// .max(9, 'Must be exactly 9 characters')
			.optional(),
	});

// Forgot Password
export type ForgotPasswordFormValues = {
	email: string;
};

export const authFormForgotInitialValues = {
	email: '',
};

export const authFormForgotSchema = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
});

// Reset Password
export type ResetPasswordFormValue = {
	password: string;
	confirmPassword: string;
};

export const authFormResetPasswordInitialValues = {
	password: '',
	confirmPassword: '',
};

export const authFormResetPasswwordSchema = Yup.object().shape({
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password')], 'Both password need to be the same')
		.required('Confirm password is required'),
});
