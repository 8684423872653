import React from 'react';
import { Link } from 'react-router-dom';

import { urls } from '@shared/helpers';

const PurchaseSuccess: React.FC = () => {
	const platform = urls.useQuery().get('platform');
	return (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="mc-shop uk-text-center">
					<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
						<div className="uk-margin-auto uk-text-large uk-text-center uk-text-aqualight">
							<img src="/images/mitacoin-cart-ok.png" alt="" width="200" />
							<br />
							<p className="uk-margin-small">Your payment was successfully processed</p>
							<p className="uk-text-intro uk-text-goldlight uk-margin-small">
								Your purchased is completed and approved. Soon you will have your MITACOINS in your wallet.
							</p>
						</div>
						<p className="uk-text-graylightxx uk-text-bolder uk-text-content">
							The blockchain technology used by Mitacoin is incorruptible. Secured through cryptography, a branch of mathematics
							that provides a highly secure means of facilitating and recording transactions on the network.
						</p>
						<div className="uk-text-center uk-margin-medium">
							<Link to="/my-account" type="submit" className="uk-button uk-button-primary">
								Go to My Wallet
							</Link>
						</div>
						<div className="uk-text-center uk-padding-small uk-text-graylight uk-text-small uk-text-uppercase uk-text-bold uk-margin-large-top">
							Secure payments powered by{' '}
							{platform === 'pp' ? (
								<img className="uk-margin-small-left" src="/images/paypal-logo.png" alt="PayPal Logo" />
							) : (
								<img className="uk-margin-small-left" src="" alt="" />
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default PurchaseSuccess;
