import React from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import { auth, shared } from '../modules';
import { LayoutPrivateRoute, privateRoutes } from './PrivateRoutes';
import { LayoutPublicRoute, PublicRoute, publicRoutes } from './PublicRoutes';

const { MainLayout } = shared.layout;
function LayoutRoute({ component, ...res }: RouteProps) {
	return (
		<MainLayout>
			<Route {...res} component={component} />
		</MainLayout>
	);
}

const Router: React.FC = () => (
	<BrowserRouter>
		<Switch>
			<PublicRoute exact path="/" component={auth.screens.Login} />
			{publicRoutes.map((route) => (
				<LayoutPublicRoute exact={route.exact} path={route.path} component={route.component} key={route as any} />
			))}
			{privateRoutes.map((route) => (
				<LayoutPrivateRoute exact={route.exact} path={route.path} component={route.component} key={route as any} />
			))}
			<LayoutRoute exact path="/general-error" component={shared.screens.GeneralError} />
			<LayoutRoute exact path="/session-expired" component={shared.screens.SessionExpired} />
			<LayoutRoute exact path="/not-found" component={shared.screens.NotFound} />

			<Route>
				<Redirect to="/not-found" />
			</Route>
		</Switch>
	</BrowserRouter>
);

export default Router;
