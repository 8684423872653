/* eslint-disable */
import './checkout.css';
import './phone-input.css';
import './intlTelInput.css';

import React, { useEffect } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, FormikProps } from 'formik';

import { CheckoutValues } from '@gold/utils';
import { CurrencyToggle, InputAddress } from '@shared/components';
import { COP_INCREMENT } from '@shared/constants';
import { formatNumberCOP, formatNumUSD } from '@shared/helpers/format';

type CheckoutProps = FormikProps<CheckoutValues> & {
	amount: number;
	priceTotal: number;
	priceTotalCOP: number;
	couponPercent: number;
	goldPrice: number;
	goldPriceCOP: number;
	loader: boolean;
	showPostalCode: boolean;
	selectedCurrency: string;
	handleCurrencyToggle: (currency: string) => void;
	currencies: string[];
	formValuesCountry: string;
};

const CheckoutComponent: React.FC<CheckoutProps> = ({
	amount,
	priceTotal,
	priceTotalCOP,
	couponPercent,
	goldPrice,
	goldPriceCOP,
	values,
	handleChange,
	handleSubmit,
	setFieldValue,
	errors,
	touched,
	isSubmitting,
	loader,
	showPostalCode,
	selectedCurrency,
	handleCurrencyToggle,
	currencies,
	formValuesCountry,
}) => {
	// reflect the increment for COP currency values
	const goldPriceCOPWithIncrement = (1 + COP_INCREMENT) * goldPriceCOP;
	const priceTotalCOPWithIncrement = (1 + COP_INCREMENT) * priceTotalCOP;

	useEffect(() => {
		const input = document.querySelector('#phone');

		if (input) {
			input.addEventListener('countrychange', function () {
				setFieldValue('phone.iso2', '');
			});
		}
	}, []);

	useEffect(() => {
		const keys = Object.keys(errors);
		if (isSubmitting && keys.length > 0) {
			if (keys[0] === 'phone') {
				return (document.querySelector('#phone') as HTMLElement).focus();
			}
			(document.querySelector(`[name="${keys[0]}"]`) as HTMLElement).focus();
		}
	}, [isSubmitting, errors, touched]);
	return (
		<>
			<CurrencyToggle currencies={currencies} handleToggle={handleCurrencyToggle} selectedCurrency={selectedCurrency} />
			<div className="uk-card mc-shop mc-shop-checkout uk-width-xlarge uk-margin-auto uk-margin-medium-top">
				<form className="uk-form-stacked" onSubmit={handleSubmit}>
					<div className="uk-width-1-1 uk-text-center uk-margin">
						<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-small">
							<span>Checkout</span>
						</legend>
						<p className="uk-text-subheading uk-text-bluelight uk-margin-small">Review your purchase & payment options</p>
					</div>
					<div className="uk-grid-collapse mc-container" uk-grid="true">
						<div className="uk-width-medium uk-text-center mc-cart-img">
							<div className="uk-inline">
								<img className="mc-shop-dots" src="/images/mitacoin-dots.png" alt="" />
								<div className="uk-overlay uk-position-cover">
									<img src="/images/mitacoin-cart.png" alt="" />
								</div>
							</div>
						</div>
						<div className="uk-width-medium mc-cart-list">
							<div className="uk-text-right">
								<Link to="/shop" className="uk-button uk-button-info uk-button-pill mc-edit-cart">
									<img src="/images/icons/ico-cart.svg" alt="" /> Back to Cart
								</Link>
							</div>
							<div className="uk-padding-small">
								<table className="uk-table uk-table-small uk-table-divider uk-table-middle mc-cart-table">
									<thead>
										<tr>
											<th className="uk-text-right">Item</th>
											<th className="uk-text-right">Quantity</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="uk-text-right mc-item">
												<strong className="uk-text-meta uk-text-uppercase uk-text-bluedark">Mitacoins</strong>
											</td>
											<td className="uk-text-right mc-value">
												<strong className="uk-text-number uk-text-large uk-text-bluelight">{amount}</strong>
											</td>
										</tr>
										<tr>
											<td className="uk-text-right mc-item">
												<strong className="uk-text-meta uk-text-uppercase uk-text-bluedark">Price</strong>
											</td>
											<td className="uk-text-right mc-value">
												<strong className="uk-text-number uk-text-bluelight">
													<small className="uk-text-uppercase">({selectedCurrency})</small>{' '}
													{selectedCurrency === 'usd'
														? `${formatNumUSD(goldPrice)}`
														: `${formatNumberCOP(goldPriceCOPWithIncrement.toString(), 2)}`}
												</strong>
											</td>
										</tr>
										{couponPercent ? (
											<tr className="mc-coupon">
												<td className="uk-text-right mc-item">
													<small className="uk-text-danger uk-text-uppercase">Coupon MITACOIN</small>
												</td>
												<td className="uk-text-right mc-value">
													<strong className="uk-text-number uk-text-aqua">-{couponPercent * 100}% </strong>
												</td>
											</tr>
										) : null}
										<tr className="mc-total">
											<td className="uk-text-right mc-item">
												<strong className="uk-text-meta uk-text-uppercase uk-text-bluedark">Total to pay</strong>
											</td>
											<td className="uk-text-right mc-value">
												<small className="uk-text-uppercase uk-text-bluelight">({selectedCurrency})</small>
												<strong className="uk-text-number uk-text-bluelight">
													{selectedCurrency === 'usd'
														? `${formatNumUSD(priceTotal)}`
														: `${formatNumberCOP(priceTotalCOPWithIncrement.toString(), 2)}`}
												</strong>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="uk-width-1-1 uk-margin-large-top uk-margin-medium-bottom">
						<hr className="uk-margin-small" />
						<p className="uk-text-heading uk-text-uppercase uk-text-blue uk-margin-remove">
							<img className="uk-margin-small-right" src="../images/icons/ico-billing.svg" alt="" /> Billing Information
						</p>
						<hr className="uk-margin-small" />
					</div>

					<div className="uk-grid-medium mc-billing" uk-grid="true">
						<div className="uk-width-1-2@s">
							<label className="uk-form-label">First Name*</label>
							<div className="uk-form-controls">
								<Field className="uk-input" type="text" placeholder="" name="firstName" />
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.firstName && touched.firstName ? (
								<ErrorMessage name="firstName" component="small" className="errorMessage" />
							) : null}
						</div>
						<div className="uk-width-1-2@s">
							<label className="uk-form-label">Last Name*</label>
							<div className="uk-form-controls">
								<Field className="uk-input" type="text" placeholder="" name="lastName" />
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.lastName && touched.lastName ? (
								<ErrorMessage name="lastName" component="small" className="errorMessage" />
							) : null}
						</div>
						<div className="uk-width-1-2@s">
							<label className="uk-form-label">Second Last Name</label>
							<div className="uk-form-controls">
								<Field className="uk-input" type="text" placeholder="" name="secondLastName" />
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.secondLastName && touched.secondLastName ? (
								<ErrorMessage name="secondLastName" component="small" className="errorMessage" />
							) : null}
						</div>
						<div className="uk-width-1-2@s mc-phone">
							<label className="uk-form-label">Mobile Phone Number*</label>
							<div className="uk-form-controls">
								<input
									className={`uk-input ${errors.phone && touched.phone ? 'input-error' : null}`}
									id="phone"
									name="phone.number"
									onChange={handleChange}
									type="tel"
								/>
							</div>
							{errors.phone ? (
								typeof errors.phone === 'string' ? (
									<ErrorMessage name="phone" component="small" className="errorMessage" />
								) : (
									<ErrorMessage name="phone.number" component="small" className="errorMessage" />
								)
							) : null}
						</div>

						<div className="uk-width-1-2@s">
							<label className="uk-form-label">ID Type*</label>
							<div className="uk-form-controls">
								<div uk-form-custom="target: > * > span:first-child">
									<Field as="select" className="uk-input" name="idType">
										<option value="CC">C.C.</option>
										<option value="CE">C.E.</option>
										<option value="NIT">NIT</option>
										<option value="Otro">Other</option>
									</Field>
									<button className="uk-button uk-button-default" type="button">
										<span></span>
										<span uk-icon="icon: chevron-down"></span>
									</button>
								</div>
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.idType && touched.idType ? <ErrorMessage name="idType" component="small" className="errorMessage" /> : null}
						</div>
						<div className="uk-width-1-2@s">
							<label className="uk-form-label">ID Number*</label>
							<div className="uk-form-controls">
								<div uk-form-custom="target: > * > span:first-child">
									<Field className="uk-input" type="text" placeholder="" name="idNumber" onChange={handleChange} />
								</div>
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.idNumber && touched.idNumber ? (
								<ErrorMessage name="idNumber" component="small" className="errorMessage" />
							) : null}
						</div>

						<div className="uk-width-1-1 mc-address">
							<label className="uk-form-label">Address*</label>
							<div className="uk-form-controls">
								<InputAddress name="address" className="uk-input" />
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.address && touched.address ? (
								<ErrorMessage name="address" component="small" className="errorMessage" />
							) : null}
						</div>

						{values.country !== 'CO' ? (
							<div className="uk-width-1-1 mc-address">
								<label className="uk-form-label">Address Complement</label>
								<div className="uk-form-controls">
									<Field
										className="uk-input"
										type="text"
										placeholder="e.g. Apt. 123"
										name="addressComplement"
										onChange={handleChange}
									/>
								</div>
								{errors.addressComplement && touched.addressComplement ? (
									<ErrorMessage name="addressComplement" component="small" className="errorMessage" />
								) : null}
							</div>
						) : null}

						<div className="uk-width-1-2@s">
							<label className="uk-form-label">Country*</label>
							<div className="uk-form-controls">
								<div uk-form-custom="target: > * > span:first-child">
									<CountryDropdown
										value={values.country}
										labelType="full"
										valueType="short"
										name="country"
										onChange={(val: string) => setFieldValue('country', val)}
										// defaultOptionLabel="Colombia"
									/>
									<button className="uk-button uk-button-default" type="button">
										<span></span>
										<span uk-icon="icon: chevron-down"></span>
									</button>
								</div>
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.country && touched.country ? (
								<ErrorMessage name="country" component="small" className="errorMessage" />
							) : null}
						</div>

						{values.country !== 'CO' ? (
							<div className="uk-width-1-2@s mc-address">
								<label className="uk-form-label">State*</label>
								<div className="uk-form-controls">
									<Field className="uk-input" type="text" placeholder="" name="state" onChange={handleChange} />
								</div>
								{errors.state && touched.state ? <ErrorMessage name="state" component="small" className="errorMessage" /> : null}
							</div>
						) : null}

						<div className="uk-width-1-2@s">
							<label className="uk-form-label">City*</label>
							<div className="uk-form-controls">
								<Field className="uk-input" type="text" placeholder="" name="city" onChange={handleChange} />
								<div className="mc-form-validation uk-hidden">
									<img src="../images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
							</div>
							{errors.city && touched.city ? <ErrorMessage name="city" component="small" className="errorMessage" /> : null}
						</div>

						{values.country !== 'CO' && showPostalCode ? (
							<div className="uk-width-1-2@s mc-address">
								<label className="uk-form-label">Postal Code*</label>
								<div className="uk-form-controls">
									<Field className="uk-input" type="text" placeholder="e.g. 050021  " name="postalCode" onChange={handleChange} />
								</div>
								{errors.postalCode && touched.postalCode ? (
									<ErrorMessage name="postalCode" component="small" className="errorMessage" />
								) : null}
							</div>
						) : null}

						<div className="uk-width-1-1 mc-terms">
							<div className="uk-form-controls">
								<label className="uk-margin-small" uk-lightbox="true">
									<Field
										className="uk-checkbox uk-align-left uk-margin-small-right"
										type="checkbox"
										name="updatePersonalInfo"
										onChange={handleChange}
									/>
									Save as default information.
								</label>
							</div>
						</div>
					</div>

					<div className="uk-width-1-1 uk-text-center uk-margin mc-pay">
						<button
							type="submit"
							id="mercadopagoContainer"
							disabled={loader || isSubmitting}
							className={`uk-button uk-button-large uk-button-primary ${loader ? 'loading' : ''} uk-margin-medium-bottom`}
						>
							<img src="/images/mc-crown.png" width="31" alt="" /> Place My Order
						</button>
					</div>
				</form>
				{formValuesCountry !== 'CO' && (
					<div className="uk-width-1-1 uk-text-left uk-margin-large-bottom mc-infobox">
						<hr />
						<img src="/images/icons/ico-info.svg" className="uk-align-left uk-margin-small-right uk-margin-small-left" alt="" />
						<span className="uk-text-bold uk-text-graydarkx uk-text-small">
							Your information is secured by {values.country === 'CO' ? 'Paypal' : 'PayPal'} payments services. This means we
							NEVER have access to your credit card information.
						</span>
						<hr />
					</div>
				)}
			</div>
		</>
	);
};

export default CheckoutComponent;
