export default {
	environmentName: process.env.REACT_APP_ENVIRONMENT_NAME,
	currentInitialSupply: process.env.REACT_APP_INITIAL_SUPPLY,
	sentryDsn: process.env.REACT_APP_SENTRY_DSN,
	idleTimerExpiredSession: 300000, // 5min. milliseconds
	paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
	adminNumber: process.env.REACT_APP_ADMIN_NUMBER,
	apiKeyGoogleAddress: process.env.REACT_APP_API_KEY_GOOGLE_ADDRESS,
	api: {
		baseUrl: `${process.env.REACT_APP_WS_URL}/api`,
		paths: {
			login: '/auth',
			register: '/auth/register',
			confirmMyAccount: '/auth/account-confirmation',
			forgotPassword: '/auth/forgot',
			resetPassword: '/auth/reset',
			refresh: '/auth/refresh',
			enrollUser: '/auth/enrollUser',
			referralsCode: '/auth/referrals-code',
			getUserInfo: '/user/me',
			updateUserPersonalInfo: '/user/updateUserPersonalInfo',
			updateUserBillingInfo: '/user/updateUserBillingInfo',
			updatePassword: '/user/updatePassword',
			getDataGold: '/gold',
			getSupply: '/gold/supply',
			getDataWallet: '/wallet',
			buyMitacoins: '/wallet',
			payments: '/payments',
			checkoutMercadoPago: '/payments/checkout/mercadopago',
			checkoutPayPal: '/payments/checkout/paypal',
			checkoutWhatsapp: 'payments/checkout/whatsapp',
			transferMitacoins: '/transfer',
			confirmedTransferMitacoins: '/transfer/confirmed',
			transferHistory: '/transfer/history',
			inviteFriend: '/transfer/inviteFriend',
			sendANewCode: '/transfer/sendANewCode',
		},
	},
};
