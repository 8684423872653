import React from 'react';

import { IUserData } from '@shared/utils';
import { FormBilling, FormPersonal, HistoryPurchases, TransferHistory } from '@user/containers';
interface PersonalInfoProps {
	userInfo: IUserData;
	setUserInfo: (info: IUserData) => null;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ userInfo, setUserInfo }) => {
	return (
		<section className="uk-section uk-section-xsmall">
			<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-large-bottom">
				<div className="uk-grid-collapse uk-grid-match mc-user-info" uk-grid="true">
					<div className="uk-width-1-1 uk-text-center uk-margin-medium-top uk-margin-bottom">
						<h3 className="uk-heading uk-text-bluedark uk-text-center uk-margin-remove">Your Account Info</h3>
					</div>
					<hr className="uk-width-1-1" />
					<div className="uk-width-1-5@s">
						<ul className="uk-tab-left mc-tabs" uk-tab="media: 640; connect: #info-tabs; animation: uk-animation-fade">
							<li>
								<a className="uk-text-right" href="/">
									<span className="uk-icon">
										<img src="/images/icons/ico-profile-user.svg" alt="" />
									</span>
								</a>
							</li>
							<li>
								<a className="uk-text-right" href="/">
									<span className="uk-icon">
										<img src="/images/icons/ico-profile-billing.svg" alt="" />
									</span>
								</a>
							</li>

							<li>
								<a className="uk-text-right" href="/">
									<span className="uk-icon">
										<img src="/images/icons/ico-profile-purchases.svg" alt="" />
									</span>
								</a>
							</li>

							<li>
								<a className="uk-text-right" href="/">
									<span className="uk-icon">
										<img src="/images/icons/ico-profile-transfers.svg" alt="" />
									</span>
								</a>
							</li>
						</ul>
					</div>
					<div className="uk-width-4-5@s">
						<div className="mc-tabs-info">
							<ul id="info-tabs" className="uk-switcher">
								<div>
									<FormPersonal userInfo={userInfo} setUserInfo={setUserInfo} />
								</div>
								{userInfo ? (
									<div>
										<FormBilling userInfo={userInfo} setUserInfo={setUserInfo} />
									</div>
								) : null}
								<div>
									<HistoryPurchases />
								</div>
								<div>
									<TransferHistory />
								</div>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="uk-text-center">
				<button className="uk-text-bluelightx" uk-totop="true" uk-scroll="true"></button>
			</div>
		</section>
	);
};

export default PersonalInfo;
