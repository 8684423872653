import { handleError } from '@error/helpers';
import { clearAuthTokens } from '@shared/helpers';
import * as shared from '@shared/index';
import { MitacoinServiceResponse } from '@shared/utils';

import config from '../../../config';

const {
	services: { mitacoinApi },
} = shared;

const SignIn = async ({ email, password }: { email: string; password: string }): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.login, {
			email,
			password,
		});

		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};

const SignUp = async ({
	email,
	password,
	firstName,
	lastName,
	secondLastName,
	phone,
	whoReferred,
}: {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	secondLastName: string;
	phone: { number: number; iso2: string };
	whoReferred?: string;
}): Promise<MitacoinServiceResponse> => {
	let data;
	if (whoReferred !== '') {
		data = {
			email,
			password,
			firstName,
			lastName,
			secondLastName,
			birthDate: '01-02-1990',
			phone,
			whoReferred,
		};
	} else {
		data = {
			email,
			password,
			firstName,
			lastName,
			secondLastName,
			birthDate: '01-02-1990',
			phone,
		};
	}

	try {
		const response = await mitacoinApi.post(config.api.paths.register, data);
		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};

const ForgotPassword = async ({ email }: { email: string }): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.forgotPassword, {
			email,
		});
		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};

const PasswordReset = async ({ password, token }: { password: string; token: string }): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(`${config.api.paths.resetPassword}/${token}`, { password });
		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};

const Logout = async () => {
	try {
		window.sessionStorage.setItem('auth', '');
		return clearAuthTokens();
	} catch (error) {
		return handleError(error);
	}
};

const confirmMyAccount = async ({ token }: { token: string }): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.confirmMyAccount, {
			token,
		});

		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};

const enrollUser = async ({ token }: { token: string }): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.enrollUser, {
			token,
		});

		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};

const referralsCode = async (email: string): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.referralsCode, {
			email,
		});
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export { confirmMyAccount, enrollUser, ForgotPassword, Logout, PasswordReset, referralsCode, SignIn, SignUp };
