import React from 'react';

import { useWalletContext } from '@context/hooks';
import { MyAccount as MyAccountComponent } from '@user/components';

const MyAccount: React.FC = () => {
	const { wallet } = useWalletContext();
	const hasBoughtCoins = wallet ? wallet.currentMitacoinAmount > 0 : false;
	return (
		<>
			<MyAccountComponent hasBoughtCoins={hasBoughtCoins} />
		</>
	);
};

export default MyAccount;
