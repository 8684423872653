import './checkout.css';

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import CheckoutFormContainer from '../CheckoutForm/CheckoutForm';

const Checkout: React.FC = () => {
	const { state } = useLocation();
	const [goldDataErrorMessage, setGoldDataErrorMessage] = useState('');

	useEffect(() => {
		if (!state) {
			return setGoldDataErrorMessage('Service not available');
		}
		document.title = 'Checkout - Mitacoin';
	}, [state]);

	return (
		<>
			{goldDataErrorMessage ? (
				<section className="uk-section uk-section-xsmall">
					<div className="mc-shop uk-text-center">
						<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
							<div className="uk-width-xlarge uk-margin-auto uk-text-large uk-text-center uk-text-orange">
								<img src="/images/mitacoin-cart-error.png" alt="" width="200" />
								<br />
								<p className="uk-margin-small">{goldDataErrorMessage}</p>
								<p className="uk-text-intro uk-text-graylightxx uk-margin-small">Please try again or contact us.</p>
							</div>
							<div className="uk-text-center uk-margin-medium">
								<Link to="/market" type="submit" className="uk-button uk-button-default">
									Back to market
								</Link>
							</div>
						</div>
					</div>
				</section>
			) : null}
			<CheckoutFormContainer />
		</>
	);
};

export default Checkout;
