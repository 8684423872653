import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';

import { ResetPasswordComponent } from '@auth/components/index';
import { PasswordReset } from '@auth/services';
import { authFormResetPasswordInitialValues, authFormResetPasswwordSchema, ResetPasswordFormValue } from '@auth/utils';
import { customHooks } from '@shared/index';

const ResetPassword: React.FC = () => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');
	const [loader, setLoader] = useState(false);

	const [showPassword1, setShowPassword1] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);
	const [loadScriptPassword, setLoadScriptPassword] = useState(false);

	customHooks.useScript('/js/pswmeter.min.js', {
		onLoad: () => setLoadScriptPassword(true),
		onError: (err) => console.error(err),
	});

	interface ParamTypes {
		token: string;
	}

	const { token } = useParams<ParamTypes>();

	const handleSubmit = async (values: ResetPasswordFormValue) => {
		setLoader(true);
		setServerError('');
		const response = await PasswordReset({
			password: values.confirmPassword,
			token,
		});
		setLoader(false);
		if (response && response.success) {
			return history.push('/');
		}
		return setServerError(response.message ?? 'Login error');
	};

	useEffect(() => {
		document.title = 'New Password - Mitacoin';
		if (!loadScriptPassword) return;
		new window.passwordStrengthMeter({
			containerElement: '#pswmeter',
			passwordInput: '#psw-input',
			showMessage: true,
			messageContainer: '#pswmeter-message',
			messagesList: ['Password Strength', 'Very Weak!', 'Weak', 'Medium', 'Strong'],
			height: 6,
			borderRadius: 0,
			pswMinLength: 8,
			colorScore1: '#DA0D34',
			colorScore2: '#FA6400',
			colorScore3: '#0475E2',
			colorScore4: '#00B6B9',
		});
	}, [loadScriptPassword]);

	return (
		<Formik
			initialValues={authFormResetPasswordInitialValues}
			validationSchema={authFormResetPasswwordSchema}
			onSubmit={handleSubmit}
		>
			{(formikProps: FormikProps<ResetPasswordFormValue>) => (
				<>
					<ResetPasswordComponent
						{...formikProps}
						serverError={serverError}
						loader={loader}
						showPassword1={showPassword1}
						showPassword2={showPassword2}
						togglePassword1={() => setShowPassword1(!showPassword1)}
						togglePassword2={() => setShowPassword2(!showPassword2)}
					/>
				</>
			)}
		</Formik>
	);
};

export default ResetPassword;
