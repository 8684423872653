/* eslint-disable */
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { gold } from '../../modules';

export const WalletContext = createContext({
	wallet: null as any,
	isLoading: false,
	clearWallet: () => {},
	setIsAuth: (bool: boolean) => {},
});

type WalletProviderProps = {
	children: React.ReactNode;
};

const WalletProvider = (props: WalletProviderProps) => {
	const [wallet, setWallet] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [, setFailed] = useState(false);
	const [isAuth, setIsAuth] = useState(!!window.sessionStorage.getItem('auth'));
	const clearWallet = () => setWallet(null);
	// console.log("MOUNTED WALLET PROVIDER");
	// console.log(isAuth);
	// console.log(wallet, isLoading, failed);

	useEffect(() => {
		const getWallet = async () => {
			try {
				setIsLoading(true);
				const res = await gold.services.wallet.getWalletData();
				setIsLoading(false);

				if (res && res.success && res.payload) {
					setWallet(res.payload);
					// console.log(res.payload);
					return;
				}
				setFailed(true);
				throw new Error();
			} catch (error) {
				window.location.href = '/general-error';
			}
		};
		// console.log("effected:: WALLET PROVIDER");
		// console.log(isAuth);
		// console.log(wallet, isLoading, failed);

		if (isAuth && !isLoading && !wallet && window.location.pathname !== '/general-error') {
			getWallet();
		}
	}, [wallet, isAuth]);

	const memoizedOutput = useMemo(
		() => ({
			wallet,
			isLoading,
			clearWallet,
			setIsAuth,
		}),
		[wallet, isLoading],
	);

	return <WalletContext.Provider value={memoizedOutput}>{props.children}</WalletContext.Provider>;
};

export default WalletProvider;
