import React from 'react';

import { formatUnix } from '@shared/helpers/format';

export type TransferHistoryEntry = {
	date: number;
	from: string;
	to: string;
	amount: number;
};
interface TransferHistoryProps {
	history: TransferHistoryEntry[];
}

const TransferHistory: React.FC<TransferHistoryProps> = ({ history }) => {
	const [onFilter, setOnFilter] = React.useState(history);
	const [errorOnFilter, setErrorOnFilter] = React.useState<string>('');

	const handleSetFilter = () => {
		const filterValue: string = (document.getElementById('filterValue') as HTMLInputElement).value ?? '';
		if (!filterValue) {
			return setErrorOnFilter('Please verify the information');
		}
		setErrorOnFilter('');
		const historyFilter = history.filter(
			(transaction) =>
				transaction.from === filterValue.toLowerCase().trim() || transaction.to === filterValue.toLowerCase().trim(),
		);
		setOnFilter(historyFilter);
	};

	const handleClearFilter = () => {
		setErrorOnFilter('');
		setOnFilter(history);
		const filterValue = document.getElementById('filterValue') as HTMLInputElement;
		filterValue.value = '';
	};

	return (
		<li>
			<div className="uk-grid-small mc-user-fields" uk-grid="true">
				<div className="uk-width-1-1">
					<legend className="uk-text-content uk-text-bluelight uk-heading-divider uk-text-uppercase uk-margin-bottom">
						<strong>Transfers History</strong>
					</legend>
					<p className="uk-text-bluelight">Search by email:</p>
					<div className="uk-flex uk-margin-bottom">
						<div className="uk-width-expand uk-margin-small-right">
							<div className="uk-form-controls uk-inline">
								<input type="text" id="filterValue" className="uk-input" style={{ height: '50px' }} />
							</div>
						</div>
						<div className="uk-button-group">
							<button type="button" className="uk-button uk-button-secondary uk-margin-small-right" onClick={handleSetFilter}>
								Search
							</button>
							<button type="button" className="uk-button uk-button-secondary" onClick={handleClearFilter}>
								Clear
							</button>
						</div>
					</div>
					{errorOnFilter ? (
						<div className="errorMessage">
							<p className="uk-text-danger">{errorOnFilter}</p>
						</div>
					) : null}
					<table className="mc-transfers uk-table uk-table-striped uk-table-middle uk-table-responsive">
						<thead>
							<tr>
								<th className="uk-text-left">Date</th>
								<th className="uk-text-left uk-table-expand">From</th>
								<th className="uk-text-left uk-table-expand">To</th>
								<th className="uk-text-center">Quantity</th>
							</tr>
						</thead>
						<tbody>
							{onFilter.length > 0 ? (
								onFilter.map((transaction: TransferHistoryEntry, index: number) => {
									return (
										<tr key={index}>
											<td className="date">{formatUnix(transaction.date)}</td>
											<td className="user uk-text-truncate">{transaction.from}</td>
											<td className="user uk-text-truncate">{transaction.to}</td>
											<td className="mitacoins">
												{transaction.amount} <img src="/images/mc-crown.png" width="32" alt="" />
											</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td>-</td>
									<td className="user">No data found</td>
									<td className="user">No data found</td>
									<td>-</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</li>
	);
};

export default TransferHistory;
