const encryptEmail = (email: string) => {
	const emailSplit = email.split('@');
	const hideEmail = emailSplit[0];
	let finishemail = '';

	for (let index = 1; index < hideEmail.length; index++) {
		const data = hideEmail.slice(0, index).replace(/./g, '*') + hideEmail[hideEmail.length - 1] + '@' + emailSplit[1];
		finishemail = data;
	}
	return finishemail;
};

export { encryptEmail };
