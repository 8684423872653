import moment from 'moment';

export const formatDate = (value: string | number | Date, format = 'MMM DD/YY'): string => {
	return moment(value).format(format);
};

const ecua = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const formatNumUSD = (number: number) => ecua.format(number);

export const formatUnix = (value: number, format = 'MMM DD/YY'): string => {
	return moment.unix(value).format(format);
};

export const firstLetterToUpperCase = (string: string) => {
	return string[0].toUpperCase() + string.slice(1);
};

export const extractNumberOfDecimals = (value: number, numDecimals: number) => {
	const valueToString = value.toString();
	const valueSplit = valueToString.split('.');
	if (valueSplit.length !== 2) return value;
	const [integerValue, decimalValue] = valueSplit;
	const firstTwoDecimalString = decimalValue.slice(0, numDecimals);
	return `${integerValue}.${firstTwoDecimalString}`;
};

export const convertCentsToDollar = (amount: number) => {
	const test = Number((amount / 100).toFixed(2));
	return formatNumUSD(test);
};

export const formatNumber = (value: string, fractions = 0, style = 'currency', useSymbol = true): string => {
	if (value === '' || value === undefined) {
		return value;
	}
	if (!(style in { decimal: true, currency: true, percent: true })) {
		return value.toString();
	}
	const safeValue = value.replace ? Number(value.replace(/[^0-9-.]+/, '')) : value;

	if (useSymbol) {
		return new Intl.NumberFormat('en-US', {
			style,
			currency: 'USD',
			minimumFractionDigits: fractions,
		}).format(safeValue as number);
	} else {
		return new Intl.NumberFormat('en-US', {
			style,
			currency: 'USD',
			currencyDisplay: 'code',
			minimumFractionDigits: fractions,
		})
			.format(safeValue as number)
			.replace('USD', '')
			.trim();
	}
};

export const formatNumberCOP = (value: string, fractions = 0, style = 'currency', useSymbol = true): string => {
	if (value === '' || value === undefined) {
		return value;
	}
	if (!(style in { decimal: true, currency: true, percent: true })) {
		return value.toString();
	}
	const safeValue = value.replace ? Number(value.replace(/[^0-9-.]+/, '')) : value;
	if (useSymbol) {
		return new Intl.NumberFormat('es-CO', {
			style,
			currency: 'COP',
			minimumFractionDigits: fractions,
		}).format(safeValue as number);
	} else {
		return new Intl.NumberFormat('es-CO', {
			style,
			currency: 'COP',
			currencyDisplay: 'code',
			minimumFractionDigits: fractions,
		})
			.format(safeValue as number)
			.replace('COP', '')
			.trim();
	}
};

export const objectValuesToUpperCaseAndNormalize = (obj: Record<string, string | number | boolean | object> | object) => {
	const newObj: any = { ...obj };
	Object.keys(newObj).forEach((key: string) => {
		const value: string | number | boolean | object = newObj[key];
		if (typeof value === 'string') {
			newObj[key] = value
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toUpperCase()
				.trim();
		}
		if (typeof value === 'object') {
			const normalizeObject = objectValuesToUpperCaseAndNormalize(value);
			newObj[key] = normalizeObject;
		}
	});
	return newObj;
};
