import React from 'react';

import { useUserInfoContext } from '@context/hooks';
import { PersonalInfo as PersonalInfoComponent } from '@user/components';

const PersonalInfo: React.FC = () => {
	const { userInfo, setUserInfo } = useUserInfoContext();
	return <PersonalInfoComponent userInfo={userInfo} setUserInfo={setUserInfo} />;
};

export default PersonalInfo;
