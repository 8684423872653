import React, { useEffect } from 'react';

const Landing: React.FC = () => {
	document.title = 'Your Market - Mitacoin';
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
		script.async = true;
		script.innerHTML = JSON.stringify({
			colorTheme: 'light',
			dateRange: '12M',
			showChart: true,
			locale: 'en',
			width: '100%',
			height: '100%',
			largeChartUrl: '',
			isTransparent: false,
			showSymbolLogo: false,
			plotLineColorGrowing: 'rgba(255, 217, 102, 1)',
			plotLineColorFalling: 'rgba(250, 100, 0, 1)',
			gridLineColor: 'rgba(118, 135, 156, 1)',
			scaleFontColor: 'rgba(91, 98, 107, 1)',
			belowLineFillColorGrowing: 'rgba(254, 236, 132, 0.5)',
			belowLineFillColorFalling: 'rgba(250, 100, 0, 0.15)',
			symbolActiveColor: 'rgba(196, 205, 217,0.5)',
			tabs: [
				{
					title: 'Indices',
					symbols: [
						{
							s: 'FX_IDC:XAUUSDG',
							d: 'GOLD GRAM USD',
						},
						{
							s: 'TVC:GOLD',
							d: 'GOLD OZ USD',
						},
					],
					originalTitle: 'Indices',
				},
			],
		});
		const tradingContainer: HTMLElement | null = document.getElementById('tradingview_0703b');
		if (tradingContainer) tradingContainer.appendChild(script);
	}, []);
	return null;
};

export default Landing;
