import React from 'react';

import { MyAccount as MyAccountContainer, PersonalInfo } from '@user/containers';

const MyAccount: React.FC = () => {
	return (
		<>
			<MyAccountContainer />
			<PersonalInfo />
		</>
	);
};

export default MyAccount;
