/* eslint-disable */
import './CurrencyToggle.css';

import React from 'react';

type CurrencyToggleProps = {
	currencies: string[];
	handleToggle: (currency: string) => void;
	selectedCurrency: string;
};

const CurrencyToggle: React.FC<CurrencyToggleProps> = ({ currencies, selectedCurrency, handleToggle }) => {
	return (
		<div className="mc-currency-container">
			<div className="mc-currency uk-flex uk-flex-middle uk-flex-right">
				<small className="uk-text-xsmall uk-text-right">Shop currency</small>
				<ul
					className="uk-subnav uk-subnav-pill"
					uk-switcher="connect: .mc-price-rate; swiping: false; animation: uk-animation-fade"
				>
					{currencies.map((currency) => (
						<li className={currency === selectedCurrency ? 'uk-active' : ''} key={currency} translate="no">
							<a onClick={() => handleToggle(currency)}>{currency.toUpperCase()}</a>
						</li>
					))}
				</ul>
			</div>{' '}
			<ul className="mc-price-rate uk-switcher">
				{currencies.map((currency) => (
					<li key={currency}>
						{currency === 'cop' ? (
							<small>
								<span className="uk-icon uk-icon-image"></span> Approx. exchange rate.
								{/* <strong>1 USD = $3,873.60 COP</strong> */}
							</small>
						) : null}
					</li>
				))}
			</ul>
		</div>
	);
};

export default CurrencyToggle;
