import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { hooks } from '@context/index';
import { ValidateCodeTransaction as ValidateCodeComponent } from '@transfers/components';
import { confirmedTransferMitacoins, sendANewCode } from '@transfers/services';
import { ICouponData } from '@transfers/utils';

interface ValidateCodeTransactionProps {
	currentUserEmail: string;
	confirmedData: ICouponData | undefined;
}

const ValidateCodeTransaction: React.FC<ValidateCodeTransactionProps> = ({ confirmedData, currentUserEmail }) => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [loaderCode, setLoaderCode] = useState(false);
	const { clearWallet } = hooks.useWalletContext();
	const { setModalTransferOpen } = hooks.useModalTransferContext();

	const handleSubmit = async (code: number) => {
		if (confirmedData) {
			const data = Object.assign(confirmedData, {
				confirmedTransferCodeMitacoins: code,
			});
			setIsLoading(true);
			setServerError('');
			const response = await confirmedTransferMitacoins(data);
			setIsLoading(false);
			if (response.success) {
				setModalTransferOpen(false);
				history.push('/success-transaction', { confirmedData });
				return clearWallet();
			}
			return setServerError(response.message ?? 'Something went wrong');
		}
	};

	const handleSendANewCode = async () => {
		setServerError('');
		setLoaderCode(true);
		const response = await sendANewCode();
		setLoaderCode(false);
		if (response && response.success) {
			toast.success('New code sent');
			return;
		}
		return setServerError(response.message ?? 'Error trying to send a new code');
	};

	return (
		<ValidateCodeComponent
			serverError={serverError}
			isLoading={isLoading}
			sendANewCode={handleSendANewCode}
			loaderCode={loaderCode}
			handleSubmit={handleSubmit}
			currentUserEmail={currentUserEmail}
		/>
	);
};

export default ValidateCodeTransaction;
