import './InviteModal.css';

import React from 'react';
import { ErrorMessage, Field, FormikProps } from 'formik';

import { encryptEmail } from '@auth/helpers';
import { InviteFriendFormValues } from '@transfers/utils';

type InviteModalProps = FormikProps<InviteFriendFormValues> & {
	friendEmail: string | undefined;
	loader: boolean;
	serverError: string;
};

const InviteModal: React.FC<InviteModalProps> = ({
	friendEmail,
	loader,
	serverError,
	handleChange,
	handleSubmit,
	touched,
	errors,
	handleReset,
}) => {
	return (
		<>
			{/* INVITE MODAL  */}
			<div id="mc-invite" className="uk-flex-top" uk-modal="true">
				<div className="uk-modal-dialog uk-margin-auto-vertical">
					<button className="uk-modal-close-default" type="button" uk-close="true" onClick={handleReset}></button>
					<div className="uk-modal-header uk-text-center">
						<h2 className="uk-modal-title">Invite Friends</h2>
					</div>
					<form className="uk-form-horizontal mc-invite-form" onSubmit={handleSubmit}>
						<div className="uk-modal-body">
							<p className="uk-text-meta uk-text-graydarkx uk-text-bold uk-text-center uk-margin-remove-top">
								Invite your friends to sign in and accept Mitacoin transfers
							</p>
							<div className="mc-invite-fields">
								<div className="uk-margin">
									<label className="uk-form-label">Friend Name</label>
									<div className="uk-form-controls">
										<div className="uk-inline uk-width-1-1">
											<span className="uk-form-icon uk-form-icon-flip">
												<img src="/images/icons/ico-friend.svg" alt="" />
											</span>
											<Field className="uk-input" type="text" placeholder="" name="friendName" onChange={handleChange} />
										</div>
										<div className="mc-form-validation uk-hidden">
											<img src="/images/icons/ico-formerror.svg" alt="" /> Information required
										</div>
										{errors.friendName && touched.friendName && (
											<ErrorMessage name="friendName" component="small" className="errorMessage" />
										)}
									</div>
								</div>
								<div className="uk-margin">
									<label className="uk-form-label">Friend email account</label>
									<div className="uk-form-controls">
										<div className="uk-inline uk-width-1-1">
											<span className="uk-form-icon uk-form-icon-flip">
												<img src="/images/icons/ico-email.svg" alt="" />
											</span>
											<Field className="uk-input" type="email" placeholder="" name="friendEmail" onChange={handleChange} />
										</div>
										<div className="mc-form-validation uk-hidden">
											<img src="/images/icons/ico-formerror.svg" alt="" /> Information required
										</div>
										{errors.friendEmail && touched.friendEmail && (
											<ErrorMessage name="friendEmail" component="small" className="errorMessage" />
										)}
									</div>
								</div>
								{serverError ? (
									<div className="uk-margin">
										<div className="uk-alert-warning" uk-alert="true">
											<button type="button" className="uk-alert-close" uk-close="true"></button>
											<p>
												{serverError}.
												{serverError === 'Email could not be sent' ? (
													<button type="submit" className="uk-button uk-button-text uk-text-danger">
														TRY AGAIN
													</button>
												) : null}
											</p>
										</div>
									</div>
								) : null}
							</div>
						</div>

						<div className="uk-modal-footer uk-text-center">
							<button className="uk-button uk-button-default uk-modal-close" onClick={handleReset}>
								Cancel
							</button>
							<button
								type="submit"
								disabled={loader}
								className={`uk-button uk-button-primary uk-margin-small-left ${loader && 'loading'}`}
							>
								Send Invitation{' '}
							</button>
							<button hidden type="button" id="btnInviteFriend" uk-toggle="target: #mc-invite-success" />
						</div>
					</form>
				</div>
			</div>

			{/* TRANSFERS MODAL STEP3 */}
			<div id="mc-invite-success" className="uk-flex-top" uk-modal="true">
				<div className="uk-modal-dialog uk-margin-auto-vertical">
					<button className="uk-modal-close-default" type="button" uk-close="true"></button>
					<div className="uk-modal-header uk-text-center">
						<h2 className="uk-modal-title">Invitation sent</h2>
					</div>
					<form className="uk-form-stacked mc-success-form">
						<div className="uk-modal-body">
							<p className="uk-text-content uk-text-bluedark uk-text-center uk-text-bolder uk-margin">
								We sent an email to <br />
								<span className="uk-text-bluelight uk-text-intro uk-text-bold">
									{friendEmail ? encryptEmail(friendEmail) : ''}
								</span>
								<br />
								with an invitation to create an account in Mitacoin. Good luck!
							</p>
							<div className="uk-text-center uk-margin-small">
								<button className="uk-button uk-button-text uk-modal-close">Close</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default InviteModal;
