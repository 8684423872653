import { handleError } from '@error/helpers';
import * as shared from '@shared/index';
import { MitacoinServiceResponse } from '@shared/utils';

import config from '../../../config';

const {
	services: { mitacoinApi },
} = shared;

export const getUserInfo = async (): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.get(config.api.paths.getUserInfo);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const updatePersonalInfo = async ({
	email,
	password,
	firstName,
	lastName,
	secondLastName,
	phone,
}: {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	secondLastName: string;
	phone: object;
}): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.updateUserPersonalInfo, {
			email,
			password,
			firstName,
			lastName,
			secondLastName,
			phone,
		});
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const updateBillingInfo = async ({
	address,
	city,
	country,
	state,
}: {
	address: string;
	city: string;
	country: string;
	state: string;
}): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.updateUserBillingInfo, { address, city, country, state });
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const updatePassword = async (password: string): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.updatePassword, {
			password,
		});
		if (!response) {
			throw new Error('missing response');
		}
		if (!response.data) {
			throw new Error('missing response');
		}
		return { ...response.data };
	} catch (error) {
		return handleError(error);
	}
};
