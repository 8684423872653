import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import { handleError } from '@error/helpers';

import config from '../../../config';
import * as format from './format';
import * as urls from './urls';

export { format, urls };

export const setAuthTokens = ({ token, refreshToken, expiresIn }: { token: string; refreshToken: string; expiresIn: number }) => {
	sessionStorage.setItem(
		'auth',
		JSON.stringify({
			token,
			refreshToken,
			expiresIn: moment().add(expiresIn, 's').subtract(60, 's').toDate(),
		}),
	);
};

export const clearAuthTokens = () => {
	window.sessionStorage.removeItem('auth');
};

export const refreshToken = async (_refreshToken: string) => {
	try {
		const res: AxiosResponse = await axios.post(config.api.baseUrl + config.api.paths.refresh, { refreshToken: _refreshToken });
		return setAuthTokens(res.data.payload);
	} catch (error) {
		clearAuthTokens();
		return handleError(error);
	}
};
