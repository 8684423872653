import './Transfer.css';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';

import { hooks } from '@context/index';
import { goldPrice } from '@gold/services';
import { CurrencyToggle } from '@shared/components';
import { CURRENCIES, DEFAULT_CURRENCY } from '@shared/constants';
import { Loading } from '@shared/screens';
import { IDataGold } from '@shared/utils';
import { Transfer as TransferComponent } from '@transfers/components';
import { mitacoinToPrice } from '@transfers/helpers';
import { transferMitacoins } from '@transfers/services';
import {
	ICouponData,
	TransferMitacoinsFormValues,
	TransferMitacoinsFormValuesInitialValues,
	TransferMitacoinsFormValuesSchema,
} from '@transfers/utils';
import { getUserInfo } from '@user/services';

const Transfer: React.FC = () => {
	const [confirmedData, setConfirmedData] = useState<ICouponData>();
	const [loader, setLoader] = useState(false);
	const [serverError, setServerError] = useState('');

	const [currentUserEmail, setCurrentUserEmail] = useState('');
	const [dataUserLoader, setDataUserLoader] = useState(false);
	const [userDataError, setUserDataError] = useState('');

	const [dataGold, setDataGold] = useState<IDataGold>();
	const [dataGoldLoading, setDataGoldLoading] = useState<boolean>(false);
	const [dataGoldError, setDataGoldError] = useState<string>('');

	const [inputAmount, setInputAmount] = useState<string>('');
	const [total, setTotal] = useState<string>('0');
	const [selectedCurrency, setSelectedCurrency] = useState<string>(DEFAULT_CURRENCY);

	const { wallet } = hooks.useWalletContext();
	const { setModalTransferOpen } = hooks.useModalTransferContext();

	useEffect(() => {
		const getGoldPrice = async () => {
			setDataGoldLoading(true);
			const responseDataGold = await goldPrice.getGoldPrice();
			setDataGoldLoading(false);
			if (responseDataGold.success && responseDataGold.payload) {
				return setDataGold(responseDataGold.payload);
			}
			return setDataGoldError(responseDataGold.message ?? 'Please try again');
		};
		if (!dataGold && !dataGoldLoading) {
			getGoldPrice();
		}
	}, [dataGold, dataGoldLoading]);

	useEffect(() => {
		const getDataUser = async () => {
			setDataUserLoader(true);
			const response = await getUserInfo();
			setDataUserLoader(false);
			if (response.success && response.payload) {
				return setCurrentUserEmail(response.payload.email);
			}
			return setUserDataError(response.message ?? 'Please try again');
		};

		if (!currentUserEmail && !dataUserLoader) {
			getDataUser();
		}
	}, [currentUserEmail, dataUserLoader]);

	useEffect(() => {
		const price = selectedCurrency === 'usd' ? dataGold?.price : dataGold?.priceCOP;
		const mitacoinAmount = mitacoinToPrice(Number(inputAmount), price ?? 0, selectedCurrency);
		setTotal(mitacoinAmount);
	}, [inputAmount, selectedCurrency, dataGold]);

	const handleCurrencyToggle = (currency: string) => {
		setSelectedCurrency(currency);
	};

	const handleSubmit = async (values: TransferMitacoinsFormValues) => {
		setServerError('');
		values.amount = Number(inputAmount);
		setLoader(true);
		if (values.amount <= 0) {
			setLoader(false);
			return setServerError('Enter a valid amount of Mitacoins');
		} else if (values.amount > wallet.currentMitacoinAmount) {
			setLoader(false);
			return setServerError(`You can't transfer more than ${wallet.currentMitacoinAmount} mitacoins`);
		}
		const response = await transferMitacoins({ ...values });

		setLoader(false);
		if (response.success) {
			setConfirmedData({ ...values });
			setModalTransferOpen(true);
		}
		return setServerError(response.message ?? 'Something went wrong');
	};

	return (
		<section className="uk-section uk-section-xsmall">
			<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
				<div className="uk-background-gold mc-breadcrumb">
					<div className="uk-container">
						<ul className="uk-breadcrumb uk-flex-center">
							<li>
								<Link to="/market">Mitacoin</Link>
							</li>
							<li>
								<span>Transfer Mitacoins</span>
							</li>
						</ul>
					</div>
				</div>
				<CurrencyToggle currencies={CURRENCIES} handleToggle={handleCurrencyToggle} selectedCurrency={selectedCurrency} />
				<div className="uk-card mc-transfer uk-width-xlarge uk-margin-auto uk-margin-medium-top">
					<Formik
						initialValues={TransferMitacoinsFormValuesInitialValues}
						validationSchema={TransferMitacoinsFormValuesSchema}
						onSubmit={handleSubmit}
					>
						{(formikProps: FormikProps<TransferMitacoinsFormValues>) => {
							return (
								<>
									{wallet && currentUserEmail && dataGold ? (
										<TransferComponent
											{...formikProps}
											confirmedData={confirmedData}
											loader={loader}
											serverError={serverError}
											currentUserEmail={currentUserEmail}
											priceUSD={dataGold.price}
											priceCOP={dataGold.priceCOP}
											priceUSDtoCOP={dataGold.priceUSDtoCOP}
											userMitacoinAmount={wallet.currentMitacoinAmount}
											total={total}
											selectedCurrency={selectedCurrency}
											inputAmount={inputAmount}
											setInputAmount={setInputAmount}
										/>
									) : (
										<Loading />
									)}
									{userDataError ? (
										<div className="uk-margin">
											<div className="uk-alert-warning" uk-alert="true">
												<button type="button" className="uk-alert-close" uk-close="true"></button>
												<p>{userDataError}</p>
											</div>
										</div>
									) : null}
									{dataGoldError ? (
										<div className="uk-margin">
											<div className="uk-alert-warning" uk-alert="true">
												<button type="button" className="uk-alert-close" uk-close="true"></button>
												<p>{dataGoldError}</p>
											</div>
										</div>
									) : null}
								</>
							);
						}}
					</Formik>
				</div>
			</div>
		</section>
	);
};
export default Transfer;
