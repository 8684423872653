import { handleError } from '@error/helpers';
import * as shared from '@shared/index';
import { MitacoinServiceResponse } from '@shared/utils';

import config from '../../../config';

const {
	services: { mitacoinApi },
} = shared;

export const getTransferHistory = async (): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.get(config.api.paths.transferHistory);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const transferMitacoins = async ({
	email,
	amount,
}: {
	email: string;
	amount: number;
}): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.transferMitacoins, { email, amount });
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const confirmedTransferMitacoins = async ({
	email,
	amount,
	confirmedTransferCodeMitacoins,
}: {
	email: string;
	amount: number;
	confirmedTransferCodeMitacoins: number;
}): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.confirmedTransferMitacoins, {
			email,
			amount,
			confirmedTransferCodeMitacoins,
		});
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};
export const inviteFriend = async ({
	friendName,
	friendEmail,
}: {
	friendName: string;
	friendEmail: string;
}): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.post(config.api.paths.inviteFriend, {
			friendName,
			friendEmail,
		});
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};

export const sendANewCode = async (): Promise<MitacoinServiceResponse> => {
	try {
		const response = await mitacoinApi.get(config.api.paths.sendANewCode);
		if (response && response.data) {
			return { ...response.data };
		}
		throw new Error('missing response');
	} catch (error) {
		return handleError(error);
	}
};
