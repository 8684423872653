/* eslint-disable */
import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';

import config from '../../../../config';

type InputAddressProps = InputHTMLAttributes<HTMLInputElement>;

const apiKey = config.apiKeyGoogleAddress;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

function loadAsyncScript(src: string) {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		Object.assign(script, {
			type: 'text/javascript',
			async: true,
			src,
		});
		script.addEventListener('load', () => resolve(script), { once: true });
		document.head.appendChild(script);
	});
}

const InputAddress: React.FC<InputAddressProps> = ({ ...props }) => {
	const [field, meta] = useField({ name: props.name ?? '' });
	const searchInput = document.getElementById('input-address') as HTMLInputElement;

	const initMapScript = () => {
		if (window.google) {
			return Promise.resolve();
		}
		const src = `${mapApiJs}?key=${apiKey}&libraries=places`;
		return loadAsyncScript(src);
	};

	const initAutocomplete = () => {
		if (!searchInput) return;

		const options = {
			fields: ['formatted_address'],
			types: ['address'],
		};

		const autocomplete = new window.google.maps.places.Autocomplete(searchInput, options);
		autocomplete.addListener('place_changed', () => handleChangeAddress(autocomplete));
	};

	// eslint-disable-next-line no-undef
	const handleChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
		const addressValue = autocomplete.getPlace();
		field.onChange(field.name)(addressValue.formatted_address || '');
	};

	React.useEffect(() => {
		initMapScript().then(() => initAutocomplete());
	}, [searchInput]);

	return <input id="input-address" type="text" onChange={field.onChange} defaultValue={meta.initialValue ?? ''} {...props} />;
};

export default InputAddress;
