import * as Yup from 'yup';

import * as sharedConstants from '@shared/constants';

// User Personal Data
export type IUserDataPersonal = {
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	secondLastName: string;
	phone: { number: number; iso2: string };
	referralCode: string;
};

export const checkoutFormPersonalSchema = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
	firstName: Yup.string()
		.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
		.required('First Name is required'),
	lastName: Yup.string()
		.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
		.required('Last Name is required'),
	secondLastName: Yup.string()
		.matches(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g, 'Please enter a valid value')
		.optional(),

	phone: Yup.object().shape({
		number: Yup.number().positive().integer().required('Phone is required'),
		iso2: Yup.string().required('Country of phone is required'),
	}),
});

// User Billing Data
export type IUserDataBilling = {
	address: string;
	addressComplement?: string;
	postalCode?: string;
	city: string;
	state: string;
	country: string;
};

export const createFormBillingSchema = (country: string | undefined, state: string | undefined) =>
	Yup.object().shape({
		address: Yup.string().required('Address is required').trim().max(sharedConstants.ADDRESS_MAX_LENGTH, 'Address is too long'),
		addressComplement: Yup.string().optional().trim().max(sharedConstants.ADDRESS_COMPLEMENT_MAX_LENGTH),
		postalCode: Yup.string().optional().trim().max(sharedConstants.POSTAL_CODE_MAX_LENGTH, 'Postal code is too long'),
		city: Yup.string().required('City is required').trim().max(sharedConstants.CITY_MAX_LENGTH),
		state: Yup.string().test('validate-state', 'state-error', function () {
			const { path, createError } = this;
			if (!state) {
				return createError({ path, message: 'Region is required' });
			}
			return true;
		}),
		country: Yup.string().test('validate-contry', 'country-error', function () {
			const { path, createError } = this;
			if (!country) {
				return createError({ path, message: 'Country is required' });
			}
			return true;
		}),
	});

// New Password

export type NewPasswordFormValues = {
	password: string;
	confirmPassword: string;
};

export const userNewPasswordInitialValues = {
	password: '',
	confirmPassword: '',
};

export const checkoutFormNewPasswordSchema = Yup.object().shape({
	password: Yup.string().required('Password is required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password')], 'Both password need to be the same')
		.required('Confirm password is required'),
});
