import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ErrorMessage, FormikProps } from 'formik';

import { InputAddress } from '@shared/components';
import { IUserDataBilling } from '@user/utils';

type FormBillingProps = FormikProps<IUserDataBilling> & {
	editDataProfile: boolean;
	toggleDataUser: () => void;
	isLoading: boolean;
	serverError: string;
	setCountry: (val: string) => void;
	setCity: (val: string) => void;
	country: string;
	state: string;
};

const FormBillingComponent: React.FC<FormBillingProps> = ({
	editDataProfile,
	toggleDataUser,
	isLoading,
	serverError,
	setCountry,
	setCity,
	country,
	state,
	handleChange,
	handleSubmit,
	errors,
	initialValues,
	touched,
	setFieldValue,
}) => {
	return (
		<form>
			<li>
				<legend className="uk-text-content uk-text-bluelight uk-heading-divider uk-text-uppercase uk-margin-bottom">
					<strong>Billing Information</strong>
				</legend>
				<div className="uk-grid-small mc-user-fields" uk-grid="true">
					<div className="uk-width-1-1">
						<label className="uk-form-label">Address</label>
						<div className="uk-form-controls">
							<InputAddress name="address" className="uk-input uk-form-blank" disabled={editDataProfile} />
						</div>
						{errors.address && touched.address && <ErrorMessage name="address" component="small" className="errorMessage" />}
					</div>

					<div className="uk-width-1-2@s">
						<label className="uk-form-label">Country</label>
						<div className="uk-form-controls">
							<CountryDropdown
								classes="uk-input uk-form-blank"
								value={country}
								labelType="full"
								valueType="full"
								name="country"
								onChange={(val: string) => setCountry(val)}
								disabled={editDataProfile}
							/>
						</div>
						{errors.country && touched.country ? <small className="errorMessage">{errors.country}</small> : null}
					</div>
					<div className="uk-width-1-2@s">
						<label className="uk-form-label">State</label>
						<div className="uk-form-controls">
							<RegionDropdown
								classes="uk-input uk-form-blank"
								country={country}
								value={state}
								countryValueType="full"
								onChange={(val: string) => setCity(val)}
								name="state"
								disabled={editDataProfile}
							/>
						</div>
						{errors.state && touched.state ? <small className="errorMessage">{errors.state}</small> : null}
					</div>
					<div className="uk-width-1-2@s">
						<label className="uk-form-label">City</label>
						<div className="uk-form-controls">
							<input
								className={`uk-input uk-form-blank ${errors.city && touched.city ? 'input-error' : null}`}
								type="text"
								name="city"
								defaultValue={initialValues.city}
								onChange={handleChange}
								disabled={editDataProfile}
							/>
						</div>
						{errors.city && touched.city && <ErrorMessage name="city" component="small" className="errorMessage" />}
					</div>

					<div className="uk-width-1-1 uk-text-left uk-margin-medium">
						{serverError ? (
							<>
								{serverError}
								<br />
								<br />
							</>
						) : null}
						{editDataProfile ? (
							<button type="button" className="uk-button uk-button-default" onClick={() => toggleDataUser()}>
								Edit Information
							</button>
						) : (
							<>
								{isLoading ? (
									<button type="button" className="uk-button uk-button-default loading">
										Update Information{' '}
									</button>
								) : (
									<button type="button" className="uk-button uk-button-default uk-margin-right" onClick={() => handleSubmit()}>
										Update Information
									</button>
								)}
							</>
						)}
					</div>
				</div>
			</li>
		</form>
	);
};

export default FormBillingComponent;
