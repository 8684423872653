import * as countries from 'i18n-iso-countries';

import * as goldConstants from '@gold/constants';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const shouldShowPostalCode = (country: string): boolean => {
	return goldConstants.countriesWithPostalCode.includes(country);
};

export const mapCountryNameToISOCode = (country: string): string => {
	return countries.getAlpha2Code(country, 'en');
};

export const mapCountryISOCodeToName = (country: string): string => {
	return countries.getName(country, 'en', { select: 'official' });
};
