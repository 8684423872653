// the COP price needs to reflect an increment of 1.02% in some
// cases
export const COP_INCREMENT = 0.0102;
// taken from paypal as mercadopago has no limits for these
export const ADDRESS_MAX_LENGTH = 300;
export const ADDRESS_COMPLEMENT_MAX_LENGTH = 300;
export const CITY_MAX_LENGTH = 120;
export const STATE_MAX_LENGTH = 300;
export const POSTAL_CODE_MAX_LENGTH = 60;
export const COUNTRY_CODE_MAX_LENGTH = 2;

export const CURRENCIES = ['usd', 'cop'];
export const DEFAULT_CURRENCY = 'usd';
