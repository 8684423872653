import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';

import { LoginComponent } from '@auth/components/index';
import { SignIn } from '@auth/services';
import { authFormLoginInitialValues, authFormLoginSchema, LoginFormValues } from '@auth/utils';
import { useWalletContext } from '@context/hooks';
import * as shared from '@shared/index';

const Login: React.FC = () => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');
	const [loader, setLoader] = useState(false);
	const { setIsAuth: setIsAuthOnWalletProvider } = useWalletContext();

	const handleSubmit = async (values: LoginFormValues) => {
		setLoader(true);
		setServerError('');
		const response = await SignIn({ ...values });
		setLoader(false);

		if (response && response.success && response.payload) {
			shared.helpers.setAuthTokens(response.payload);
			setIsAuthOnWalletProvider(true);
			return history.push('/market');
		}
		return setServerError(response.message ?? 'Login error');
	};

	useEffect(() => {
		document.title = 'Enter your Account - Mitacoin';
		const script = document.createElement('script');
		script.src = 'js/particles.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<Formik initialValues={authFormLoginInitialValues} validationSchema={authFormLoginSchema} onSubmit={handleSubmit}>
			{(formikProps: FormikProps<LoginFormValues>) => (
				<>
					<LoginComponent {...formikProps} serverError={serverError} loader={loader} />
				</>
			)}
		</Formik>
	);
};

export default Login;
