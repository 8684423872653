import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const EmailSent: React.FC = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		document.title = pathname === '/pass-email-sent' ? 'Password Sent - Mitacoin' : 'Confirm your acount - Mitacoin';
	}, [pathname]);
	return (
		<>
			<section className="uk-section uk-section-xsmall ">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<Link to="/">Mitacoin</Link>
								</li>
								<li>
									<span>{pathname === '/pass-email-sent' ? 'Password Recovery' : 'Confirm your account'}</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="uk-card mc-forms uk-width-xlarge uk-margin-auto uk-margin-medium-top uk-margin-large-bottom">
						<form className="uk-form-stacked uk-grid-medium" uk-grid="true" autoComplete="off">
							<div className="uk-width-1-1 uk-text-center">
								<legend className="uk-heading-small uk-heading-line uk-text-center uk-margin-small">
									<span>{pathname === '/pass-email-sent' ? 'Reset Account password' : 'Thanks for signing up'}</span>
								</legend>
								{pathname === '/pass-email-sent' ? (
									<p className="uk-text-subheading uk-text-graydarkxx uk-margin-small">Password request sent</p>
								) : null}
							</div>
							<div className="uk-width-1-1 uk-text-center uk-margin-large-bottom">
								<img src="/images/mitacoin-mail-sent.svg" alt="" />
								<p className="uk-text-content uk-text-danger uk-text-bolder uk-margin">
									{pathname === '/pass-email-sent'
										? 'If your email address exists in Mitacoin we will send a message with instructions to start the change password process.'
										: 'Check your inbox and click the link to confirm your account.'}
								</p>
								<div className="uk-margin-medium uk-text-center">
									<Link className="uk-button uk-button-default uk-button-small" to="/">
										Back to login
									</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default EmailSent;
