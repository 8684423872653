import { useContext } from 'react';

import { ModalContext } from '@context/providers/ModalProvider';

import { UserInfoContext, WalletContext } from '../providers';

export const useWalletContext = () => {
	const context = useContext(WalletContext);
	if (!context) {
		window.location.href = '/general-error';
	}
	return context;
};

export const useUserInfoContext = () => {
	const context = useContext(UserInfoContext);
	if (!context) {
		window.location.href = '/general-error';
	}
	return context;
};

export const useModalTransferContext = () => {
	const context = useContext(ModalContext);
	if (!context) {
		window.location.href = '/general-error';
	}
	return context.modalTransfer;
};
