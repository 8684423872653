import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { InviteModal as InviteModalComponent } from '@transfers/components';
import { inviteFriend } from '@transfers/services';
import {
	InviteFriendFormValues,
	InviteFriendFormValuesInitialValues,
	InviteFriendFormValuesInitialValuesSchema,
} from '@transfers/utils';

const InviteModal: React.FC = () => {
	const [friendEmail, setFriendEmail] = useState('');
	const [loader, setLoader] = useState(false);
	const [serverError, setServerError] = useState('');

	const handleSubmit = async (values: InviteFriendFormValues) => {
		setLoader(true);
		setServerError('');
		setFriendEmail(values.friendEmail);
		const response = await inviteFriend({ ...values });
		setLoader(false);

		if (response && response.success) {
			return (document.querySelector('#btnInviteFriend') as HTMLElement).click();
		}

		return setServerError(response.message ?? 'Something went wrong');
	};
	return (
		<Formik
			initialValues={InviteFriendFormValuesInitialValues}
			validationSchema={InviteFriendFormValuesInitialValuesSchema}
			onSubmit={handleSubmit}
		>
			{(formikProps: FormikProps<InviteFriendFormValues>) => (
				<InviteModalComponent {...formikProps} friendEmail={friendEmail} loader={loader} serverError={serverError} />
			)}
		</Formik>
	);
};

export default InviteModal;
