import './chart.css';

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { CurrencyToggle } from '@shared/components';
import { COP_INCREMENT } from '@shared/constants';
import { extractNumberOfDecimals, formatNumberCOP, formatNumUSD, formatUnix } from '@shared/helpers/format';
import { IDataGold, IDataWallet } from '@shared/utils';

interface WalletProps {
	currencies: string[];
	dataGold: IDataGold;
	dataWallet: IDataWallet;
	handleCurrencyToggle: (currency: string) => void;
	selectedCurrency: string;
}

const WalletComponent: React.FC<WalletProps> = ({ currencies, dataGold, dataWallet, handleCurrencyToggle, selectedCurrency }) => {
	const { pathname } = useLocation();
	const { priceCOP, price } = dataGold;
	const mitaCoinAmount = dataWallet.currentMitacoinAmount ?? 0;
	// reflect the increment for COP
	const balanceCOP = (1 + COP_INCREMENT) * priceCOP * mitaCoinAmount;
	const balanceUSD = price * mitaCoinAmount;

	useEffect(() => {
		const script = document.createElement('script');
		script.src = '/js/modal-whatIsAMitacoin.js';
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<>
			{pathname === '/market' ? (
				<>
					<div className="uk-width-1-1 uk-text-center uk-margin">
						<CurrencyToggle currencies={currencies} handleToggle={handleCurrencyToggle} selectedCurrency={selectedCurrency} />
						<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-small">
							<span>Market</span>
						</legend>
						<p className="uk-text-subheading uk-text-bluelight uk-margin-remove">Your gold investments</p>
					</div>

					{dataWallet.currentMitacoinAmount > 0 ? (
						<>
							<div className="uk-grid-collapse mc-market-container uk-flex-middle" uk-grid="true">
								<div className="uk-width-1-2@s uk-text-center mc-market-img">
									<img src="/images/mitacoin-market.png" alt="" />
								</div>
								<div className="uk-width-1-2@s uk-text-right mc-market-right">
									<div className="mc-market-help uk-text-right">
										<a href="/" className="uk-label uk-label-toggle" uk-toggle="target: #mitacoin-info">
											What's a Mitacoin?
										</a>
									</div>
									<div className="uk-padding-small mc-market-balance">
										<h4 className="uk-legend uk-text-graydark uk-margin-remove">Mitacoin Balance</h4>
										<div className="mc-balance uk-flex uk-flex-column">
											<span className="uk-text-large uk-text-bluelight uk-text-number">
												{' '}
												{dataWallet.currentMitacoinAmount ? extractNumberOfDecimals(dataWallet.currentMitacoinAmount, 5) : 0}{' '}
												<img src="/images/mc-crown-chip.png" alt="" width="45" uk-tooltip="title: Your Wallet" />
											</span>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">Mitacoins</small>
										</div>
										<hr className="uk-margin-small" />
										<div className="mc-balance uk-flex uk-flex-column">
											<span className="uk-text-lead uk-text-bluelight uk-text-number">
												{extractNumberOfDecimals(dataWallet.currentMitacoinAmount / 2, 5)}{' '}
												<img src="/images/icons/ico-gram.svg" alt="" uk-tooltip="title: Gold grams backed" />
											</span>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">
												Pure 999 Fine Gold
											</small>
										</div>
										<hr className="uk-margin-small" />
										<div className="mc-balance uk-flex uk-flex-column">
											<strong className="uk-text-lead uk-text-bluelight uk-text-number uk-text-bold">
												{selectedCurrency === 'usd'
													? `${formatNumUSD(balanceUSD)} USD`
													: `${formatNumberCOP(balanceCOP.toString(), 2)} COP`}
											</strong>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">
												Total balance today • {formatUnix(dataWallet.currentDate)}{' '}
											</small>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className="uk-grid-collapse mc-market-container uk-flex-middle" uk-grid="true">
							<div className="uk-width-1-2@s uk-text-center mc-market-img">
								<img src="/images/mitacoin-market.png" alt="" />
							</div>
							<div className="uk-width-1-2@s uk-text-right mc-market-right">
								<div className="mc-market-help uk-text-right">
									<a href="/" className="uk-label uk-label-toggle" uk-toggle="target: #mitacoin-info">
										What's a Mitacoin?
									</a>
								</div>
								<div className="uk-padding-small mc-market-balance">
									<div className="mc-balance uk-flex uk-flex-column">
										<strong className="uk-text-content uk-text-danger uk-margin-small">You don't have any Mitacoins!</strong>
										<span className="uk-text-large uk-text-bluelight uk-text-number">
											<del>0.00</del> <img src="/images/mc-crown-chip.png" alt="" width="35" uk-tooltip="title: Your Wallet" />
										</span>
										<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">Mitacoins</small>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			) : pathname === '/my-account' ? (
				<>
					<CurrencyToggle currencies={currencies} handleToggle={handleCurrencyToggle} selectedCurrency={selectedCurrency} />

					<div className="uk-width-1-1 uk-text-center uk-margin">
						<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-small">
							<span>Wallet</span>
						</legend>
						<p className="uk-text-subheading uk-text-bluelight uk-margin-remove">Your Mitacoins</p>
					</div>
					{dataWallet.currentMitacoinAmount > 0 ? (
						<>
							<div className="uk-grid-collapse mc-user-wallet" uk-grid="true">
								<div className="uk-width-1-2@s uk-text-center">
									<img src="/images/mitacoin-wallet.png" alt="" />
								</div>
								<div className="uk-width-1-2@s uk-text-right">
									<div className="mc-market-help">
										<a href="/" className="uk-label uk-label-toggle" uk-toggle="target: #mitacoin-info">
											What's a Mitacoin?
										</a>
									</div>
									<div className="uk-padding-small mc-wallet-balance">
										<h4 className="uk-legend uk-text-graydark uk-margin-remove">Mitacoin Balance</h4>
										<div className="mc-balance uk-flex uk-flex-column">
											<span className="uk-text-large uk-text-bluelight uk-text-number">
												{' '}
												{dataWallet.currentMitacoinAmount ? extractNumberOfDecimals(dataWallet.currentMitacoinAmount, 5) : 0}{' '}
												<img src="/images/mc-crown-chip.png" alt="" width="45" uk-tooltip="title: Your Wallet" />
											</span>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">Mitacoins</small>
										</div>
										<hr className="uk-margin-small" />
										<div className="mc-balance uk-flex uk-flex-column">
											<span className="uk-text-lead uk-text-bluelight uk-text-number">
												{extractNumberOfDecimals(dataWallet.currentMitacoinAmount / 2, 5)}{' '}
												<img src="/images/icons/ico-gram.svg" alt="" uk-tooltip="title: Gold grams backed" />
											</span>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">
												Pure 999 Fine Gold
											</small>
										</div>
										<hr className="uk-margin-small" />
										<div className="mc-balance uk-flex uk-flex-column">
											<strong className="uk-text-lead uk-text-bluelight uk-text-number uk-text-bold">
												{selectedCurrency === 'usd'
													? `${formatNumUSD(dataWallet.currentInvestmentValue)} USD`
													: `${formatNumberCOP(balanceCOP.toString(), 2)} COP`}
											</strong>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">
												Total balance today • {formatUnix(dataWallet.currentDate)}{' '}
											</small>
										</div>
									</div>
								</div>
							</div>
							<div className="uk-text-center uk-margin-medium-bottom">
								<hr className="uk-margin-medium-bottom uk-divider-light" />
								<Link className="uk-button uk-button-primary" to="/shop">
									<img src="/images/mc-crown.png" width="32" alt="" /> Buy More Mitacoins
								</Link>
							</div>
						</>
					) : (
						<>
							<div className="uk-grid-collapse mc-user-wallet uk-flex-middle" uk-grid="true">
								<div className="uk-width-1-2@s uk-text-center">
									<img src="/images/mitacoin-wallet-empty.png" alt="" />
								</div>
								<div className="uk-width-1-2@s uk-text-right">
									<div className="mc-market-help uk-text-right">
										<a href="/" className="uk-label uk-label-toggle" uk-toggle="target: #mitacoin-info">
											What's a Mitacoin?
										</a>
									</div>
									<div className="uk-padding-small mc-wallet-balance">
										<div className="mc-balance uk-flex uk-flex-column">
											<strong className="uk-text-content uk-text-danger uk-margin-small">You don't have any Mitacoins!</strong>
											<span className="uk-text-large uk-text-bluelight uk-text-number">
												<del>0.00</del> <img src="/images/mc-crown-chip.png" alt="" width="35" uk-tooltip="title: Your Wallet" />
											</span>
											<small className="uk-text-xsmall uk-text-graydarkx uk-text-uppercase uk-text-bold">Mitacoins</small>
										</div>
									</div>
								</div>
							</div>
							<div className="uk-text-center uk-margin-medium-bottom">
								<hr className="uk-margin-medium-bottom uk-divider-light" />
								<Link className="uk-button uk-button-primary" to="/shop">
									<img src="/images/mc-crown.png" width="32" alt="" /> Buy More Mitacoins
								</Link>
							</div>
						</>
					)}
				</>
			) : null}

			{/* Modal what is a mitacoin */}
			<div id="mitacoin-info" uk-modal="true">
				<div className="uk-modal-dialog">
					<button className="uk-modal-close-default" type="button" uk-close="true"></button>
					<div className="uk-modal-header">
						<h4 className="uk-modal-title uk-text-center">MITACOIN Inside</h4>
					</div>
					<div className="uk-modal-body">
						<p className="uk-text-bolder uk-text-graydarkxx uk-text-content">
							Every MITACOIN you purchase is backed by a pure 999 half gram of Fine Gold. Taxes and fees are also included:
						</p>
						<div className="mc-inside">
							<div className="uk-width-large uk-margin-auto">
								<img src="/images/mitacoin-piechart-pie.png" alt="" />
							</div>
							<div className="uk-width-medium uk-margin-auto">
								<img src="/images/mitacoin-piechart-legends.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WalletComponent;
