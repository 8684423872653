import React, { useEffect, useState } from 'react';

import { GoldSpotPrice } from '@gold/components';
import { goldPrice } from '@gold/services';

const Gold: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [dataGold, setDataGold] = useState();

	const containerTradingview = document.getElementById('trading_gold');

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await goldPrice.getGoldPrice();
			setIsLoading(false);
			if (res && res.payload) {
				return setDataGold(res.payload);
			}
		})();
	}, []);

	useEffect(() => {
		if (containerTradingview) {
			const script = document.createElement('script');
			script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
			script.async = true;
			script.innerHTML = JSON.stringify({
				symbol: 'FX_IDC:XAUUSDG',
				width: '100%',
				colorTheme: 'light',
				isTransparent: true,
				locale: 'en',
			});
			containerTradingview.appendChild(script);
		}
	}, [containerTradingview]);

	return (
		<>
			{isLoading ? (
				<div className="uk-position-center">
					<div uk-spinner="ratio: 4.5"></div>
				</div>
			) : null}
			<GoldSpotPrice dataGold={dataGold} />
		</>
	);
};

export default Gold;
