import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import intlTelInput, { Plugin } from 'intl-tel-input';

import { payments } from '@gold//services';
import { CheckoutForm as CheckoutFormComponent, CheckoutHeader } from '@gold/components';
import { checkoutFormInitialValues, checkoutFormSchema, CheckoutValues } from '@gold/utils';
import { CURRENCIES, DEFAULT_CURRENCY } from '@shared/constants';
import { format } from '@shared/helpers';

import * as goldHelpers from '../../helpers';

const CheckoutForm: React.FC = () => {
	const {
		state: {
			amount,
			goldPrice,
			goldPriceCOP,
			totalPrice,
			totalPriceCOP,
			priceWithDiscount,
			priceWithDiscountCOP,
			couponPercent,
			couponCode,
			userInfo,
		},
	}: any = useLocation();

	const initialValues: CheckoutValues = userInfo
		? {
				firstName: userInfo.firstName ?? '',
				lastName: userInfo.lastName ?? '',
				secondLastName: userInfo.secondLastName ?? '',
				phone: {
					number: userInfo.phone?.number ?? 0,
					iso2: userInfo.phone?.iso2 ?? '',
				},
				idType: userInfo.identification?.type ?? 'CC',
				idNumber: userInfo.identification?.number ?? '',
				updatePersonalInfo: false,
				address: userInfo.address ?? '',
				addressComplement: userInfo.addressComplement ?? '',
				postalCode: userInfo.postalCode ?? '',
				city: userInfo.city ?? '',
				state: userInfo.state ?? '',
				country: userInfo.country ? goldHelpers.mapCountryNameToISOCode(userInfo.country) : '',
		  }
		: checkoutFormInitialValues;

	// formValues is the state on the form and can be used to
	// dynamically change the payment platform logo
	const [formValues, setFormValues] = React.useState<CheckoutValues>(initialValues);
	const [showPostalCode, setShowPostalCode] = React.useState<boolean>(goldHelpers.shouldShowPostalCode(initialValues.country));
	const [loader, setLoader] = React.useState<boolean>(false);
	const [selectedCurrency, setSelectedCurrency] = React.useState<string>(DEFAULT_CURRENCY);

	const handleCurrencyToggle = (currency: string) => setSelectedCurrency(currency);

	const handleCheckoutStart = async (values: CheckoutValues) => {
		try {
			setLoader(true);
			const valuesToUppercase = format.objectValuesToUpperCaseAndNormalize(values) as CheckoutValues;
			const {
				firstName,
				lastName,
				secondLastName,
				phone,
				idType,
				idNumber,
				updatePersonalInfo,
				address,
				addressComplement,
				postalCode,
				city,
				state,
				country,
			} = valuesToUppercase;
			const identification = { type: idType, number: idNumber };
			const response = await payments.dispatchCheckoutStart(
				amount,
				couponCode,
				firstName,
				lastName,
				secondLastName,
				phone,
				identification,
				updatePersonalInfo,
				address,
				addressComplement,
				postalCode,
				city,
				state,
				country,
			);
			setLoader(false);
			if (response && response.success && response.payload) {
				window.open(response.payload.initPoint, '_self');
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const input = document.querySelector('#phone');
		if (input) {
			const config = intlTelInput(input, {
				separateDialCode: true,
				initialCountry: 'co',
				utilsScript: '/js/utils.js',
			});
			itiConfig.current = config;
		}
	}, []);

	const itiConfig = React.useRef<Plugin>();

	const validate = React.useCallback(async (values: CheckoutValues) => {
		// validate is the onChange handler, it is guaranteed to
		// update the state on every value change
		setFormValues(values);
		setShowPostalCode(goldHelpers.shouldShowPostalCode(values.country));
		try {
			const countryData = itiConfig.current?.getSelectedCountryData();
			values.phone = {
				number: Number(values.phone.number) || 0,
				iso2: countryData?.iso2 || '',
			};
			await validateYupSchema(values, checkoutFormSchema(itiConfig.current), false);
		} catch (error) {
			const err = error as Error;
			if (err.name === 'ValidationError') {
				return yupToFormErrors<CheckoutValues>(err);
			} else {
				console.error(error);
			}
		}
		return {};
	}, []);

	return (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom mc-toggle">
					<CheckoutHeader />
					<Formik initialValues={initialValues} validate={validate} onSubmit={handleCheckoutStart}>
						{(formikProps: FormikProps<CheckoutValues>) => (
							<CheckoutFormComponent
								{...formikProps}
								priceTotal={priceWithDiscount ?? totalPrice}
								priceTotalCOP={priceWithDiscountCOP ?? totalPriceCOP}
								amount={amount}
								goldPrice={goldPrice}
								goldPriceCOP={goldPriceCOP}
								couponPercent={couponPercent}
								loader={loader}
								showPostalCode={showPostalCode}
								currencies={CURRENCIES}
								selectedCurrency={selectedCurrency}
								handleCurrencyToggle={handleCurrencyToggle}
								formValuesCountry={formValues.country}
							/>
						)}
					</Formik>
					<hr className="uk-margin-remove uk-divider" />
					<div className="uk-text-center uk-background-muted uk-padding uk-text-black uk-text-small uk-text-uppercase uk-text-bold mc-infopayments">
						<>
							<span>Secure payments powered by</span>
							<img
								className="uk-margin-small-left"
								src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
								alt="PayPal Logo"
							/>
						</>
					</div>
				</div>
			</section>
		</>
	);
};

export default CheckoutForm;
