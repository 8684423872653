import React from 'react';

import ModalProvider from './ModalProvider';
import UserInfoProvider from './UserInfoProvider';
import WalletProvider from './WalletProvider';

type WalletProviderProps = {
	children: React.ReactNode;
};

const GlobalProvider = (props: WalletProviderProps) => {
	return (
		<UserInfoProvider>
			<WalletProvider>
				<ModalProvider>{props.children}</ModalProvider>
			</WalletProvider>
		</UserInfoProvider>
	);
};

export default GlobalProvider;
