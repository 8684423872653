import React from 'react';

import { useUserInfoContext } from '@context/hooks';
import { HistoryPurchases as HistoryPurchasesComponent } from '@user/components';

const HistoryPurchases: React.FC = () => {
	const { userInfo } = useUserInfoContext();
	const transactions = userInfo && userInfo.wallet ? userInfo.wallet.transactions : [];

	return <HistoryPurchasesComponent historyPurchaseData={transactions} />;
};

export default HistoryPurchases;
