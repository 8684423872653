/* eslint-disable */
import './foot.css';

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Logout } from '@auth/services';
import { useUserInfoContext } from '@context/hooks';

type WalletProviderProps = {
	children: React.ReactNode;
};

const MainLayout = ({ children }: WalletProviderProps) => {
	const { pathname } = useLocation();

	const { userInfo } = useUserInfoContext();

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'js/particles.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const closeNavbar = () => (document.querySelector('.uk-offcanvas-close') as HTMLElement).click();

	const logoutAccount = () => {
		Logout();
		return (window.location.href = '/');
	};

	return (
		<>
			<div className="uk-section-primary uk-preserve-color" uk-height-viewport="offset-bottom: true">
				<div uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky uk-navbar-default; cls-inactive: uk-navbar-transparent; top: 200">
					<nav className="uk-navbar-container" uk-navbar="true">
						<div className="uk-navbar-left uk-flex-right">
							{userInfo ? (
								<button className="uk-navbar-toggle" uk-navbar-toggle-icon="true" uk-toggle="target: #mc-offcanvas"></button>
							) : null}
						</div>
						<div className="uk-navbar-center">
							<Link className="uk-navbar-item uk-logo" to="/" title="Logo">
								<img src="/images/mitacoin-head.png" alt="Mitacoin" />
							</Link>
						</div>
						<div className="uk-navbar-right uk-flex-left">
							{userInfo ? (
								<ul className="uk-navbar-nav">
									<li>
										<a>Hello {userInfo.firstName}</a>
									</li>
								</ul>
							) : (
								<ul className="uk-navbar-nav">
									<li>
										<Link to="/" className="" title="Login">
											Login
										</Link>
									</li>
									{/* <li>
										<Link to="/register" className="" title="Sign up">
											Sign up
										</Link>
									</li> */}
								</ul>
							)}
						</div>
					</nav>
				</div>
				{children}
			</div>
			<footer className="mc-footer">
				<div className="uk-section uk-section-xsmall uk-background-secondary">
					<div className="uk-container">
						<div className="uk-grid-collapse uk-flex uk-flex-center uk-flex-middle">
							<div>
								<small className="uk-text-bold uk-text-xsmall uk-text-uppercase">
									© Mitacoin. Medellín, city of innovation, Colombia
								</small>
							</div>
							{/* <div>
								<ul className="uk-subnav uk-subnav-divider uk-flex-center" uk-margin="false">
									<li>
										<a href="https://mitacoins.com/how" target="_blank" rel="noopener noreferrer">
											How
										</a>
									</li>
									<li>
										<a href="https://mitacoins.com/why-gold" target="_blank" rel="noopener noreferrer">
											Why
										</a>
									</li>
									<li>
										<a href="https://mitacoins.com/help" target="_blank" rel="noopener noreferrer">
											Help
										</a>
									</li>
								</ul>
							</div> */}
							{/* <div>
								<ul className="uk-iconnav mc-social uk-flex-center">
									<li>
										<a
											href="http://mitacoins.com/"
											target="_blank"
											rel="noopener noreferrer"
											className="uk-icon-button"
											uk-icon="icon: world"
										>
											{' '}
										</a>
									</li>
									<li>
										<a
											href="https://twitter.com/mitacoin"
											target="_blank"
											rel="noopener noreferrer"
											className="uk-icon-button"
											uk-icon="icon: twitter"
										>
											{' '}
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/mitacoingold/"
											target="_blank"
											rel="noopener noreferrer"
											className="uk-icon-button"
											uk-icon="icon: instagram"
										>
											{' '}
										</a>
									</li>
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</footer>

			<div id="particles-background" className="vertical-centered-box"></div>
			<div id="particles-foreground" className="vertical-centered-box"></div>

			{userInfo ? (
				<div id="mc-offcanvas" uk-offcanvas="mode: push; overlay: true">
					<div className="uk-offcanvas-bar uk-flex uk-flex-column">
						<button className="uk-offcanvas-close" type="button" uk-close="true"></button>
						<ul className="uk-nav uk-nav-primary uk-margin-auto-vertical">
							<li className={pathname === '/market' ? 'uk-active' : ''} onClick={closeNavbar}>
								<Link to="/market">Market</Link>
							</li>
							{/* <li className={pathname === '/shop' || pathname === '/checkout' ? 'uk-active' : ''} onClick={closeNavbar}>
								<Link to="/shop">Buy</Link>
							</li>
							<li className={pathname === '/transfer' ? 'uk-active' : ''} onClick={closeNavbar}>
								<Link to="/transfer">Transfer</Link>
							</li>
							<li className={pathname === '/my-account' ? 'uk-active' : ''} onClick={closeNavbar}>
								<Link to="/my-account">My Wallet</Link>
							</li> */}
							<li>
								<Link to="/" onClick={logoutAccount}>
									Logout
								</Link>
							</li>
						</ul>
					</div>
				</div>
			) : null}
		</>
	);
};

export default MainLayout;
