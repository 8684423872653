const getStatusColor = (value: string) => {
	if (value === 'intent' || value === 'inProgress') {
		return 'uk-label-warning';
	} else if (value === 'completed' || value === 'approved') {
		return 'uk-label-success';
	} else if (value === 'declined') {
		return 'uk-label-danger';
	}
};

export { getStatusColor };
