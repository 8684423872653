export const countriesWithPostalCode: string[] = [
	'AR',
	'AU',
	'AT',
	'BT',
	'BR',
	'CA',
	'CN',
	'KM',
	'DK',
	'FK',
	'FO',
	'FR',
	'GM',
	'DE',
	'GL',
	'IT',
	'JP',
	'KI',
	'KG',
	'MW',
	'MR',
	'YT',
	'MX',
	'NR',
	'NL',
	'NE',
	'NU',
	'NF',
	'NO',
	'PN',
	'PL',
	'RU',
	'SG',
	'ES',
	'SH',
	'PM',
	'SR',
	'SJ',
	'SE',
	'CH',
	'TV',
	'GB',
	'US',
	'VA',
	'WF',
];
