import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';

import { customHooks } from '@shared/index';
import { NewPassword as NewPasswordComponent } from '@user/components';
import { updatePassword } from '@user/services';
import { checkoutFormNewPasswordSchema, NewPasswordFormValues, userNewPasswordInitialValues } from '@user/utils';

const ResetPassword: React.FC = () => {
	const history = useHistory();
	const { state } = useLocation<string>();
	const [serverError, setServerError] = useState('');
	const [loader, setLoader] = useState(false);
	const [loadScript, setLoadScript] = useState(false);

	const [showPassword1, setShowPassword1] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);

	customHooks.useScript('/js/pswmeter.min.js', {
		onLoad: () => setLoadScript(true),
		onError: (err) => console.error(err),
	});

	const handleSubmit = async (values: NewPasswordFormValues) => {
		setLoader(true);
		const response = await updatePassword(values.password);
		setLoader(false);
		if (response && response.success) {
			return history.push('/');
		}
		return setServerError(response.message ?? 'Login error');
	};

	useEffect(() => {
		document.title = 'New Password - Mitacoin';
		if (!loadScript) {
			return;
		}
		new window.passwordStrengthMeter({
			containerElement: '#pswmeter',
			passwordInput: '#psw-input',
			showMessage: true,
			messageContainer: '#pswmeter-message',
			messagesList: ['Password Strength', 'Very Weak!', 'Weak', 'Medium', 'Strong'],
			height: 6,
			borderRadius: 0,
			pswMinLength: 8,
			colorScore1: '#DA0D34',
			colorScore2: '#FA6400',
			colorScore3: '#0475E2',
			colorScore4: '#00B6B9',
		});
	}, [loadScript]);

	return (
		<Formik initialValues={userNewPasswordInitialValues} validationSchema={checkoutFormNewPasswordSchema} onSubmit={handleSubmit}>
			{(formikProps: FormikProps<NewPasswordFormValues>) => (
				<>
					<NewPasswordComponent
						{...formikProps}
						serverError={serverError}
						loader={loader}
						userName={state}
						showPassword1={showPassword1}
						showPassword2={showPassword2}
						togglePassword1={() => setShowPassword1(!showPassword1)}
						togglePassword2={() => setShowPassword2(!showPassword2)}
					/>
				</>
			)}
		</Formik>
	);
};

export default ResetPassword;
