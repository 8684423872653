/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { hooks } from '@context/index';
import { Shop as ShopComponent } from '@gold/components';
import { goldPrice } from '@gold/services';
import { CURRENCIES, DEFAULT_CURRENCY } from '@shared/constants';
import { Loading } from '@shared/screens';
import { IDataGold, IDataSupply } from '@shared/utils';
const { useWalletContext } = hooks;

const Shop: React.FC<{}> = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [dataGold, setDataGold] = useState<IDataGold>();
	const [dataSupply, setDataSupply] = useState<IDataSupply>();
	const [selectedCurrency, setSelectedCurrency] = useState<string>(DEFAULT_CURRENCY);

	const { wallet, clearWallet, isLoading: isWalletLoading } = useWalletContext();

	useEffect(() => {
		document.title = 'Shop - Mitacoin';
		const getSupply = async () => {
			const getSupplyMitacoins = await goldPrice.getSupply();
			if (getSupplyMitacoins.payload) {
				setDataSupply(getSupplyMitacoins.payload);
			}
		};
		const getGoldPrice = async () => {
			setIsLoading(true);
			const responseDataGold = await goldPrice.getGoldPrice();
			setIsLoading(false);
			if (responseDataGold.payload) {
				setDataGold(responseDataGold.payload);
			}
		};
		getSupply();
		getGoldPrice();
		return clearWallet();
	}, []);

	const handleCurrencyToggle = (currency: string) => setSelectedCurrency(currency);

	return (
		<>
			{isLoading || isWalletLoading ? <Loading /> : null}
			{wallet && dataGold ? (
				<ShopComponent
					currencies={CURRENCIES}
					dataWallet={wallet}
					dataGold={dataGold}
					dataSupply={dataSupply}
					handleToggle={handleCurrencyToggle}
					selectedCurrency={selectedCurrency}
				/>
			) : null}
		</>
	);
};

export default Shop;
