import './checkout.css';

import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutComponent: React.FC = () => {
	return (
		<div className="uk-background-gold mc-breadcrumb">
			<div className="uk-container">
				<ul className="uk-breadcrumb uk-flex-center">
					<li>
						<Link to="/market">Mitacoin</Link>
					</li>
					<li>
						<Link to="/shop">Shop</Link>
					</li>
					<li>
						<span>Checkout</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default CheckoutComponent;
