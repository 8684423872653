export const TRANSACTION_STATUS_TYPE = [
	{
		state: 'intent',
		value: 'uk-label-warning',
	},
	{
		state: 'inProgress',
		value: 'uk-label-warning',
	},
	{
		state: 'completed',
		value: 'uk-label-success',
	},
	{
		state: 'approved',
		value: 'uk-label-success',
	},
	{
		state: 'declined',
		value: 'uk-label-danger',
	},
];
