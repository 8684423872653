/* eslint-disable */
import { useEffect } from 'react';

const useScript = (
	url: string,
	{
		onLoad,
		onError,
	}: {
		onLoad: (ev: Event) => void;
		onError: (error: ErrorEvent) => void;
	},
) => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = url;
		script.async = true;

		document.body.appendChild(script);
		script.addEventListener('load', onLoad);
		script.addEventListener('error', onError);
		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
};

export default useScript;
