import React, { createContext, useMemo, useState } from 'react';

export const ModalContext = createContext({
	modalTransfer: { modalTransferOpen: false, setModalTransferOpen: (bool: boolean) => {} },
});

const ModalProvider: React.FC<any> = (props) => {
	const [modalTransferOpen, setModalTransferOpen] = useState<boolean>(false);

	const memoizedOutput = useMemo(
		() => ({
			modalTransfer: { modalTransferOpen, setModalTransferOpen },
		}),
		[modalTransferOpen, setModalTransferOpen],
	);

	return <ModalContext.Provider value={memoizedOutput} {...props} />;
};

export default ModalProvider;
