/* eslint-disable */
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { user } from '../../modules';

export const UserInfoContext = createContext({
	userInfo: null as any,
	setUserInfo: () => null,
});

const UserInfoProvider: React.FC<any> = (props) => {
	const [userInfo, setUserInfo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isAuth] = useState(!!window.sessionStorage.getItem('auth'));

	useEffect(() => {
		const getWallet = async () => {
			try {
				setIsLoading(true);
				const res = await user.services.getUserInfo();
				setIsLoading(false);

				if (res && res.success && res.payload) {
					setUserInfo(res.payload);
					// console.log(res.payload);
					return;
				}
				throw new Error();
			} catch (error) {
				window.location.href = '/general-error';
			}
		};

		if (isAuth && !isLoading && !userInfo && window.location.pathname !== '/general-error') {
			getWallet();
		}
	}, [isAuth]);

	const memoizedOutput = useMemo(
		() => ({
			userInfo,
			setUserInfo,
		}),
		[userInfo, setUserInfo],
	);

	return <UserInfoContext.Provider value={memoizedOutput} {...props} />;
};

export default UserInfoProvider;
