/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { confirmMyAccount } from '@auth/services';
import { useWalletContext } from '@context/hooks';
import * as shared from '@shared/index';
const { Loading } = shared.screens;

interface IAccountParams {
	token: string;
}

const AccountConfirmation: React.FC<{}> = () => {
	const { setIsAuth: setIsAuthOnWalletProvider } = useWalletContext();
	const { token } = useParams<IAccountParams>();

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [user, setUser] = useState(false);

	useEffect(() => {
		const confirmAccount = async () => {
			setIsLoading(true);
			const response = await confirmMyAccount({ token });
			setIsLoading(false);
			if (response && response.success) {
				shared.helpers.setAuthTokens(response.payload);
				setIsAuthOnWalletProvider(true);
				toast.success('Account confirmed successfully!');
				return setUser(true);
			}
			if ((response as any).status === 409) {
				return setErrorMessage(response.message ?? '`Failed to register user in the block chain`');
			}
			return setErrorMessage(response.message ?? 'Register user error');
		};
		confirmAccount();
	}, [token]);

	// No active
	// const tryAgainEnrollUser = React.useCallback(async () => {
	//   setIsLoading(true);
	//   const response = await enrollUser({ token });
	//   setIsLoading(false);
	//   if (response && response.success) {
	//     shared.helpers.setAuthTokens(response.payload);
	//     setIsAuthOnWalletProvider(true);
	//     toast.success('User registered successfully!');
	//     return setUser(true);
	//   }
	//   return setErrorMessage(response.message ?? 'Register user error');
	// }, [token]);

	return (
		<>
			{errorMessage ? (
				<section className="uk-section uk-section-xsmall">
					<div className="mc-shop uk-text-center">
						<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
							<div className="uk-width-xlarge uk-margin-auto uk-text-large uk-text-center uk-text-orange">
								<img src="/images/mitacoin-cart-error.png" alt="" width="200" />
								<br />
								<p className="uk-margin-small">{errorMessage}</p>
								<p className="uk-text-intro uk-text-graylightxx uk-margin-small">Please try again or contact us.</p>
							</div>
							<div className="uk-text-center uk-margin-medium">
								<a href="https://mitacoins.com/helps" className="uk-button uk-button-default">
									Contact us
								</a>
							</div>
						</div>
					</div>
				</section>
			) : null}
			{isLoading ? <Loading /> : null}
			{user ? <Redirect to={{ pathname: '/market', state: { userNew: true } }} /> : null}
		</>
	);
};

export default AccountConfirmation;
