import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { urls } from '@shared/helpers';

const PurchaseFailed: React.FC = () => {
	// platform is the payment platform that redirects
	// the user here
	const platform = urls.useQuery().get('platform');
	const { state } = useLocation<{ message: string; test: number }>();
	return (
		<section className="uk-section uk-section-xsmall">
			<div className="mc-shop uk-text-center">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-width-xlarge uk-margin-auto uk-text-large uk-text-center uk-text-orange">
						<img src="/images/mitacoin-cart-error.png" alt="" width="200" />
						<br />
						<p className="uk-margin-small">{state ? state.message : 'We were unable to complete your purchase'}</p>
						<p className="uk-text-intro uk-text-graylightxx uk-margin-small">Please try again or contact us.</p>
					</div>
					<div className="uk-text-center uk-margin-medium">
						<Link to="/market" type="submit" className="uk-button uk-button-default">
							Back to market
						</Link>
					</div>
					<div className="uk-text-center uk-padding-small uk-text-graylight uk-text-small uk-text-uppercase uk-text-bold uk-margin-large-top">
						<>
							Secure payments powered by{' '}
							{platform === 'pp' ? (
								<img className="uk-margin-small-left" src="/images/paypal-logo.png" alt="PayPal Logo" />
							) : (
								<img className="uk-margin-small-left" src="" alt="" />
							)}
						</>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PurchaseFailed;
