import { AxiosError, AxiosResponse } from 'axios';

import { MitacoinServiceResponse } from '@shared/utils';

/**
 * when we have 400 try to get the errors.detail for using
 * for messages and modals
 * @param error any
 */
const getDetailError = (error: any | AxiosError | AxiosResponse) => {
	const defaultErrMsg = 'Please try again.';
	try {
		const response = error.response?.data ?? error;
		return response.message ?? defaultErrMsg;
	} catch (error) {
		return defaultErrMsg;
	}
};

const handleError = (error: any): MitacoinServiceResponse => {
	// TODO sentry
	return { success: false, message: getDetailError(error) };
};

export { handleError };
