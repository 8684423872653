import './TotalDisclaimer.css';

import React from 'react';

const TotalDisclaimer: React.FC = () => {
	return (
		<div className="mc-total-disclaimer">
			<span className="uk-text-blue">*Approximate investment value.</span>Final payment will be recalculated according to the
			London Stock Exchange at checkout.
		</div>
	);
};

export default TotalDisclaimer;
