import './Calculator.css';

import React, { ChangeEvent, KeyboardEvent } from 'react';

import { valueToMitacoin } from '@transfers/helpers';

type CalculatorProps = {
	priceUSD: number;
	priceCOP: number;
	priceUSDtoCOP: number;
	setInputAmount: (val: string) => void;
};

// TODO: copy/paste from Transfer.tsx but this could be
// a shared function for inputs that just allow positive
// numbers
const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>, currency: string) => {
	const regexExpresion = currency === 'usd' ? /^[0-9.]+$/ : /^[0-9]+$/;
	if (!regexExpresion.test(e.key)) {
		if (e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || e.key === 'Delete') {
			return true;
		}
		return e.preventDefault();
	}
};

const setDecimals = (n: number, posiciones = 0) => {
	const numberToString = n.toString();
	const decimalLength = numberToString.indexOf('.') + 1;
	const numStr = numberToString.slice(0, decimalLength + posiciones);
	return numStr;
};

const Calculator: React.FC<CalculatorProps> = ({ priceUSD, priceCOP, priceUSDtoCOP, setInputAmount }: CalculatorProps) => {
	const [currency, setCurrency] = React.useState<string>('usd');
	const [value, setValue] = React.useState<string>('');
	const [total, setTotal] = React.useState<string>('0');

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const num = e.target.value.split('.');
		if (num[1]) {
			if (num[1].length >= 3) num[1] = num[1].substring(0, 3);
		}
		setValue(num.join('.'));
	};
	React.useEffect(() => {
		const price = currency === 'usd' ? priceUSD : priceCOP;
		const mitacoinAmount = valueToMitacoin(Number(value) || 0, price ?? 0, currency);
		const mitacoinDecimals = setDecimals(mitacoinAmount, 3);
		// TODO: are 2 decimal places OK?
		setTotal(mitacoinDecimals);
	}, [value, currency, priceUSD, priceCOP]);

	const handleClickCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		navigator.clipboard.writeText(total);
		setInputAmount(total);
	};

	return (
		<>
			<div className="mc-calculate" data-uk-alert>
				{/* <button className="uk-alert-close" data-uk-close></button> */}
				<div className="uk-grid-collapse" data-uk-grid>
					<div className="mc-options">
						<ul
							className="uk-subnav uk-subnav-pill uk-flex-middle"
							data-uk-switcher="connect: .mc-calculator-rate; swiping: false; animation: uk-animation-fade"
						>
							<li>
								<small className="uk-text-xsmall">Calculate your transfer ❯❯</small>
							</li>
							<li className="uk-active">
								<a
									onClick={() => {
										setCurrency('usd');
										setValue('');
									}}
									href="/#"
								>
									USD
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										setCurrency('cop');
										setValue('');
									}}
									href="/#"
								>
									COP
								</a>
							</li>
						</ul>
					</div>
					<div className="mc-calculator-container uk-flex uk-flex-center uk-width-1-1">
						<div className="mc-calculator-sign">
							<legend>$</legend>
						</div>
						<div className="mc-calculator-field uk-flex">
							<div className="uk-form-controls uk-inline-block">
								<input
									type="text"
									value={value}
									placeholder="200"
									className="uk-input uk-text-center uk-text-number"
									onKeyDown={(e) => handleOnKeyDown(e, currency)}
									onChange={handleOnChange}
									maxLength={7}
								/>
							</div>
							<label className="uk-form-label uk-text-center">{currency.toUpperCase()}</label>
						</div>
						<div className="mc-calculator-sign">
							<legend>=</legend>
						</div>
						<div className="mc-calculator-field uk-flex">
							<div className="uk-form-controls uk-inline-block">
								<span className="uk-form-icon uk-form-icon-flip	" data-uk-icon>
									<img src="/images/mc-crown-chip.png" alt="" width="30" />
								</span>
								<input type="text" value={total} className="uk-input uk-text-center uk-text-number mc-total" disabled />
							</div>
							<label className="uk-form-label uk-text-center">Mitacoins</label>
						</div>
						<div
							className="mc-calculator-field"
							// style={{ visibility: 'hidden' }}
						>
							<button className="uk-button uk-button-gray" onClick={handleClickCopy}>
								Copy
							</button>
						</div>
					</div>
					<ul className="mc-calculator-rate uk-switcher uk-width-1-1">
						<li></li>
						<li></li>
						<li>
							<div className="uk-text-center uk-text-small">
								<span
									className="uk-icon uk-icon-image"
									style={{
										backgroundImage: 'url(/images/icons/ico-currency.svg)',
									}}
								></span>
								Approx. exchange rate: <strong>1 USD = ${priceUSDtoCOP} COP</strong>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default Calculator;
