import './shop.css';

import React, { ChangeEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useUserInfoContext } from '@context/hooks';
import { payments } from '@gold/services';
import { CurrencyToggle, TotalDisclaimer } from '@shared/components';
import { COP_INCREMENT } from '@shared/constants';
import { formatNumber, formatNumberCOP } from '@shared/helpers/format';
import { IDataGold, IDataWallet } from '@shared/utils';

import config from '../../../../config';

interface ShopProps {
	currencies: string[];
	dataWallet: IDataWallet;
	dataGold: IDataGold;
	dataSupply?: { supply: number; name: string };
	handleToggle: (currency: string) => void;
	selectedCurrency: string;
}

const ShopComponent: React.FC<ShopProps> = ({ currencies, dataWallet, dataGold, dataSupply, handleToggle, selectedCurrency }) => {
	if (!config || !config.currentInitialSupply) {
		throw new Error('');
	}
	const currentSupply = parseInt(config.currentInitialSupply);
	const history = useHistory();
	const { userInfo } = useUserInfoContext();

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [amount, setAmount] = useState<number>(1);
	const [couponCode] = useState('');
	const { price: priceUSD, priceCOP } = dataGold;
	const [, setTotalPrice] = useState<number>(priceUSD);
	// const handleChangeCoupunValue = (e: ChangeEvent<HTMLInputElement>) => {
	// 	return setCounponCode(e.target.value);
	// };

	// reflect the increment for COP price
	const priceCOPWithIncrement = (1 + COP_INCREMENT) * priceCOP;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const re = /^[0-9\b]+$/;
		if (!re.test(e.target.value)) {
			return;
		}
		const eventTargetValue: number = Number(e.target.value);
		setAmount(Math.round(eventTargetValue));
		setTotalPrice(eventTargetValue * priceUSD);
	};

	const increaseMitacoins = () => {
		setAmount(amount + 1);
		setTotalPrice((amount + 1) * priceUSD);
	};

	const decreaseMitacoins = () => {
		if (amount <= 1) {
			return;
		}
		setAmount(amount - 1);
		setTotalPrice((amount - 1) * priceUSD);
	};

	const validation = async () => {
		setIsLoading(true);
		if (dataSupply) {
			if (dataSupply.supply <= 0) {
				setError('At this time we do not have mitacoins available for sale');
				return setIsLoading(false);
			}
		}

		if (amount <= 0) {
			setError('Purchase not possible');
			return setIsLoading(false);
		}

		if (dataSupply) {
			if (dataSupply.supply < amount) {
				setError(`At this time you can only buy a maximum of ${dataSupply.supply} Mitacoins`);
				return setIsLoading(false);
			}
		}
		const goldPrice = (document.getElementById('goldPrice') as HTMLInputElement).value;
		if (goldPrice === '') {
			setError('Service not available ');
			return setIsLoading(false);
		}

		const response = await payments.initTransaction(amount, couponCode);
		setIsLoading(false);
		if (response && response.success && response.payload) {
			return history.push({
				pathname: '/checkout',
				state: {
					amount: response.payload.amount,
					goldPrice: response.payload.goldPrice,
					goldPriceCOP: response.payload.goldPriceCOP,
					totalPrice: response.payload.totalPrice,
					totalPriceCOP: response.payload.totalPriceCOP,
					priceWithDiscount: response.payload.priceWithDiscount,
					couponPercent: response.payload.couponPercent,
					couponCode,
					userInfo,
				},
			});
		}
		return setError(response.message ?? 'Please try again later');
	};

	const getSupplyAlert = (seasonName: string, message: string | number, bgColor: string, textColor: string) => (
		<div className={`mc-stocks uk-alert-${bgColor}`} uk-alert="true">
			<span>
				Season {seasonName} <strong className={`uk-text-uppercase uk-text-${textColor}`}>{message} Tokens Available</strong>
			</span>
		</div>
	);
	// TODO handle initialSupply and leftSupply for dynamic percentages
	const renderSupplyAlert = () => {
		const supplyFractions = {
			tenPercent: currentSupply * 0.1,
			half: currentSupply * 0.5,
		};
		if (!dataSupply) {
			return null;
			// check for ten percent left
		} else if (dataSupply.supply <= supplyFractions.tenPercent) {
			return getSupplyAlert(dataSupply.name, `only ${dataSupply.supply}`, 'danger', 'danger');
			// check for fifty percent left
		} else if (dataSupply.supply <= supplyFractions.half) {
			return getSupplyAlert(dataSupply.name, dataSupply.supply, 'warning', 'warning');
			// check for more than fifty percent left
		} else if (dataSupply.supply > supplyFractions.half) {
			return getSupplyAlert(dataSupply.name, dataSupply.supply, 'primary', 'blue');
		}
	};

	return dataWallet.initialInvestmentValue === null ? (
		<section className="uk-section uk-section-xsmall">
			<div className="mc-shop uk-text-center">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-width-xlarge uk-margin-auto uk-text-large uk-text-center uk-text-orange">
						<img src="/images/mitacoin-cart-error.png" alt="" width="200" />
						<br />
						<p className="uk-margin-small">Service not available</p>
						<p className="uk-text-intro uk-text-graylightxx uk-margin-small">Please try again or contact us.</p>
					</div>
					<div className="uk-text-center uk-margin-medium">
						<Link to="/market" type="submit" className="uk-button uk-button-default">
							Back to market
						</Link>
					</div>
				</div>
			</div>
		</section>
	) : (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<Link to="/">Mitacoin</Link>
								</li>
								<li>
									<span>Shop</span>
								</li>
							</ul>
						</div>
					</div>

					<CurrencyToggle currencies={currencies} selectedCurrency={selectedCurrency} handleToggle={handleToggle} />

					<div className="uk-card mc-shop uk-width-xlarge uk-margin-auto uk-margin-medium-top">
						<form className="uk-form-stacked">
							<div className="uk-width-1-1 uk-text-center uk-margin">
								<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-small">
									<span>Mitacoin Shop</span>
								</legend>
								<p className="uk-text-subheading uk-text-bluelight uk-margin-small">Price is subject to the stock market</p>
								{renderSupplyAlert()}
							</div>

							<div className="uk-grid-collapse mc-shop-container" uk-grid="true">
								<div className="uk-text-center mc-shop-coin">
									<div className="uk-inline">
										<img className="mc-shop-dots" src="/images/mitacoin-dots.png" alt="" />
										<div className="uk-overlay uk-position-cover">
											<div className="coin"></div>
										</div>
									</div>
								</div>
								<div className="uk-text-center mc-shop-fields">
									<div className="mc-shop-quantity">
										<label className="uk-form-label uk-text-center">How many mitacoins?</label>
										<div className="uk-form-controls uk-flex uk-flex-center uk-flex-middle">
											<input type="button" className="mc-minus uk-button uk-button-quantity" onClick={decreaseMitacoins} />
											<input
												type="text"
												name="quantity_mitacoins"
												className="uk-input uk-text-center uk-form-width-small uk-text-number"
												value={amount}
												onChange={handleChange}
											/>
											<input type="button" className="mc-plus uk-button uk-button-quantity" onClick={increaseMitacoins} />
										</div>
										<div className="mc-form-validation uk-hidden">
											<img src="/images/icons/ico-formerror.svg" alt="" /> Please select Mitacoins to invest
										</div>
									</div>
									<div className="mc-shop-price uk-margin">
										<label className="uk-form-label uk-text-center">{`Market Price (${selectedCurrency.toUpperCase()})`}</label>
										<div className="uk-form-controls uk-text-center">
											<div className="uk-inline">
												<span className="uk-form-icon">
													<img src="/images/icons/ico-money.svg" width="30" alt="" />
												</span>
												<input
													type="text"
													id="goldPrice"
													className="uk-input uk-text-center uk-form-width-medium uk-text-number"
													disabled
													value={
														selectedCurrency === 'usd'
															? formatNumber(priceUSD.toString(), undefined, undefined, false)
															: formatNumberCOP(priceCOPWithIncrement.toString(), 2, undefined, false)
													}
												/>
											</div>
										</div>
									</div>
									{/* <button
                    type="button"
                    className="mc-shop-coupon uk-button uk-button-success uk-button-small uk-button-pill"
                    uk-toggle="target: .mc-shop-coupon"
                  >
                    Add promo coupon
                  </button> */}
									{/* <div className="mc-shop-coupon uk-margin" hidden>
                    <label className="uk-form-label uk-text-center">
                      Coupon code
                    </label>
                    <div className="uk-form-controls uk-text-center">
                      <div className="uk-inline">
                        <span className="uk-form-icon">
                          <img
                            src="/images/icons/ico-coupon.svg"
                            width="30"
                            alt=""
                          />
                        </span>
                        <input
                          type="text"
                          className="uk-input uk-text-center uk-form-width-medium"
                          placeholder=""
                          name="coupon_code"
                          onChange={handleChangeCoupunValue}
                        />
                      </div>
                    </div>
                    <div className="mc-form-validation uk-hidden">
                      <img src="/images/icons/ico-formerror.svg" alt="" />{' '}
                      Coupon code invalid, try again
                    </div>
                  </div> */}
									<div className="uk-margin mc-total">
										<hr className="uk-margin-small" />
										<strong className="uk-text-small uk-text-uppercase uk-text-blue uk-text-middle uk-margin-small-right">
											Total
										</strong>
										<strong className="uk-text-number uk-text-large uk-text-bluelight uk-text-middle">
											{selectedCurrency === 'usd'
												? formatNumber((amount * priceUSD).toString())
												: formatNumberCOP((amount * priceCOPWithIncrement).toString(), 2)}
											*
										</strong>
										<strong className="uk-text-small uk-text-uppercase uk-text-blue uk-text-middle uk-margin-small-left">
											{`(${selectedCurrency.toUpperCase()})`}
										</strong>
									</div>

									<TotalDisclaimer />

									<div className="uk-width-1-1 uk-margin-large-bottom uk-margin-top uk-text-center mc-checkout">
										<button
											type="button"
											className={`uk-button uk-button-primary ${isLoading && 'loading'}`}
											onClick={validation}
										>
											Go to Checkout
										</button>
										{error ? <p className="uk-text-danger">{error}</p> : null}
									</div>
								</div>
							</div>
						</form>
					</div>
					<hr className="uk-margin-remove uk-divider" />
					<div className="uk-text-center uk-background-muted uk-padding uk-text-black uk-text-small uk-text-uppercase uk-text-bold mc-infopayments">
						<span>Secure payments</span>
						{/* <img className="uk-margin-small-left" src="/images/mercado-pago.svg" alt="" />
						<img
							className="uk-margin-small-left"
							src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_37x23.jpg"
							alt="PayPal Logo"
						/> */}
					</div>
				</div>
			</section>
		</>
	);
};

export default ShopComponent;
