import React, { useEffect, useState } from 'react';

import { hooks } from '@context/index';
import { IHistoryTransfers } from '@shared/utils';
import { getTransferHistory } from '@transfers/services';
import { TransferHistory as TransferHistoryComponent } from '@user/components';

const TransferHistory = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [history, setHistory] = useState<IHistoryTransfers[]>();
	const { wallet } = hooks.useWalletContext();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await getTransferHistory();
			setIsLoading(false);
			if (res && res.success) {
				setHistory(res.payload.history ?? []);
			}
		})();
	}, [wallet]);
	return (
		<>
			{isLoading ? (
				<div className="uk-text-center">
					<div uk-spinner="ratio: 4.5" className="uk-margin"></div>
				</div>
			) : null}
			{history && !isLoading ? <TransferHistoryComponent history={history} /> : null}
		</>
	);
};

export default TransferHistory;
