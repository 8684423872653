import './app.css';

import React from 'react';

import Router from './router';

function App() {
	return (
		<>
			<Router />
			{/* <a href="https://wa.me/573215126782" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
				<i className="fa fa-whatsapp whatsapp-icon"></i>
			</a> */}
		</>
	);
}

export default App;
