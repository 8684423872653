import 'react-toastify/dist/ReactToastify.css';
import './landing.css';

import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { containers as GoldContainers } from '@gold/index';
import { Landing as LandingContainer, Wallet } from '@user/containers';

const { GoldSpotPrice } = GoldContainers;

const Landing: React.FC = () => {
	return (
		<>
			<ToastContainer />
			<LandingContainer />
			<section className="uk-section uk-section-xsmall">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<Link to="/market">Mitacoin</Link>
								</li>
								<li>
									<span>Market</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="uk-card mc-market uk-width-xlarge uk-margin-auto uk-margin-medium-top">
						<form className="uk-form-stacked">
							<Wallet />
							<GoldSpotPrice />

							{/* tradingview */}
							<div className="uk-width-1-1 mc-market-history">
								<legend className="uk-heading-small uk-heading-line uk-text-bluedark uk-text-center uk-margin-remove">
									<span>Gold Indices</span>
								</legend>
								<div className="mc-history-chart" id="tradingview_0703b"></div>
								<div className="tradingview-widget-copyright uk-text-blue uk-margin-small">
									<a href="https://www.tradingview.com/markets/indices/" rel="noopener noreferrer" target="_blank">
										<span className="uk-text-xsmall uk-text-blue uk-text-uppercase">Gold Indices by TradingView</span>
									</a>
								</div>
							</div>
							{/* tradingview */}
							<div className="uk-padding-small uk-padding-remove-horizontal uk-padding-remove-top" />
							{/* <hr className="uk-divider-light uk-margin-medium" />
							<div className="uk-width-1-1 uk-text-center mc-invest">
								<p className="uk-text-small uk-text-graydark uk-text-uppercase uk-text-bold"> What are you waiting for?</p>
								<Link to="/shop" type="submit" className="uk-button uk-button-primary uk-width-medium@s">
									<img src="/images/mc-crown.png" width="32" alt="" /> Buy Mitacoins now!{' '}
								</Link>
								<br />
							</div> */}
							{/* <hr className="uk-divider-light uk-margin-medium" />
							<div className="uk-width-1-1 uk-text-center mc-invest">
								<a
									href="https://mitacoins.com/why-gold/"
									className="uk-button uk-button-text uk-margin-medium-bottom"
									rel="noopener noreferrer"
									target="_blank"
								>
									Why gold? Click here
								</a>
							</div> */}
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default Landing;
