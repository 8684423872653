/* eslint-disable */
import './register.css';
import './phone-input.css';
import './intlTelInput.css';

import React, { useEffect } from 'react';
import { ErrorMessage, Field, FormikProps } from 'formik';

import { RegisterFormValues } from '@auth/utils';

type RegisterProps = FormikProps<RegisterFormValues> & {
	serverError: string;
	loader: boolean;
	showPassword: boolean;
	setShowPassword: () => void;
	referrer: string;
};

const Register: React.FC<RegisterProps> = ({
	serverError,
	loader,
	showPassword,
	setShowPassword,
	handleChange,
	handleSubmit,
	errors,
	touched,
	isSubmitting,
	setFieldValue,
	referrer,
}) => {
	useEffect(() => {
		const input = document.querySelector('#phone');
		if (input) {
			input.addEventListener('countrychange', function () {
				setFieldValue('phone.iso2', '');
			});
		}
	}, []);

	useEffect(() => {
		const keys = Object.keys(errors);
		if (isSubmitting && keys.length > 0) {
			if (keys[0] === 'phone') {
				return (document.querySelector('#phone') as HTMLElement).focus();
			}
			if (keys[0] === 'termsCheckboxes') {
				// additional processing needs to be done in order to focus a terms
				// and conditions checkbox by its name
				const checkboxesKeys = Object.keys(errors.termsCheckboxes!);
				(document.querySelector(`[name="termsCheckboxes.${checkboxesKeys[0]}"]`) as HTMLElement).focus();
			} else {
				(document.querySelector(`[name="${keys[0]}"]`) as HTMLElement).focus();
			}
		}
	}, [isSubmitting, errors, touched]);
	return (
		<>
			<section className="uk-section uk-section-xsmall">
				<div className="uk-width-1-2@l uk-width-3-4@m uk-width-4-5@s uk-background-default uk-margin-auto uk-margin-medium-bottom">
					<div className="uk-background-gold mc-breadcrumb">
						<div className="uk-container">
							<ul className="uk-breadcrumb uk-flex-center">
								<li>
									<a href="/">Mitacoin</a>
								</li>
								<li>
									<span>Create Account</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="uk-card mc-forms mc-register-form uk-width-xlarge uk-margin-auto uk-margin-medium-top">
						<form className="uk-form-stacked uk-grid-medium" uk-grid="true" onSubmit={handleSubmit} autoComplete="off">
							<div className="uk-width-1-1 uk-text-center">
								<legend className="uk-heading-small uk-heading-line uk-text-center uk-margin-small">
									<span>Create Account</span>
								</legend>
								<p className="uk-text-subheading uk-text-bluedark uk-margin-small">Welcome to Mitacoin!</p>
								<p className="uk-text-content uk-text-graydark uk-text-bolder uk-margin-remove">
									Please complete your information, go to your inbox and click on welcome email link to confirm and activate your
									account:
								</p>
							</div>
							<div className="uk-width-1-2@s">
								<label className="uk-form-label">First Name*</label>
								<div className="uk-form-controls">
									<Field
										className={`uk-input ${errors.firstName && touched.firstName ? 'input-error' : null}`}
										type="text"
										placeholder=""
										name="firstName"
										onChange={handleChange}
									/>
									<div className="mc-form-validation uk-hidden">
										<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
									</div>
								</div>
								{errors.firstName && touched.firstName && (
									<ErrorMessage name="firstName" component="small" className="errorMessage" />
								)}
							</div>
							<div className="uk-width-1-2@s">
								<label className="uk-form-label">Last Name*</label>
								<div className="uk-form-controls">
									<Field
										className={`uk-input ${errors.lastName && touched.lastName ? 'input-error' : null}`}
										type="text"
										placeholder=""
										name="lastName"
										onChange={handleChange}
									/>
									<div className="mc-form-validation uk-hidden">
										<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
									</div>
								</div>
								{errors.lastName && touched.lastName && (
									<ErrorMessage name="lastName" component="small" className="errorMessage" />
								)}
							</div>
							<div className="uk-width-1-2@s">
								<label className="uk-form-label">Second Last Name</label>
								<div className="uk-form-controls">
									<Field
										className={`uk-input ${errors.secondLastName && touched.secondLastName ? 'input-error' : null}`}
										type="text"
										placeholder=""
										name="secondLastName"
										onChange={handleChange}
									/>
									<div className="mc-form-validation uk-hidden">
										<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
									</div>
								</div>
								{errors.secondLastName && touched.secondLastName && (
									<ErrorMessage name="secondLastName" component="small" className="errorMessage" />
								)}
							</div>

							<div className="uk-width-1-2@s mc-phone">
								<label className="uk-form-label">Mobile Phone Number*</label>
								<div className="uk-form-controls">
									<input
										className={`uk-input ${errors.phone && touched.phone ? 'input-error' : null}`}
										id="phone"
										name="phone.number"
										onChange={handleChange}
										type="tel"
									/>
								</div>
								<div className="mc-form-validation uk-hidden">
									<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
								</div>
								{errors.phone ? (
									typeof errors.phone === 'string' ? (
										<ErrorMessage name="phone" component="small" className="errorMessage" />
									) : (
										<ErrorMessage name="phone.number" component="small" className="errorMessage" />
									)
								) : null}
							</div>

							{/* {referrer === '' ? (
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label">Referral Code</label>
                  <div className="uk-form-controls">
                    <Field
                      className={`uk-input ${
                        errors.whoReferred && touched.whoReferred
                          ? 'input-error'
                          : null
                      }`}
                      type="text"
                      placeholder=""
                      name="whoReferred"
                      onChange={handleChange}
                      maxLength="9"
                    />
                    <div className="mc-form-validation uk-hidden">
                      <img src="/images/icons/ico-formerror.svg" alt="" />{' '}
                      Information Required
                    </div>
                  </div>
                  {errors.whoReferred && touched.whoReferred && (
                    <ErrorMessage
                      name="whoReferred"
                      component="small"
                      className="errorMessage"
                    />
                  )}
                </div>
              ) : (
                <div className="uk-width-1-2@s">
                  <label className="uk-form-label">Referral Code</label>
                  <div className="uk-form-controls">
                    <Field
                      className={`uk-input ${
                        errors.whoReferred && touched.whoReferred
                          ? 'input-error'
                          : null
                      }`}
                      type="text"
                      placeholder={referrer}
                      name="whoReferred"
                      onChange={handleChange}
                      maxLength="9"
                      readonly="true"
                    />
                    <div className="mc-form-validation uk-hidden">
                      <img src="/images/icons/ico-formerror.svg" alt="" />{' '}
                      Information Required
                    </div>
                  </div>
                  {errors.whoReferred && touched.whoReferred && (
                    <ErrorMessage
                      name="whoReferred"
                      component="small"
                      className="errorMessage"
                    />
                  )}
                </div>
              )} */}

							<div className="uk-width-1-1 uk-text-center uk-margin-large-top">
								<hr className="uk-margin-small uk-divider" />
								<p className="uk-text-heading uk-text-uppercase uk-text-blue uk-margin-remove">
									<img className="uk-margin-small-right" src="/images/icons/ico-user-crown.svg" alt="" /> Account Access
									Information:
								</p>
								<hr className="uk-margin-small uk-divider" />
								<div className="uk-alert-primary" uk-alert="true">
									<p className="uk-text-bolder uk-text-bluedark uk-text-center uk-text-small">
										<strong>For security reasons, your account password has to be:</strong>
										<br />A lowercase letter + 1 uppercase letter + one number and at least 8 characters length.
									</p>
								</div>
							</div>
							<div className="uk-width-1-2@s mc-email">
								<label className="uk-form-label">Email*</label>
								<div className="uk-form-controls">
									<div className="uk-inline uk-width-expand">
										<span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: mail"></span>
										<Field
											className={`uk-input ${errors.email && touched.email ? 'input-error' : null}`}
											type="email"
											placeholder="myemail@***mail.com"
											name="email"
											onChange={handleChange}
										/>
									</div>
									<div className="mc-form-validation uk-hidden">
										<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
									</div>
								</div>
								{errors.email && touched.email && <ErrorMessage name="email" component="small" className="errorMessage" />}
							</div>
							<div className="uk-width-1-2@s mc-pass">
								<label className="uk-form-label">Password*</label>
								<div className="uk-form-controls">
									<div className="uk-inline uk-width-expand">
										<button
											type="button"
											className="uk-form-icon uk-form-icon-flip"
											uk-icon="icon: lock"
											onClick={setShowPassword}
										></button>
										<Field
											id="psw-input"
											className={`uk-input ${errors.password && touched.password ? 'input-error' : null}`}
											type={showPassword ? 'text' : 'password'}
											name="password"
											onChange={handleChange}
										/>
										<div id="pswmeter" className="mt-3"></div>
										<div id="pswmeter-message" className="uk-text-uppercase uk-text-bolder"></div>
									</div>
									<div className="mc-form-validation uk-hidden">
										<img src="/images/icons/ico-formerror.svg" alt="" /> Information Required
									</div>
								</div>
								{errors.password && touched.password && (
									<ErrorMessage name="password" component="small" className="errorMessage" />
								)}
							</div>
							<div className="uk-width-1-1 mc-terms">
								<hr className="uk-divider-light" />
								<div className="uk-form-controls">
									<label className="uk-margin-small" uk-lightbox="true">
										<Field
											className={`uk-checkbox uk-align-left uk-margin-small-right ${
												errors.termsCheckboxes?.terms && touched.termsCheckboxes?.terms ? 'input-error' : null
											}`}
											type="checkbox"
											name="termsCheckboxes.terms"
											onChange={handleChange}
										/>
										I certify that I am 18 years of age or older, and I agree to{' '}
										<a className="uk-button uk-button-link" href="https://mitacoins.com/terms-of-service/" data-type="iframe">
											Mitacoin User Terms
										</a>
										.
									</label>
									<br />
									{errors.termsCheckboxes?.terms && touched.termsCheckboxes?.terms && (
										<ErrorMessage name="termsCheckboxes.terms" component="small" className="errorMessage" />
									)}

									<hr className="uk-divider-light" />

									<label className="uk-margin-small" uk-lightbox="true">
										<Field
											className={`uk-checkbox uk-align-left uk-margin-small-right ${
												errors.termsCheckboxes?.privacy && touched.termsCheckboxes?.privacy ? 'input-error' : null
											}`}
											type="checkbox"
											name="termsCheckboxes.privacy"
											onChange={handleChange}
										/>
										I agree to{' '}
										<a
											className="uk-button uk-button-link"
											href="https://mitacoins.com/privacy-and-confidentiality/"
											data-type="iframe"
										>
											Privacy and confidentiality statement of Exordium Tech S.A.S.
										</a>
									</label>
									<br />
									{errors.termsCheckboxes?.privacy && touched.termsCheckboxes?.privacy && (
										<ErrorMessage name="termsCheckboxes.privacy" component="small" className="errorMessage" />
									)}

									<hr className="uk-divider-light" />

									<label className="uk-margin-small" uk-lightbox="true">
										<Field
											className={`uk-checkbox uk-align-left uk-margin-small-right ${
												errors.termsCheckboxes?.rates && touched.termsCheckboxes?.rates ? 'input-error' : null
											}`}
											type="checkbox"
											name="termsCheckboxes.rates"
											onChange={handleChange}
										/>
										I agree to{' '}
										<a className="uk-button uk-button-link" href="https://mitacoins.com/rates/" data-type="iframe">
											Rates.
										</a>{' '}
									</label>
									<br />
									{errors.termsCheckboxes?.rates && touched.termsCheckboxes?.rates && (
										<ErrorMessage name="termsCheckboxes.rates" component="small" className="errorMessage" />
									)}
								</div>

								{serverError ? (
									<div className="uk-margin">
										<div className="uk-alert-warning" uk-alert="true">
											<button type="button" className="uk-alert-close" uk-close="true"></button>
											<p>
												{serverError}{' '}
												{serverError === 'Email could not be sent' ? (
													<button type="submit" className="uk-button uk-button-text uk-text-danger">
														TRY AGAIN
													</button>
												) : null}{' '}
											</p>
										</div>
									</div>
								) : null}
							</div>
							<div className="uk-width-1-1 uk-margin-large-bottom uk-margin-medium-top uk-text-center">
								<button type="submit" disabled={loader} className={`uk-button uk-button-primary ${loader && 'loading'}`}>
									Enter
								</button>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default Register;
