import { COP_INCREMENT } from '@shared/constants';
import { formatNumberCOP, formatNumUSD } from '@shared/helpers/format';

export const valueToMitacoin = (value: number, price: number, selectedCurrency: string): number => {
	const balanceCOP = (1 + COP_INCREMENT) * price;
	if (selectedCurrency === 'cop') {
		return value / balanceCOP;
	}
	return value / price;
};

export const mitacoinToPrice = (value: number, price: number, selectedCurrency: string): string => {
	const balanceCOP = (1 + COP_INCREMENT) * price;
	if (selectedCurrency === 'usd') {
		return formatNumUSD(value * price);
	} else if (selectedCurrency === 'cop') {
		return formatNumberCOP((value * balanceCOP).toString(), 2);
	}
	return (value * price).toFixed(2);
};
