/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import intlTelInput from 'intl-tel-input';

import { referralsCode } from '@auth/services';
import * as shared from '@shared/index';
import { IUserData } from '@shared/utils';
import { FormPersonal as FormPersonalComponent } from '@user/components';
import { getUserInfo, updatePersonalInfo } from '@user/services';
import { checkoutFormPersonalSchema, IUserDataPersonal } from '@user/utils';
interface FormPersonalProps {
	userInfo: IUserData;
	setUserInfo: (info: IUserData) => null;
}

const FormPersonal: React.FC<FormPersonalProps> = ({ userInfo, setUserInfo }) => {
	const [editDataProfile, setEditDataProfile] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [serverError, setServerError] = useState('');
	const [loadScriptIntlPhone, setLoadScriptIntlPhone] = useState(false);
	const [refCode, setRefCode] = useState('');

	shared.customHooks.useScript('/js/IntlTelInput.js', {
		onLoad: () => setLoadScriptIntlPhone(true),
		onError: (err) => console.error(err),
	});

	const handleSubmit = async (values: IUserDataPersonal) => {
		values.firstName = values.firstName.toLowerCase();
		values.lastName = values.lastName.toLowerCase();
		values.secondLastName = values.secondLastName.toLowerCase();
		setIsLoading(true);
		setServerError('');
		const response = await updatePersonalInfo({ ...values });
		setIsLoading(false);
		if (response && response.success) {
			setEditDataProfile(true);
			shared.helpers.setAuthTokens(response.payload);
			setIsLoading(true);
			const responseDataUpdate = await getUserInfo();
			setIsLoading(false);
			if (responseDataUpdate.success && responseDataUpdate.payload) {
				return setUserInfo(responseDataUpdate.payload);
			}
		}
		return setServerError(response.message ?? 'Update user error');
	};

	useEffect(() => {
		if (userInfo) {
			if (!loadScriptIntlPhone) return;
			const input: HTMLInputElement | null = document.querySelector('#phone');
			if (input) {
				intlTelInput(input, {
					initialCountry: userInfo.phone.iso2,
					utilsScript: '/js/utils.js',
				});
			}
		}
	}, [userInfo, loadScriptIntlPhone]);

	useEffect(() => {
		if (userInfo) {
			if (!userInfo.referralCode) {
				const generateReferralCode = async () => {
					const response = await referralsCode(userInfo.email);
					if (response.success && response.payload) {
						setUserInfo({
							...userInfo,
							referralCode: response.payload.referralCode,
						});
						return setRefCode(response.payload.referralCode);
						// return setUserInfo(null);
					}
				};
				generateReferralCode();
			} else {
				return setRefCode(userInfo.referralCode);
			}
		}
	}, [userInfo]);

	if (!userInfo) {
		return null;
	}
	return refCode !== '' ? (
		<Formik
			initialValues={{
				email: userInfo.email,
				firstName: userInfo.firstName,
				lastName: userInfo.lastName,
				secondLastName: userInfo.secondLastName ? userInfo.secondLastName : '',
				phone: { number: userInfo.phone.number, iso2: userInfo.phone.iso2 },
				password: userInfo.password,
				referralCode: refCode,
			}}
			validationSchema={checkoutFormPersonalSchema}
			onSubmit={handleSubmit}
		>
			{(formikProps: FormikProps<IUserDataPersonal>) => (
				<>
					<FormPersonalComponent
						{...formikProps}
						editDataProfile={editDataProfile}
						userData={userInfo}
						serverError={serverError}
						toggleDataUser={() => setEditDataProfile(!editDataProfile)}
						isLoading={isLoading}
					/>
				</>
			)}
		</Formik>
	) : null;
};

export default FormPersonal;
